// import React from 'react';
// import { Link } from 'react-router-dom';
// import EventView from '../../Event/View';
// // Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';

// // Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/free-mode';
// import 'swiper/css/pagination';

// // import required modules
// import { isEmpty } from "lodash";
// import { Autoplay, FreeMode, Pagination } from 'swiper/modules';


// const EventUpComing = ({ events, props }) => {
//     return (
//         <>
//             {!isEmpty(events) ? (
//                 <div className="bg-neutral pt-14">
//                     <h2 className="text-2xl md:text-[40px] font-body font-light tracking-[.150em] text-white uppercase">
//                         Upcoming Events
//                     </h2>
//                     <Swiper
//                         slidesPerView={3}
//                         spaceBetween={30}
//                         freeMode={true}
//                         autoplay={{
//                             delay: 2500,
//                             disableOnInteraction: false,
//                         }}
//                         breakpoints={{
//                             320: {
//                                 slidesPerView: 2,
//                                 spaceBetween: 10,
//                             },
//                             640: {
//                                 slidesPerView: 2,
//                                 spaceBetween: 20,
//                             },
//                             1024: {
//                                 slidesPerView: 4,
//                                 spaceBetween: 18,
//                             },
//                         }}
//                         modules={[Autoplay, FreeMode, Pagination]}
//                         className="mySwiper mt-9 !pb-5"
//                     >
//                         {events && events.map((singleEvent) => 
//                         {
//                             return (
//                             <>
//                                 <SwiperSlide key={singleEvent.slug}>
//                                 <Link to={"/events/" + singleEvent.slug}>
//                                         <EventView singleEvent={singleEvent}  />
//                                     </Link>
//                                 </SwiperSlide>
//                             </>
//                         )})}
//                     </Swiper>
//                 </div>
//             ) : ''}
//         </>
//     )
// }

// export default EventUpComing
import React from 'react';
import { useNavigate } from 'react-router-dom';
import EventView from '../../Event/View';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { isEmpty } from "lodash";
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';

const EventUpComing = ({ events }) => {
    const navigate = useNavigate();

    const handleEventClick = (slug) => {
        navigate(`/events/${slug}`);
        window.location.reload();
    };

    return (
        <>
            {!isEmpty(events) ? (
                <div>
                    <h2 className="text-2xl md:text-3xl font-semibold text-[#212121] ">
                        You may love these too
                    </h2>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        freeMode={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1.2,
                                spaceBetween: 10,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 2.5,
                                spaceBetween: 30,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                        }}
                        modules={[Autoplay, FreeMode, Pagination]}
                        className="mySwiper mt-5 gallary-slider !pb-10"
                    >
                        {events && events.map((singleEvent) => (
                            <SwiperSlide key={singleEvent.slug}>
                                <Link to={"/events/" + singleEvent.slug}>
                                    <EventView singleEvent={singleEvent} />
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            ) : ''}
        </>
    );
};

export default EventUpComing;
