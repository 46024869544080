import React from "react";
// Import custom components


import ComingSoon from "../pages/CommingSoon/Index";
import PrivacyPolicy from "../pages/PrivacyPolicy/Index";
import TermsCondition from "../pages/TermsCondition/Index";
import Business from "../pages/Business/Index";
import ContactUs from "../pages/ContactUs/Index";
import AboutUs from "../pages/AboutUs/Index";
import RefundCancellationPolicy from "../pages/RefundCancellationPolicy/Index";
import Home from "../pages/Home/Index";
import EventCategory from "../pages/EventCategory/Index";
import EventCategoryDetails from "../pages/EventCategory/Details";
import EventPassPurchase from "../pages/EventCategory/PassPurchase";
import OrganiserDetails from "../pages/Organiser/Details";
import OurBlog from "../pages/OurBlog/Index";
import OurBlogDetails from "../pages/OurBlog/Details";
import UserProfile from "../pages/UserProfile/Index";
import SignUp from "../pages/SignUp/Index";
import LogIn from "../pages/LogIn/Index";
import Notification from "../pages/Notification/Index";
import PaymentOption from "../pages/EventCategory/PaymentOption";
import ThankYou from "../pages/ThankYou/Index";
import PubsClub from "../pages/PubsClub/Index";
import Exhibitions from "../pages/Exhibitions/Index";
import Artiest from "../pages/Artiest/Index";
import QrShareScan from "../pages/QrCode/Index";
import Registration from "../pages/Registration/Index";
import Logout from "../pages/Logout/Index";
import SponsorView from "../pages/Sponsor/Index";
import { Navigate } from "react-router-dom";
import Page404 from "../pages/Page404/Index";
import Page429 from "../pages/Page429/Index";
import Page500 from "../pages/Page500/Index"
import DandiyaQrPass from "../pages/DandiyaQrPass/Index"
import PaymentUnsuccessfull from "../pages/PaymentUnsuccessfull/Index"

const authProtectedRoutes = [
  { path: "/user-profile", component: <UserProfile /> },
  { path: "/user-setting", component: <UserProfile /> },
  { path: "/user-tickets", component: <UserProfile /> },
  { path: "/user-tickets/:uuid/share-history", component: <UserProfile /> },
  { path: "/user-interest", component: <UserProfile /> },
];

const publicRoutes = [
  { path: "/", component: <Home /> },
  { path: "/events", component: <EventCategory /> },
  { path: "/city/:city_name/events", component: <EventCategory /> },
  { path: "/category/:category_name/events", component: <EventCategory /> },
  { path: "/city/:city_name/category/:category_name/events", component: <EventCategory /> },
  { path: "/city/:city_name", component: <Home /> },
  { path: "/city/:city_name/category/:category_name", component: <Home /> },
  { path: "/category/:category_name", component: <Home /> },
  { path: "/events/:event_slug", component: <EventCategoryDetails /> },
  { path: "/events/:event_slug/book-tickets", component: <EventPassPurchase /> },
  { path: "/organiser/:organiser_slug", component: <OrganiserDetails /> },
  { path: "/our-blog", component: <ComingSoon /> },
  { path: "/our-blog/details", component: <OurBlogDetails /> },
  { path: "/business", component: <Business /> },
  { path: "/contact-us", component: <ContactUs /> },
  { path: "/about-us", component: <AboutUs /> },
  { path: "/notification", component: <Notification /> },
  { path: "/event/:event_slug/book-tickets/payment", component: <PaymentOption /> },
  { path: "/payment/:order_id/thank-you", component: <ThankYou /> },
  //{ path: "/pubs-club", component: <PubsClub /> },
  //{ path: "/exhibitions", component: <Exhibitions /> },
  { path: "/artiest/:artiest_slug", component: <Artiest /> },
  { path: "/sponsor/:sponsor_id/details", component: <ComingSoon /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/terms-condition", component: <TermsCondition /> },
  { path: "/refund-cancellation-policy", component: <RefundCancellationPolicy /> },
  { path: "/payment-unsuccessfull", component: <PaymentUnsuccessfull /> },
  { path: "/page-404", component: <Page404 /> },
  { path: "/page-500", component: <Page500 /> },



];
const publicRoutesWithoutHeader = [
  { path: "/coming-soon", component: <ComingSoon /> },
  { path: "/logout", component: <Logout /> },
];
const publicRoutesWithoutHeaderFooter = [
  //QrCode Open
  { path: "/qr/:uuid", component: <QrShareScan /> },
  { path: "/MEPSMS/:uuid", component: <QrShareScan /> },
  { path: "/q", component: <QrShareScan /> },
  { path: "/event/:event_id/seller/:seller_id", component: <Registration /> },
  { path: "/page-429", component: <Page429 /> },
  { path: "/sign-up", component: <SignUp /> },
  { path: "/login", component: <LogIn /> },
];
export { authProtectedRoutes, publicRoutes, publicRoutesWithoutHeader, publicRoutesWithoutHeaderFooter };