import React from "react";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import EventView from "../../components/Event/View";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import PropTypes from "prop-types";
// import required modules
import { Autoplay, FreeMode, Pagination } from "swiper/modules";
import { isEmpty } from "lodash";
import popularEvent from "../../assets/light_theme_images/popular-event.svg";
import offerEvent from "../../assets/light_theme_images/exclusive-offer.svg";
import upcomingEvent from "../../assets/light_theme_images/upcoming-event.svg";
import arrowRight from "../../assets/light_theme_images/arrow-right.svg";

const Event = ({ events, props, section_title }) => {
  return (
    <>
      {events && events.length > 0 ? (
        <>
          <div className="pb-16">
            <div>
              <div className="flex items-center md:justify-between gap-4 mb-6">
                <div className="flex gap-4">
                  <div className="flex">
                    <img
                      src={section_title == 'Popular Events Near You' ? popularEvent :
                        section_title == 'Events With Exclusive Offers' ? offerEvent :
                          section_title == 'Upcoming Events Near You' ? upcomingEvent :
                            popularEvent}
                      alt="popular evrnt"
                      className="inline-block mr-2"
                    />
                    <h2 className="text-xl md:text-3xl font-semibold text-[#212121] capitalize line-clamp-1">
                      {section_title}
                    </h2>
                  </div>
                  <img
                    src={arrowRight}
                    alt="popular evrnt"
                    className="inline-block"
                  />
                </div>

                <button
                  onClick={() => {
                    props.router.navigate("/events");
                  }}
                  className="text-[#CB2129] border border-[#CB2129] text-sm md:text-base font-semibold py-2 px-4 rounded-lg font-body whitespace-nowrap hidden md:inline-block"
                >
                  View all
                </button>
              </div>
            </div>
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              freeMode={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1.3,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 2.3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay, FreeMode, Pagination]}
              className="mySwiper"
            >
              {events &&
                events.map((singleEvent, index) => (
                  <>
                    {!isEmpty(singleEvent.event) ? (
                      <SwiperSlide>
                        <Link to={"/events/" + singleEvent.event.slug}>
                          <EventView
                            singleEvent={singleEvent.event}
                            props={props}
                            key={index}
                          />
                        </Link>
                      </SwiperSlide>
                    ) : (
                      ""
                    )}
                  </>
                ))}
            </Swiper>
            <div className="text-center mt-7">
              <button
                onClick={() => {
                  props.router.navigate("/events");
                }}
                className="text-[#CB2129] border border-[#CB2129] text-sm md:text-base font-semibold py-2 px-4 rounded-lg font-body whitespace-nowrap inline-block md:hidden"
              >
                View all
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};
Event.propTypes = {
  events: PropTypes.array,
  props: PropTypes.object,
  section_title: PropTypes.string,
};
export default Event;
