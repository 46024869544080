import React from "react"

const RadioButton = ({ validation, label, name, id, value, className }) => {
  const handleChange = event => {
    validation.setFieldValue(name, event.target.value)
  }
  return (
    <div className="flex items-center justify-between gap-6">
      <label className="lg:text-base text-sm text-[#5A5A5A] font-body font-regular">
        {label}
      </label>
      <div className="inline-flex">
        <label
          className="relative flex cursor-pointer items-center rounded-full"
          htmlFor={id}
        >
          <input
            name={name}
            type="radio"
            className="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-full border-2 border-[#5A5A5A] text-white transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-transparent before:opacity-0 before:transition-opacity checked:bg-white checked:before:bg-primary checked:border-primary duration-1000 ease-in-out"
            id={id}
            value={value}
            checked={validation.values[name] === value}
            onChange={handleChange}
          />
          <span className="pointer-events-none duration-1000 ease-in-out absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-primary opacity-0 transition-opacity peer-checked:opacity-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3.5 w-3.5"
              viewBox="0 0 16 16"
              fill="currentColor"
            >
              <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
            </svg>
          </span>
        </label>
      </div>
    </div>
  )
}

export default RadioButton
