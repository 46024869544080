import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { isEmpty } from "lodash";

const ProfileMenu = ({ notification, authUserDetails, user }) => {
  const [unread_notification_count, setUnreadNotificationCount] = useState(
    JSON.parse(localStorage.getItem("unread_notification_count"))
  );
  useEffect(() => {
    let previousValue = localStorage.getItem("unread_notification_count");
    const interval = setInterval(() => {
      const currentValue = localStorage.getItem("unread_notification_count");
      if (currentValue !== previousValue) {
        previousValue = currentValue;
        setUnreadNotificationCount(JSON.parse(currentValue));
      }
    }, 1000);
    return () => clearInterval(interval); // Cleanup
  }, []);
  return (
    <>
      {localStorage.getItem("publicMePassUser") ? (
        <>
          <Link to="/notification">
            <div className="relative hidden xl:block">
              <div className="size-12 bg-[#CB2129] rounded-full flex items-center justify-center">
                <i className="fa-solid fa-bell text-white text-2xl"></i>
              </div>
              <span className="absolute -top-[4px] -right-[5px] bg-white border border-[#CB2129] text-[#101010] text-[11px] font-normal rounded-full px-2 py-1">
                {unread_notification_count}
              </span>
            </div>
          </Link>
          <div className="flex-none mr-10 md:mr-3 xl:mr-0">
            <Link to="/user-profile">
              {!isEmpty(authUserDetails.profile_image_path) ? (
                <img
                  src={authUserDetails.profile_image_path || logo}
                  className="size-[39px] xl:size-12 rounded-full"
                  alt="Profile"
                />
              ) : (
                <>
                  {!isEmpty(authUserDetails.name) ? (
                    <div className="flex items-center justify-center h-10 xl:h-12 w-10 xl:w-12 bg-[#FFDEDE] rounded-full text-primary">
                      {authUserDetails.name.charAt(0)}
                    </div>
                  ) : (
                    <img
                      src={user}
                      className="h-10 w-10 rounded-full"
                      alt="Default User"
                    />
                  )}
                </>
              )}
            </Link>
          </div>
        </>
      ) : (
        <Link
          to="/login"
          className="hidden xl:inline-block btn-liner-gradient text-base text-white font-medium py-[10px] px-7 rounded-[24px] flex items-center justify-center"
          state={{ is_login_page: "Yes" }}
        >
          Login / Register
        </Link>
      )}
    </>
  );
};

export default ProfileMenu;
