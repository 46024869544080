import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import api from "../../constants/api";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import { convertToAmPm } from "../../common/commonFunction";

const SelectDate = ({
  dropdownRef,
  toggleDropdown,
  isDropdownOpen,
  optionGroupDate,
  selectedDate,
  handleDateDropdown,
  sessionPassCount,
}) => {

  return (
    <>
      <div className="bg-[#F9F9F9] p-4 md:p-7 rounded-lg mt-7">
        <h3 className="text-[22px] lg:text-3xl text-[#101010] font-semibold">
          Select Date
        </h3>
        <div className="flex flex-wrap gap-6 mt-4">
          {sessionPassCount > 0 && (
            <button onClick={() => handleDateDropdown('')}
              className={`${selectedDate && selectedDate.value === ''
                ? "btn-liner-gradient text-white text-lg text-center font-semibold py-2 px-4 rounded-lg"
                : "border border-[#101010] text-[#101010] text-lg text-center font-semibold py-2 px-4 rounded-lg"
                }`}
            >Season Pass</button>
          )}
          {optionGroupDate.map((item, index) => (
            <button
              key={index}
              onClick={() => handleDateDropdown(item)}
              className={`${selectedDate && selectedDate.value === item.value
                ? "btn-liner-gradient text-white text-lg text-center font-semibold py-2 px-4 rounded-lg"
                : "border border-[#101010] text-[#101010] text-lg text-center font-semibold py-2 px-4 rounded-lg"
                }`}>
              {item.label}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};
SelectDate.propTypes = {};
export default SelectDate;
