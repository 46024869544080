import React from "react";
import banner from "../../assets/images/blog.png";
import img from "../../assets/images/blog/blog1.png";
import img1 from "../../assets/images/blog/blog2.png";
import img2 from "../../assets/images/blog/blog3.png";
import img3 from "../../assets/images/blog/blog4.png";
import img4 from "../../assets/images/blog/blog5.png";
import img5 from "../../assets/images/blog/blog6.png";
import img6 from "../../assets/images/blog/blog7.png";
import img7 from "../../assets/images/blog/blog8.png";
import img8 from "../../assets/images/blog/blog9.png";
import blog from "../../assets/images/blog/blogimg.png";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  FreeMode,
  Keyboard,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import blogImg from "../../assets/light_theme_images/blog.png";
import blogItem from "../../assets/light_theme_images/blog-item.png";
import blogItem1 from "../../assets/light_theme_images/blog-item1.png";
import blogItem2 from "../../assets/light_theme_images/blog-item2.png";
import blogItem3 from "../../assets/light_theme_images/blog-item3.png";
import blogItem4 from "../../assets/light_theme_images/blog-item4.png";
import blogItem5 from "../../assets/light_theme_images/blog-item5.png";
import blogItem6 from "../../assets/light_theme_images/blog-item6.png";
import blogItem7 from "../../assets/light_theme_images/blog-item7.png";
import blogItem8 from "../../assets/light_theme_images/blog-item8.png";
function OurBlog() {
  return (
    <>
      <section className="bg-white pt-14">
        <div className="container">
          <div>
            <h2 className="text-2xl md:text-3xl font-semibold text-[#101010] mb-5">
              Recent Blog
            </h2>
            <Swiper
              cssMode={true}
              mousewheel={true}
              keyboard={true}
              slidesPerView={1}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
              className="mySwiper gallary-slider blog-slider !pb-14"
            >
              <SwiperSlide>
                <div className="grid girc-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <img
                      src={blogImg}
                      alt="img"
                      className="aspect-[1.55/1] rounded-xl"
                    />
                  </div>
                  <div className="flex flex-col gap-10">
                    <div className="flex flex-col gap-4">
                      <div className="flex items-center gap-4">
                        <p className="text-[#CB2129] text-base font-medium">
                          November 14, 2024
                        </p>
                        <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                        <p className="text-[#CB2129] text-base font-medium">
                          4 min read
                        </p>
                      </div>
                      <h2 className="text-[#101010] text-3xl font-semibold">
                        How to Build a Powerful Ticket Booking Platform
                      </h2>
                      <p className="text-[#101010] text-xl font-normal">
                        Creating a robust ticket booking platform is not just
                        about selling tickets—it’s about crafting an intuitive
                        and engaging experience for your users. Whether you're
                        targeting concerts, conferences, sports events, or
                        virtual gatherings, the success of your platform lies in
                        its ability to cater to both event organizers and
                        attendees with ease.
                      </p>
                    </div>
                    <div>
                      <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                        Read Article
                      </button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="grid girc-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <img
                      src={blogImg}
                      alt="img"
                      className="aspect-[1.55/1] rounded-xl"
                    />
                  </div>
                  <div className="flex flex-col gap-10">
                    <div className="flex flex-col gap-4">
                      <div className="flex items-center gap-4">
                        <p className="text-[#CB2129] text-base font-medium">
                          November 14, 2024
                        </p>
                        <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                        <p className="text-[#CB2129] text-base font-medium">
                          4 min read
                        </p>
                      </div>
                      <h2 className="text-[#101010] text-3xl font-semibold">
                        How to Build a Powerful Ticket Booking Platform
                      </h2>
                      <p className="text-[#101010] text-xl font-normal">
                        Creating a robust ticket booking platform is not just
                        about selling tickets—it’s about crafting an intuitive
                        and engaging experience for your users. Whether you're
                        targeting concerts, conferences, sports events, or
                        virtual gatherings, the success of your platform lies in
                        its ability to cater to both event organizers and
                        attendees with ease.
                      </p>
                    </div>
                    <div>
                      <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                        Read Article
                      </button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="grid girc-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <img
                      src={blogImg}
                      alt="img"
                      className="aspect-[1.55/1] rounded-xl"
                    />
                  </div>
                  <div className="flex flex-col gap-10">
                    <div className="flex flex-col gap-4">
                      <div className="flex items-center gap-4">
                        <p className="text-[#CB2129] text-base font-medium">
                          November 14, 2024
                        </p>
                        <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                        <p className="text-[#CB2129] text-base font-medium">
                          4 min read
                        </p>
                      </div>
                      <h2 className="text-[#101010] text-3xl font-semibold">
                        How to Build a Powerful Ticket Booking Platform
                      </h2>
                      <p className="text-[#101010] text-xl font-normal">
                        Creating a robust ticket booking platform is not just
                        about selling tickets—it’s about crafting an intuitive
                        and engaging experience for your users. Whether you're
                        targeting concerts, conferences, sports events, or
                        virtual gatherings, the success of your platform lies in
                        its ability to cater to both event organizers and
                        attendees with ease.
                      </p>
                    </div>
                    <div>
                      <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                        Read Article
                      </button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
      <section className="bg-white pt-10 pb-14">
        <div className="container">
          <h2 className="text-2xl md:text-3xl font-semibold text-[#101010] mb-5">
            Other Blogs
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            <div>
            <Link to="/our-blog/details">
              <img src={blogItem} alt="img" className="rounded-xl" />
              <div className="flex flex-col gap-2.5 mt-3">
                <h2 className="text-[#101010] text-2xl font-semibold">
                  Virtual Event Ticketing: 5 Tips for Success
                </h2>
                <p className="text-[#101010] text-base font-normal">
                  Learn how to seamlessly manage ticketing for virtual events
                  and maximize attendance with our expert tips.
                </p>
                <div className="flex items-center gap-4">
                  <p className="text-[#CB2129] text-base font-medium">
                    November 13, 2024
                  </p>
                  <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                  <p className="text-[#CB2129] text-base font-medium">
                    5 min read
                  </p>
                </div>
                <div>
                  <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                    Read Article
                  </button>
                </div>
              </div>
              </Link>
            </div>
            <div>
            <Link to="/our-blog/details">
              <img src={blogItem1} alt="img" className="rounded-xl" />
              <div className="flex flex-col gap-2.5 mt-3">
                <h2 className="text-[#101010] text-2xl font-semibold">
                  Book Now, Sell Anytime: Your Ticketing Solution
                </h2>
                <p className="text-[#101010] text-base font-normal">
                  Explore how flexible and secure ticketing systems can help you
                  sell tickets around the clock. A mobile phone showing a live
                  event ticket sale...
                </p>
                <div className="flex items-center gap-4">
                  <p className="text-[#CB2129] text-base font-medium">
                    November 12, 2024
                  </p>
                  <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                  <p className="text-[#CB2129] text-base font-medium">
                    4 min read
                  </p>
                </div>
                <div>
                  <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                    Read Article
                  </button>
                </div>
              </div>
              </Link>
            </div>
            <div>
            <Link to="/our-blog/details">
              <img src={blogItem2} alt="img" className="rounded-xl" />
              <div className="flex flex-col gap-2.5 mt-3">
                <h2 className="text-[#101010] text-2xl font-semibold">
                  Behind the Curtain: Inside the Event Industry
                </h2>
                <p className="text-[#101010] text-base font-normal">
                  Take a deep dive into the behind-the-scenes efforts that make
                  events successful. A backstage view of a concert or
                  conference...
                </p>
                <div className="flex items-center gap-4">
                  <p className="text-[#CB2129] text-base font-medium">
                    November 10, 2024
                  </p>
                  <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                  <p className="text-[#CB2129] text-base font-medium">
                    7 min read
                  </p>
                </div>
                <div>
                  <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                    Read Article
                  </button>
                </div>
              </div>
              </Link>
            </div>
            <div>
            <Link to="/our-blog/details">
              <img src={blogItem3} alt="img" className="rounded-xl" />
              <div className="flex flex-col gap-2.5 mt-3">
                <h2 className="text-[#101010] text-2xl font-semibold">
                  Sell Tickets Online with Ease: Your Event’s New Best Friend
                </h2>
                <p className="text-[#101010] text-base font-normal">
                  Discover how to leverage online ticketing platforms for
                  effective promotions and sales. A hand holding a printed QR
                  code ticket...
                </p>
                <div className="flex items-center gap-4">
                  <p className="text-[#CB2129] text-base font-medium">
                    November 10, 2024
                  </p>
                  <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                  <p className="text-[#CB2129] text-base font-medium">
                    5 min read
                  </p>
                </div>
                <div>
                  <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                    Read Article
                  </button>
                </div>
              </div>
              </Link>
            </div>
            <div>
            <Link to="/our-blog/details">
              <img src={blogItem4} alt="img" className="rounded-xl" />
              <div className="flex flex-col gap-2.5 mt-3">
                <h2 className="text-[#101010] text-2xl font-semibold">
                  How to Start an Event Ticketing Platform in 2024
                </h2>
                <p className="text-[#101010] text-base font-normal">
                  Step-by-step guidance on launching a successful event
                  ticketing platform from scratch. A group of developers
                  brainstorming...
                </p>
                <div className="flex items-center gap-4">
                  <p className="text-[#CB2129] text-base font-medium">
                    November 9, 2024
                  </p>
                  <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                  <p className="text-[#CB2129] text-base font-medium">
                    6 min read
                  </p>
                </div>
                <div>
                  <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                    Read Article
                  </button>
                </div>
              </div>
              </Link>
            </div>
            <div>
            <Link to="/our-blog/details">
              <img src={blogItem5} alt="img" className="rounded-xl" />
              <div className="flex flex-col gap-2.5 mt-3">
                <h2 className="text-[#101010] text-2xl font-semibold">
                  Maximizing Event Reach with Social Media Ticket Sales
                </h2>
                <p className="text-[#101010] text-base font-normal">
                  Use social media platforms to drive ticket sales and engage
                  your audience. A phone screen showing an Instagram post
                  promoting an event.
                </p>
                <div className="flex items-center gap-4">
                  <p className="text-[#CB2129] text-base font-medium">
                    November 7, 2024
                  </p>
                  <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                  <p className="text-[#CB2129] text-base font-medium">
                    2 min read
                  </p>
                </div>
                <div>
                  <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                    Read Article
                  </button>
                </div>
              </div>
              </Link>
            </div>
            <div>
            <Link to="/our-blog/details">
              <img src={blogItem6} alt="img" className="rounded-xl" />
              <div className="flex flex-col gap-2.5 mt-3">
                <h2 className="text-[#101010] text-2xl font-semibold">
                  Top Features Every Ticketing Platform Needs
                </h2>
                <p className="text-[#101010] text-base font-normal">
                  Ensure your ticketing platform offers these must-have features
                  for a smooth user experience. Icons representing features like
                  QR codes, analytics...
                </p>
                <div className="flex items-center gap-4">
                  <p className="text-[#CB2129] text-base font-medium">
                    November 2, 2024
                  </p>
                  <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                  <p className="text-[#CB2129] text-base font-medium">
                    5 min read
                  </p>
                </div>
                <div>
                  <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                    Read Article
                  </button>
                </div>
              </div>
              </Link>
            </div>
            <div>
            <Link to="/our-blog/details">
              <img src={blogItem7} alt="img" className="rounded-xl" />
              <div className="flex flex-col gap-2.5 mt-3">
                <h2 className="text-[#101010] text-2xl font-semibold">
                  Hybrid Events: Bridging the Gap with Smart Ticketing
                </h2>
                <p className="text-[#101010] text-base font-normal">
                  Learn how hybrid event ticketing systems cater to both
                  in-person and virtual attendees. A split-screen image
                  showing...
                </p>
                <div className="flex items-center gap-4">
                  <p className="text-[#CB2129] text-base font-medium">
                    November 4, 2024
                  </p>
                  <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                  <p className="text-[#CB2129] text-base font-medium">
                    5 min read
                  </p>
                </div>
                <div>
                  <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                    Read Article
                  </button>
                </div>
              </div>
              </Link>
            </div>
            <div>
            <Link to="/our-blog/details">
              <img src={blogItem8} alt="img" className="rounded-xl" />
              <div className="flex flex-col gap-2.5 mt-3">
                <h2 className="text-[#101010] text-2xl font-semibold">
                  Event Promotion on a Budget: Ticketing Hacks
                </h2>
                <p className="text-[#101010] text-base font-normal">
                  Cost-effective ways to promote your events and boost ticket
                  sales without breaking the bank. A chalkboard with event
                  promotional ideas drawn.
                </p>
                <div className="flex items-center gap-4">
                  <p className="text-[#CB2129] text-base font-medium">
                    November 1, 2024
                  </p>
                  <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                  <p className="text-[#CB2129] text-base font-medium">
                    7 min read
                  </p>
                </div>
                <div>
                  <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                    Read Article
                  </button>
                </div>
              </div>
              </Link>
            </div>
          </div>
          <div className="text-center mt-10">
            <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
            View more
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurBlog;
