
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { isEmpty } from "lodash";

const SelectCityModal = ({ isShowCityModal, setShowCityModal, cityList, handleOnSelect, props }) => {
    const [filteredCityList, setFilteredCityList] = useState(cityList);
    const filterInCityList = (searchCity) => {
        const filteredCityList = cityList.filter(city => {
            return city.city.toLowerCase().includes(searchCity.toLowerCase());
        });
        setFilteredCityList(filteredCityList);
    };

    return (
        <>
            <div className="fixed z-30 overflow-y-auto top-0 md:top-24 w-full left-0">
                <div className="flex md:items-center justify-center min-h-screen md:pt-4 md:px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-900 opacity-75" />
                    </div>
                    <div
                        className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] xl:w-[60%]"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div className="bg-white px-4 py-1 border">
                            <div className="flex items-center justify-between">
                                <p className='text-lg text-[#5A5A5A] font-normal'>Select City</p>
                                <button
                                    type="button"
                                    className="py-2 px-2"
                                    onClick={() => setShowCityModal(false)}
                                >
                                    <i className="fas fa-times text-lg text-[#5A5A5A]"></i>
                                </button>
                            </div>
                        </div>
                        <div className="bg-[#EAE7E7] p-4 h-screen md:h-full">
                            <div className="relative">
                                {/* City List in Search and Select */}
                                <input type="text" placeholder="Search for your city"
                                    onChange={(e) =>
                                        filterInCityList(e.target.value)
                                    }
                                    className="w-full bg-white rounded-lg  py-[10px] pr-3 pl-10 text-[#A1A1AA] text-sm font-normal outline-none  focus:border-primary placeholder:text-dark" />
                                <span className="absolute top-1/2 left-4 -translate-y-1/2">
                                    <i className='fa fa-search text-[#A1A1AA] text-sm'></i>
                                </span>
                            </div>
                            <div className='bg-white border border-[#888888] rounded-lg max-h-[70vh] md:max-h-[53vh] overflow-auto p-6 mt-5'>
                                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 '>
                                    {!isEmpty(filteredCityList) && filteredCityList.map((city, indexCity) => (
                                        <button key={city.city + indexCity}
                                            onClick={() => handleOnSelect(city)}
                                            className={props.router.params.city_name === city.city ? "text-white text-lg font-normal py-2 px-[18px] inline bg-primary rounded-full border border-primary capitalize hover:border-primary hover:bg-primary hover:text-white ease-in-out duration-1000 text-left md:text-center"
                                                : "text-[#5A5A5A] text-lg font-normal py-2 px-[18px] inline bg-white rounded-full border border-[#5A5A5A] capitalize hover:border-primary hover:bg-primary hover:text-white ease-in-out duration-1000 text-left md:text-center"}>
                                            <i className="fas fa-map-marker-alt mr-2"></i>
                                            {city.city}
                                        </button>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default SelectCityModal;
