const getPassCalculation = (passList, promoCode, event, settings, Offers) => {

    let updatedpassList = UpdatePriceList(passList);
    let totalPaybleAmount = getTotalPaybleAmount(updatedpassList);
    let PassDetails = getpassListforPayment(updatedpassList);
    let totalDiscount = applyPromoCode(promoCode, totalPaybleAmount, Offers, PassDetails);
    let taxAmount = calculateTax(event, totalPaybleAmount, totalDiscount.discount, settings);

    // request data creation
    const requestData = {
        event_slug: event.slug,
        pass_with_quantity: JSON.stringify(PassDetails),
        offer_code: promoCode,
        amount: convertToFloat(taxAmount.amountBeforeTax),
        discount: convertToFloat(totalDiscount.discount),
        net_amount: convertToFloat(taxAmount.amountAfterTax),
        convenience_charge: convertToFloat(taxAmount.convenience_charge),
        tax_amount: convertToFloat(taxAmount.tax),
        total_amount: convertToFloat(taxAmount.amount),
        discount_message: totalDiscount.message,
        discount_status: totalDiscount.status,
        get_y: totalDiscount.get_y_details,
    }
    return requestData;
}

const convertToFloat = (value) => {
    return value > 0 ? parseFloat(value) : 0;
}
const UpdatePriceList = (passList) => {
    // update the PriceDisplay field in passList
    passList.map((pass) => {
        //if qty is less than 1 then set it to 0
        if (pass.quantity < 1) {
            pass.quantity = 0;
        }
        pass.PriceDisplay = pass.amount_after_discount * pass.quantity;
        pass.event_pass_id = pass.id;
    });
    return passList;
}


const getTotalPaybleAmount = (passList) => {

    // get total payble amount from passList by adding PriceDisplay field of each pass
    let total = 0;

    passList.map((pass) => {
        total += pass.PriceDisplay;
    });
    return total;

}
const getpassListforPayment = (passList) => {
    //[{"event_pass_id":"32","quantity":"2","event_date_id":"39"},{"event_pass_id":"31","quantity":"2"}]
    let passListforPayment = [];
    passList.map((pass) => {
        if (pass.quantity > 0) {
            passListforPayment.push({ "event_pass_id": pass.id, "quantity": pass.quantity, "event_date_id": pass.event_date_id, 'amount': pass.amount });
        }
    });
    return passListforPayment;
}


const calculateTax = (event, amount, discount, settings) => {
    // calculate tax on totalPaybleAmount
    // if event has gst_applicable as "You Will Pay" then  amount  will  be included with tax and need  to identify the tax amount and amont before tax
    let tax = 0;
    let amountBeforeTax = 0;
    let amountAfterTax = 0;
    let convenience_charge = 0;
    let taxPer = parseFloat(settings.igst_per);
    let conveniencePer = parseFloat(settings.convenience_charge);

    if (event.gst_applicable === "You Will Pay") {
        amountBeforeTax = getAmountBeforTax(amount, taxPer, discount);
        tax = parseFloat(amount) - parseFloat(amountBeforeTax) - parseFloat(discount);
        amountAfterTax = parseFloat(amountBeforeTax) + parseFloat(tax);
    } else {
        amountBeforeTax = amount - discount;
        tax = getTaxAmount(amount, taxPer, discount);
        amountAfterTax = amount + tax - discount;
    }

    if (event.convenience_charge === "You Will Pay") {
        convenience_charge = 0;
    } else {
        convenience_charge = getConveninenceCharge(event, amountBeforeTax, conveniencePer, taxPer);
    }

    amountAfterTax = parseFloat(amountAfterTax) + parseFloat(convenience_charge);
    amountAfterTax = amountAfterTax.toFixed(2);
    tax = tax.toFixed(2);

    return { tax: tax, amountBeforeTax: amountBeforeTax, amountAfterTax: amountAfterTax, convenience_charge: convenience_charge, amount: amount };
}

const getTaxAmount = (amount, taxPer, discount) => {
    amount = parseFloat(amount) - parseFloat(discount);
    let taxAmount = (amount * taxPer) / 100;
    // round to 2 decimal places
    taxAmount = taxAmount.toFixed(2);
    return parseFloat(taxAmount);
}

const getAmountBeforTax = (amount, taxPer, discount) => {
    amount = parseFloat(amount) - parseFloat(discount);
    let amountPer = 100 + taxPer

    let amountBeforTax = amount / amountPer;
    amountBeforTax = amountBeforTax * 100;

    // round to 2 decimal places
    amountBeforTax = amountBeforTax.toFixed(2);
    return parseFloat(amountBeforTax);
}

const getConveninenceCharge = (event, amount, conveniencePer, taxPer) => {
    // calculate convenience charge on totalPaybleAmount
    let convenience_charge = (amount * conveniencePer) / 100;
    // round to 2 decimal places
    convenience_charge = convenience_charge.toFixed(2);
    let tax = convenience_charge * taxPer / 100;
    convenience_charge = parseFloat(convenience_charge) + parseFloat(tax);
    convenience_charge = convenience_charge.toFixed(2);
    return parseFloat(convenience_charge);
}

const applyPromoCode = (promoCode, totalPaybleAmount, Offers, PassDetails) => {
    // apply promo code on totalPaybleAmount
    // filter the promo code from the list of promo codes
    let discount = 0;
    let message = "Offer applied successfully";
    let status = "success";
    let get_y_details = [];
    const offer = Offers.find(offer => offer.offer_code === promoCode);
    let totalQty = 0;
    PassDetails.map((pass) => {
        totalQty += parseInt(pass.quantity);
    });
    if (offer) {
        if (offer.discount_type === "Percentage") {
            let passData = PassDetails.find(pass => pass.event_pass_id === offer.event_pass_id);
            if (passData) {
                discount = (passData.amount * passData.quantity * offer.discount_percentage) / 100;
            } else {
                message = "Offer is not applicable for selected pass";
                status = "error";
            }
        } else if (offer.discount_type === "Amount") {
            let passData = PassDetails.find(pass => pass.event_pass_id === offer.event_pass_id);
            if (passData) {
                discount = offer.discount_amount;
            } else {
                message = "Offer is not applicable for selected pass";
                status = "error";
            }
        } else if (offer.discount_type === "On Cart") {
            // sum of all pass details of quantity
            if (offer.oncart_discount_type === "Amount") {
                if (offer.oncart_min_cart_value !== null && totalPaybleAmount >= offer.oncart_min_cart_value) {
                    discount = offer.discount_amount;
                } else if (offer.oncart_min_cart_pass !== null && totalQty >= offer.oncart_min_cart_pass) {
                    discount = offer.discount_amount;
                } else {
                    message = "Minimum Cart Value or Minimum Passes quantity not met";
                    status = "error";
                }
            } else if (offer.oncart_discount_type === "Percentage") {
                if (offer.oncart_min_cart_value !== null && totalPaybleAmount >= offer.oncart_min_cart_value) {
                    discount = (totalPaybleAmount * offer.discount_percentage) / 100;
                } else if (offer.oncart_min_cart_pass !== null && totalQty >= offer.oncart_min_cart_pass) {
                    discount = (totalPaybleAmount * offer.discount_percentage) / 100;
                } else {
                    message = "Minimum Cart Value or Minimum Passes quantity not met";
                    status = "error";
                }
            }
        } else if (offer.discount_type === "Buy X Get Y Free") {
            let passData = PassDetails.find(pass => pass.event_pass_id === offer.buy_x);
            if (passData && passData.quantity >= offer.amount_of_x) {
                discount = 0;
                let pass_details = offer.get_y_free_pass;
                //If the offer is Buy 2 Get 4 Free, then the user will get 4 free passes for every 2 passes they buy.
                //If the user buys 3 passes, they will get 4 free passes.
                //If the user buys 4 passes, they will get 8 free passes.
                let freeQuantity = Math.floor(passData.quantity / offer.amount_of_x) * offer.amount_of_y;
                get_y_details.push({ "pass_detail": pass_details, "quantity": freeQuantity });
            } else {
                message = "Offer is not applicable for selected pass";
                status = "error";
            }
        } else {
            message = "Invalid Offer Type";
            status = "error";
        }
    } else {
        message = "";
        status = "pending";
    }
    discount = discount.toFixed(2);
    return { discount: discount, get_y_details: get_y_details, message: message, status: status };
}



export default getPassCalculation;