export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

/* DATA */
export const GET_DATA = "GET_DATA"
export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS"
export const GET_DATA_FAIL = "GET_DATA_FAIL"

/**
 * Add Edit DATA
 */
export const ADD_UPDATE_DATA = "ADD_UPDATE_DATA"
export const ADD_UPDATE_DATA_SUCCESS = "ADD_UPDATE_DATA_SUCCESS"
export const ADD_UPDATE_DATA_FAIL = "ADD_UPDATE_DATA_FAIL"
export const ADD_UPDATE_DATA_MULTIPLE_FORM = "ADD_UPDATE_DATA_MULTIPLE_FORM"

/**
 * Clear DATA
 */
export const CLEAR_DATA = "CLEAR_DATA"