import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import api from "../../constants/api";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import { convertToAmPm } from "../../common/commonFunction";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';
import { Link } from "react-router-dom";
import img from "../../assets/images/App-Store.png";
import img1 from "../../assets/images/google-play.png";
import PriceDetail from "./PriceDetail";
import SponserList from "./SponserList";
const SeasonMePass = ({
    seasonArray,
    passDetails,
    textColor,
    primaryColor,
    secondaryColor,
    backGroundColor,
    RFIDImage,
    ExpiredImage,
    DeletedImage,
    ScannedImage,
    SharedImage,
    map,
    toggleSplitModal,
    setShowTermsModal,
    pricingDetails,
    sponsorList,
    setUrlString,
    setPassAvailableQuantity
}) => {

    return (

        <>
            <div className={`rounded-[10px] border border-[#E7E7E7]`} style={{ backgroundColor: backGroundColor }}>
                <div className="grid grid-cols-3 gap-6 p-[15px]">
                    <div>
                        <img src={
                            passDetails && passDetails.event_pass && passDetails.event_pass.image
                                ? passDetails.event_pass.image
                                : passDetails && passDetails.event && passDetails.event.banner_image
                        } alt="img" className="inline-block aspect-[9/16] object-cover rounded-lg" />
                    </div>
                    <div className="col-span-2">
                        <h2 style={{ color: textColor }} className={`text-lg text-[${textColor}] font-body font-semibold capitalize mb-2`}>
                            {passDetails && passDetails.event && passDetails.event.name}
                        </h2>
                        {/* <button style={{ color: textColor }} className={`bg-[${backGroundColor}] text-[${textColor}] px-[15px] mt-2 py-[8px] rounded-full border border-[#FFD1D3] font-body font-medium text-sm`}>
                            {passDetails && passDetails.event_pass.pass_type_name}
                        </button> */}
                    </div>
                </div>
                <div className="border-b-2 border-dashed border-[#f4f4f4] my-3 mx-4 relative">
                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border border-[#f4f4f4] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[25px] before:h-[21px] before:rounded-full before:border-[#f4f4f4] box-border flex items-center p-1`}></span>
                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border border-[#f4f4f4] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[25px] before:h-[21px] before:rounded-full before:border-[#f4f4f4] box-border flex items-center p-1`}></span>
                </div>

                <div className="p-[15px]">
                    <div className="flex items-center justify-between gap-4 max-w-[85%] m-auto">
                        <h2 className={`text-lg text-[${textColor}] font-body font-semibold`} style={{ color: secondaryColor }}> {passDetails && passDetails.event_pass && passDetails.event_pass.name}</h2>

                    </div>
                    {seasonArray.length > 0 && (

                        <div className="border border-secondary rounded-lg p-6 mt-4 max-w-[90%] m-auto" style={{ backgroundColor: backGroundColor }}>
                            <Swiper
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Pagination,]}
                                className={`mySwiper season-pass ${backGroundColor === '#000000' ? 'season-pass-white' : 'season-pass-black'}`}
                            >
                                {seasonArray.map((seasonSingle, indexSeason) => (
                                    <SwiperSlide key={indexSeason}>

                                        <div className="flex items-center justify-between gap-4 mb-4 mx-2">
                                            <p className={`text-lg text-[${textColor}] font-normal text-center`} style={{ color: textColor }}>{seasonSingle.date ? seasonSingle.date : ""} | {seasonSingle.start_time ? convertToAmPm(seasonSingle.start_time) : ''}</p>
                                            <div className={`text-lg text-[${textColor}] font-normal text-center`} style={{ color: textColor }}>
                                                {seasonSingle.available_quantity}
                                            </div>
                                        </div>
                                        <div className="mb-5">
                                            {/* Qr Image Display */}
                                            {(seasonSingle && seasonSingle.status !== null && (seasonSingle.status === "Expired" || seasonSingle.status === "Deleted" || seasonSingle.status === 'Scann' || seasonSingle.available_quantity === 0)) ? (
                                                <>
                                                    {seasonSingle.status === "Expired" ? (
                                                        <img src={ExpiredImage} alt="img" className="inline-block" />
                                                    ) : seasonSingle.status === "Deleted" ? (
                                                        <img src={DeletedImage} alt="img" className="inline-block" />
                                                    ) : seasonSingle.status === "Scann" ? (
                                                        <img src={ScannedImage} alt="img" className="inline-block" />
                                                    ) : seasonSingle.available_quantity === 0 ? (
                                                        <img src={SharedImage} alt="img" className="inline-block my-4" />
                                                    ) : passDetails && passDetails.event_pass && passDetails.event_pass.is_rfid == 'Yes' ? (
                                                        <img src={RFIDImage} alt="img" className="inline-block" />
                                                    ) : <img src={seasonSingle.qr_image} alt="img" className="inline-block" />}
                                                </>
                                            ) : passDetails && passDetails.event_pass && passDetails.event_pass.is_rfid == 'Yes' ?
                                                <img src={RFIDImage} alt="img" className="inline-block" />
                                                :
                                                <img src={seasonSingle.qr_image} alt="img" className="inline-block" />
                                            }
                                        </div>
                                        {/* Spit & Share */}
                                        <div className="flex gap-6 items-center justify-center mt-2 mb-6">

                                            {(seasonSingle && seasonSingle.status !== null && (seasonSingle.status === "Expired" || seasonSingle.status === "Deleted" || seasonSingle.status === 'Scann' || seasonSingle.available_quantity === 0)) ? (
                                                ''
                                            ) : passDetails && passDetails.event_pass && passDetails.event_pass.is_rfid == 'Yes' ?
                                                ''
                                                : (
                                                    <>
                                                        {passDetails && passDetails.is_transferable === 'Yes' && (
                                                            <div className="flex-1">
                                                                <button onClick={
                                                                    () => {
                                                                        toggleSplitModal()
                                                                        setUrlString(seasonSingle.url_string)
                                                                        setPassAvailableQuantity(seasonSingle.available_quantity)
                                                                    }
                                                                } style={{ backgroundColor: primaryColor }} className={`bg-[${primaryColor}] rounded-[10px] flex items-center justify-center text-base text-white font-body font-normal w-full h-[45px] m-auto`}>
                                                                    <i className="far fa-grin-beam mr-2"
                                                                        style={{ color: secondaryColor }}
                                                                    ></i>
                                                                    Split & Share
                                                                </button>
                                                            </div>
                                                        )}
                                                    </>
                                                )}


                                            <div className="flex-none">
                                                {seasonSingle && !isEmpty(seasonSingle.gmap_link) ? (
                                                    <Link to={passDetails && seasonSingle.gmap_link} target="_blank" className="bg-[#E7E7E7] rounded-full w-[56px] h-[56px] flex items-center justify-center ">
                                                        <img src={map} alt="img" className="inline-block" />
                                                    </Link>
                                                ) :
                                                    <>
                                                        {passDetails && passDetails.event && !isEmpty(passDetails.event.gmap_link) && (
                                                            <Link to={passDetails && passDetails.event && passDetails.event.gmap_link} target="_blank" className="bg-[#E7E7E7] rounded-full w-[56px] h-[56px] flex items-center justify-center ">
                                                                <img src={map} alt="img" className="inline-block" />
                                                            </Link>
                                                        )}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    )}
                    <div className="text-center">

                        {passDetails && passDetails.event_pass && passDetails.event_pass.notes && (
                            <p onClick={
                                () => {
                                    setShowTermsModal(true)
                                }
                            }
                                style={{ color: textColor }} className={`text-base font-body font-medium mt-4 text-[${textColor}] cursor-pointer`}>
                                Highlight Notes</p>
                        )}
                    </div>

                </div>

                <div className="border-b-2 border-dashed border-[#f4f4f4] my-3 mx-4 relative">
                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border border-[#f4f4f4] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[25px] before:h-[21px] before:rounded-full before:border-[#f4f4f4] box-border flex items-center p-1`}></span>
                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border border-[#f4f4f4] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[25px] before:h-[21px] before:rounded-full before:border-[#f4f4f4] box-border flex items-center p-1`}></span>
                </div>
                <div className="p-[15px]">
                    <>
                        <PriceDetail passDetails={passDetails}
                            pricingDetails={pricingDetails}
                            textColor={textColor}
                            secondaryColor={secondaryColor} />
                    </>

                </div>
                {!isEmpty(sponsorList) && (
                    <SponserList sponsorList={sponsorList} textColor={textColor} />
                )}
                <div className="border-b-2 border-dashed border-[#f4f4f4] my-3 mx-4 relative">
                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border border-[#f4f4f4] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[25px] before:h-[21px] before:rounded-full before:border-[#f4f4f4] box-border flex items-center p-1`}></span>
                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border border-[#f4f4f4] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[25px] before:h-[21px] before:rounded-full before:border-[#f4f4f4] box-border flex items-center p-1`}></span>
                </div>
                <div className="px-[15px] py-[20px]">
                    <div className="grid grid-cols-2 gap-5">
                        <img src={img} alt="img" className="inline-block w-full" />
                        <img src={img1} alt="img" className="inline-block w-full" />
                    </div>

                </div>
            </div>

        </>

    )
}
SeasonMePass.propTypes = {

}
export default SeasonMePass