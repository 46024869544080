import React, { Component, Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet';
import { generateEventSchema } from './EventSchema';

// SeoDetails component to generate meta tags and schema for the page 
export default class SeoDetails extends Component {
    render() {
        let { title, description, keywords, ogImage, schemaData, artist } = this.props; // get the props
        const canonical = getcanonical(); // get the canonical url

        // if title, description or ogImage is not available then take this as default values
        title = title || 'Mepass - Your Gateway to Exclusive Deals, Events & More | Made in Bharat';
        description = description || 'Discover convenience with Mepass - Made in Bharat Your go-to platform for seamless access to exclusive deals, events, and more. Join us in unlocking a world of opportunities and experiences, all at your fingertips.';
        // if ogImage is not available then take this as default image
        ogImage = ogImage || 'https://www.mepass.in/apple-touch-icon-192x192.png';

        // generate schema if schemaData is available
        const schema = schemaData ? generateEventSchema(schemaData, artist) : null;

        return (
            <Fragment>
                <MetaTags>
                    {/* meta tags */}
                    <title>{title}</title>
                    <meta name="title" content={title} />
                    <link rel="canonical" href={canonical} />
                    <meta name="description" content={description} />
                    {keywords && <meta name="keywords" content={keywords} />}

                    {/* open graph */}
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="Mepass.in" />
                    <meta property="og:image" content={ogImage} />
                    <meta property='og:url' content={canonical} />

                    {/* twitter card */}
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                    <meta name="twitter:image" content={ogImage} />

                    {/* facebook card */}
                    <meta property="fb:app_id" content="398678496479525" />
                </MetaTags>

                {/* helmet to add schema */}
                <Helmet>
                    {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script> }
                </Helmet>
            </Fragment>
        );
    }
}

// function to get canonical url
const getcanonical = () => {
    const url = window.location.href;
    //remove any query string from the url and # from the url
    let urlWithoutQuery = url.split('?')[0].split('#')[0];

    //remove last / from the url if exists
    if (urlWithoutQuery.endsWith('/')) {
        urlWithoutQuery = urlWithoutQuery.slice(0, -1);
    }
    return urlWithoutQuery;
}
