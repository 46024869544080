import PropTypes from "prop-types";
import React from "react";
import withRouter from "../../components/Common/withRouter";
import { useSelector } from "react-redux";
// Layout Related Components
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3"
import { useMatch } from "react-router-dom";
const LayoutWithoutHeaderFooter = props => {
  const { loading } = useSelector(state => ({
    loading: state.AppMaster.loading,
  }));
  const eventSellerMatch = useMatch("/event/:event_id/seller/:seller_id");
  const loginMatch = useMatch("/login");
  const signupMatch = useMatch("/sign-up");
  return (
    <>
      {eventSellerMatch ? (
        <React.Fragment >
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
            {props.children}
          </GoogleReCaptchaProvider>
        </React.Fragment >
      ) : loginMatch ?
        <React.Fragment>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
            {props.children}
          </GoogleReCaptchaProvider>
        </React.Fragment>
        : signupMatch ?
          <React.Fragment>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
              {props.children}
            </GoogleReCaptchaProvider>
          </React.Fragment>
          :
          (
            <React.Fragment>
              {props.children}
            </React.Fragment>
          )}

    </>
  );
};

LayoutWithoutHeaderFooter.propTypes = {
  children: PropTypes.any,
};

export default withRouter(LayoutWithoutHeaderFooter);
