import { call, put, takeEvery } from "redux-saga/effects"
import { fetchApiJSON } from "../../helpers/api"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  GET_DATA,
  ADD_UPDATE_DATA,
  LOGIN_USER,
  LOGOUT_USER,
  ADD_UPDATE_DATA_MULTIPLE_FORM,
} from "./actionTypes"
import {
  getDataFail,
  getDataSuccess,
  addUpdateDataSuccess,
  addUpdateDataFail,
  apiError,
} from "./actions"

function* onAddUpdateData({ payload: { data, history, url } }) {
  const authData = JSON.parse(localStorage.getItem("publicMePassUser"))
  const options = {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData,
    },
  }
  const apiUrl = process.env.REACT_APP_API_URL + url
  try {
    const response = yield call(fetchApiJSON, apiUrl, options)
    if (response.STATUS_CODE === 200) {
      toast.success(response.MESSAGE, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      yield put(addUpdateDataSuccess(response.DATA))
    } else {
      sendFinalError(response, history)
      yield put(getDataFail(response.MESSAGE))
    }
  } catch (error) {
    yield put(addUpdateDataFail(error))
  }
}
function* addUpdateDataMultipleForm({ payload: { data, history, url } }) {
  const authData = JSON.parse(localStorage.getItem("publicMePassUser"))
  // Create a new FormData object
  const formData = new FormData()
  // Append form fields to the FormData object
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })
  const options = {
    body: formData,
    method: "POST",
    headers: {
      Authorization: "Bearer " + authData,
    },
  }
  const apiUrl = process.env.REACT_APP_API_URL + url
  try {
    const response = yield call(fetchApiJSON, apiUrl, options)
    if (response.STATUS_CODE === 200) {
      toast.success(response.MESSAGE, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      yield put(addUpdateDataSuccess(response.DATA))
    } else {
      sendFinalError(response, history)
      yield put(getDataFail(response.MESSAGE))
    }
  } catch (error) {
    yield put(addUpdateDataFail(error))
  }
}
function* onGetData({ payload: { data, history, url } }) {
  const authData = JSON.parse(localStorage.getItem("publicMePassUser"))
  const options = {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData,
    },
  }
  const apiUrl = process.env.REACT_APP_API_URL + url
  try {
    const response = yield call(fetchApiJSON, apiUrl, options)
    if (response.STATUS_CODE == 401) {
      sendFinalError(response, history)
    } else {
      if (url === "v1/public/check-mobile" || url === "v1/public/check-email") {
        yield put(getDataSuccess(response))
      } else {
        if (response.STATUS_CODE === 200 || response.STATUS_CODE === 404) {
          if ((url == "v1/public/event-detail/artist-view" && response.STATUS_CODE === 404) ||
            (url == "v1/public/event-detail/merchant-view" && response.STATUS_CODE === 404) ||
            (url == "v1/public/qr-pass/view" && response.STATUS_CODE === 404)
          ) {
            history("/page-404")
          } else if (url == "v1/public/notification/list") {
            localStorage.setItem(
              "unread_notification_count",
              JSON.stringify(response.DATA.unread_notification_count ? response.DATA.unread_notification_count : 0),
            )
            yield put(getDataSuccess(response.DATA))
          } else {
            yield put(getDataSuccess(response.DATA))
          }
        } else {
          if (url == "v1/public/cms-page") {
            history("/page-404")
          } else {
            sendFinalError(response, history)
            yield put(getDataFail(response.MESSAGE))
            if (url !== "v1/public/payment/make-payment") {
              history("/page-404")
            }
          }
        }
      }
    }
  } catch (error) {
    yield put(addUpdateDataFail(error))
  }
}
function* loginUser({ payload: { data, history, url } }) {
  try {
    const options = {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
    const apiUrl = process.env.REACT_APP_API_URL + url
    const response = yield call(fetchApiJSON, apiUrl, options)
    if (response.STATUS_CODE === 200) {
      localStorage.setItem(
        "publicMePassUser",
        JSON.stringify(response.DATA.token),
      )
      localStorage.setItem(
        "publicMePassUserDetails",
        JSON.stringify(response.DATA.userDetails),
      )
      localStorage.setItem(
        "unread_notification_count",
        JSON.stringify(response.DATA.unread_notification_count ? response.DATA.unread_notification_count : 0),
      )
      yield put(getDataSuccess(response.DATA))
    } else if (response.STATUS_CODE === 500) {
      toast.error(response.MESSAGE, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      toast.error(response.MESSAGE, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      yield put(apiError(response.MESSAGE))
    }
  } catch (error) {
    yield put(apiError(error))
  }
}
function* logoutUser({ payload: { history, url } }) {
  localStorage.removeItem("publicMePassUser")
  localStorage.removeItem("publicMePassUserDetails")
  localStorage.removeItem('passDetailForLocalStorage');
  localStorage.removeItem('selectedDateForLocalStorage');
  localStorage.removeItem('promoCode');
  yield put(getDataSuccess({}))
  history("/login", { state: { previousPage: 'Login' } })
}

function sendFinalError(response, history) {
  if (response.STATUS_CODE === 401) {
    toast.error(response.MESSAGE, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    localStorage.removeItem("publicMePassUser")
    localStorage.removeItem("publicMePassUserDetails")
    localStorage.removeItem('passDetailForLocalStorage');
    localStorage.removeItem('selectedDateForLocalStorage');
    localStorage.removeItem('promoCode');
    history("/login", { state: { previousPage: 'Login' } })
  } else if (response.STATUS_CODE === 408) {
    toast.error(response.MESSAGE, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    history("/home")
  } else {
    toast.error(response.MESSAGE, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}
function* appMasterSaga() {
  yield takeEvery(ADD_UPDATE_DATA, onAddUpdateData)
  yield takeEvery(GET_DATA, onGetData)
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(ADD_UPDATE_DATA_MULTIPLE_FORM, addUpdateDataMultipleForm)
}
export default appMasterSaga