import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import withRouter from "../Common/withRouter";
import logo from "../../assets/light_theme_images/logo.svg";
import notification from "../../assets/images/small/notification.png";
import user from "../../assets/images/small/user.png";
import locationIcon from "../../assets/light_theme_images/location.svg";
import downArrow from "../../assets/light_theme_images/down-arrow.svg";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { clearData, getData } from "../../store/AppMaster/actions";
import api from "../../constants/api";
import SelectCityModal from "./SelectCityModal";
import SidebarMenu from "./SidebarMenu";
import ProfileMenu from "./ProfileMenu";
import searchIcon from "../../assets/light_theme_images/search.svg";

const Header = (props) => {
  const authUserDetails = JSON.parse(
    localStorage.getItem("publicMePassUserDetails")
  );
  const [cityList, setCityList] = useState([]);
  const [isApiCallHeader, setIsApiCallHeader] = useState(0);
  const [isUpdateCityData, setIsUpdateCityData] = useState(false);
  const [activeItem, setActiveItem] = useState("/home");
  const fullUrl = window.location.href;
  const appUrl = fullUrl.split("/");
  const lastSegment = appUrl[appUrl.length - 1];
  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));

  const [sidebarArray, setSidebarArray] = useState([]);

  useEffect(() => {
    if (isApiCallHeader === 0) {
      getCityCategory(props.router.params.city_name);
      setIsApiCallHeader(1);
    }
  }, [isApiCallHeader]);

  const getCityCategory = (city) => {
    setIsUpdateCityData(true);
    setSidebarArray([]);
    setCityList([]);
    const requestData = {
      city: city,
    };
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.cmsPageDropdown));
  };

  if (!isEmpty(data) && !isEmpty(data.city_list) && isUpdateCityData) {
    setCityList(data.city_list);
    const categoryArray = [];
    sidebarArray.push({ name: "Home", link: "/home", submenu: [] });
    let link = "/events";
    if (props.router.params.category_name && props.router.params.city_name) {
      link = `/city/${props.router.params.city_name}/category/${props.router.params.category_name}/events`;
    } else if (props.router.params.category_name) {
      link = `/category/${props.router.params.category_name}/events`;
    } else if (props.router.params.city_name) {
      link = `/city/${props.router.params.city_name}/events`;
    }
    sidebarArray.push({ name: "Events", link: link, submenu: [] });

    if (!isEmpty(data.categoriesWithCity)) {
      data.categoriesWithCity.map((item) => {
        let link = "";
        if (props.router.params.city_name) {
          link = `/city/${props.router.params.city_name}/category/${item.slug}`;
        } else {
          link = `/category/${item.slug}`;
        }
        item.link = link;
        categoryArray.push(item);
      });
    }
    if (!isEmpty(data.categoriesWithoutCity)) {
      data.categoriesWithoutCity.map((item) => {
        let link = "";
        link = `/category/${item.slug}`;
        item.link = link;
        categoryArray.push(item);
      });
    }

    if (!isEmpty(categoryArray)) {
      categoryArray.map((item, itemIndex) => {
        let categoryName =
          item.name.charAt(0).toUpperCase() + item.name.slice(1);
        if (itemIndex < 2) {
          sidebarArray.push({
            name: categoryName,
            link: item.link,
            submenu: [],
          });
        } else if (itemIndex == 2) {
          sidebarArray.push({
            name: "More",
            link: "#",
            submenu: [{ name: categoryName, link: item.link }],
          });
        } else if (itemIndex > 2) {
          sidebarArray[4] &&
            sidebarArray[4].submenu &&
            sidebarArray[4].submenu.push({
              name: categoryName,
              link: item.link,
            });
        }
      });
    }
    setIsUpdateCityData(false);
  }

  const [sidebarToggle, setSidebarToggle] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarToggle(true);
  };

  const handleNavLinkClick = () => {
    setSidebarToggle(false); // Close sidebar when a link is clicked
  };

  const [isShowCityModal, setShowCityModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleCityModal = () => {
    setShowCityModal(!isShowCityModal);
  };

  const handleOnSelect = (item) => {
    setSelectedItem(item.city); // Set the selected item as active
    getCityCategory(item.city);
    setShowCityModal(false);
    if (lastSegment === "events") {
      let link = "/events";
      if (props.router.params.category_name) {
        link = `/city/${item.city}/category/${props.router.params.category_name}/events`;
      } else {
        link = `/city/${item.city}/events`;
      }
      props.router.navigate(link);
    } else {
      props.router.navigate(`/city/${item.city}`);
    }
  };

  // State to track which submenu is open
  const [openSubmenu, setOpenSubmenu] = useState(null);

  // Function to toggle submenu visibility
  const toggleSubmenu = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  // Function to set active menu or submenu
  const handleActiveItem = (path) => {
    setActiveItem(path);
  };

  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);

  // Function to toggle submenu
  const toggleSubmenuIndex = (index) => {
    setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
  };

  return (
    <React.Fragment>
      <div className="relative z-10">
        <nav className="bg-white w-full header-border-liner-gradient">
          <div className="container mx-auto py-[15px]">
            <div className="relative flex h-16 items-center justify-between gap-3 md:gap-14">
              <div className="w-full md:w-[163.63px]">
                <Link to="/home">
                  <img className="w-auto" src={logo} alt="Your Company" />
                </Link>
              </div>
              <div className="flex lg:flex-auto items-center justify-end">
                <div className="hidden xl:block">
                  <div className="flex gap-6">
                    <SidebarMenu
                      sidebarArray={sidebarArray}
                      toggleSubmenu={toggleSubmenu}
                      handleActiveItem={handleActiveItem}
                      activeItem={activeItem}
                      openSubmenu={openSubmenu}
                      isResponsive={false}
                    />
                    <div className="flex gap-4">
                      {/* To Do Remove As Of Now
                      <div className="flex-none">
                        <div className="size-12 flex items-center justify-center cursor-pointer search-border-liner-gradient">
                          <img
                            src={searchIcon}
                            alt="search icon"
                            className="inline-block"
                          />
                        </div>
                      </div> */}
                      <div className="relative">
                        <div
                          className="w-[150px] md:w-[210px] select-location-border-liner-gradient px-2 py-1.5 md:py-2 md:px-3 relative"
                        >
                          <div
                            className="flex items-center cursor-pointer relative z-10"
                            onClick={
                              () => {
                                setShowCityModal(true)
                              }
                            }
                          >
                            <div className="flex-1">
                              <div className="flex items-center">
                                <img
                                  src={locationIcon}
                                  alt="img"
                                  className="inline-block mr-2"
                                />
                                <p className="text-sm md:text-lg text-black font-medium">
                                  {props.router.params.city_name
                                    ? props.router.params.city_name
                                    : "Select your City"}
                                </p>
                              </div>
                            </div>
                            <div className="flex-none">
                              <img
                                src={downArrow}
                                className="inline-block ml-2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <ProfileMenu
                        notification={notification}
                        authUserDetails={authUserDetails}
                        user={user}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <div className="block xl:hidden mr-3">
                      <div className="flex gap-1.5">
                        <div className="relative">
                          <div
                            className="size-[39px] flex items-center justify-center cursor-pointer search-border-liner-gradient"
                            onClick={
                              () => {
                                setShowCityModal(true)
                              }
                            }
                          >
                            <div
                              className="flex items-center cursor-pointer"
                              onClick={
                                () => {
                                  setShowCityModal(true)
                                }
                              }
                            >
                              <i className="fa-solid fa-location-dot text-[#101010]"></i>
                              {/* <p className="text-sm md:text-lg text-black font-normal">
                                {props.router.params.city_name
                                  ? props.router.params.city_name
                                  : ""}
                              </p> */}
                            </div>
                          </div>
                        </div>
                        <ProfileMenu
                          notification={notification}
                          authUserDetails={authUserDetails}
                          user={user}
                        />
                      </div>
                    </div>
                    {/* {sidebarToggle && ( */}
                    <SidebarMenu
                      sidebarArray={sidebarArray}
                      toggleSubmenu={toggleSubmenu}
                      handleActiveItem={handleActiveItem}
                      activeItem={activeItem}
                      openSubmenu={openSubmenu}
                      isResponsive={true}
                      handleSidebarToggle={handleSidebarToggle}
                      sidebarToggle={sidebarToggle}
                      handleNavLinkClick={handleNavLinkClick}
                      toggleSubmenuIndex={toggleSubmenuIndex}
                      openSubmenuIndex={openSubmenuIndex}
                    />
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {/* modal city */}
      {isShowCityModal ? (
        <SelectCityModal
          isShowCityModal={isShowCityModal}
          setShowCityModal={setShowCityModal}
          cityList={cityList}
          handleOnSelect={handleOnSelect}
          props={props}
        />
      ) : null}
    </React.Fragment>
  );
};

export default withRouter(Header);
