import { isEmpty } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import img from "../../../assets/images/App-Store.png";
import DeletedImage from "../../../assets/images/default/Deleted-image.png";
import ExpiredImage from "../../../assets/images/default/Expired-image.png";
import RFIDImage from "../../../assets/images/default/RFID-image.png";
import ScannedImage from "../../../assets/images/default/Scanned-image.png";
import SharedImage from "../../../assets/images/default/Shared-image.png";
import img2 from "../../../assets/images/small/pass-left-frame.svg"
import img3 from "../../../assets/images/small/pass-right-frame.svg"
import img4 from "../../../assets/images/small/dandiya-left-icon.svg"
import img5 from "../../../assets/images/small/dandiya-right-icon.svg"
import img6 from "../../../assets/images/google-play-light.svg";
import img7 from "../../../assets/images/App-Store-light.svg"
import map from "../../../assets/images/small/map.png";
import logo from "../../../assets/images/mepass-dark-logo.svg";
import PriceDetail from "./PriceDetail";
import SponserList from "../SponserList";
import PassDetails from "./PassDetails";
import SeasonMePass from "./SeasonMePass";
const Navratri2024 = ({
    passDetails,
    seasonArray,
    pricingDetails,
    sponsorList,
    textColor,
    primaryColor,
    secondaryColor,
    backGroundColor,
    toggleSplitModal,
    setShowTermsModal,
    setUrlString,
    setPassAvailableQuantity,
}) => {
    return (
        <>
            <div className={`bg-[#f4f4f4] min-h-screen h-full`}>
                <div className="w-[90%] md:w-[398px] m-auto py-10">
                    <div className="m-auto mb-6 max-w-[160px]">
                        <img src={logo} alt="mepass" />
                    </div>
                    {passDetails && passDetails.pass_type_slug == 'season-mepass' ? (
                        <SeasonMePass
                            seasonArray={seasonArray}
                            passDetails={passDetails}
                            pricingDetails={pricingDetails}
                            sponsorList={sponsorList}
                            textColor={textColor}
                            primaryColor={primaryColor}
                            secondaryColor={secondaryColor}
                            backGroundColor={backGroundColor}
                            RFIDImage={RFIDImage}
                            ExpiredImage={ExpiredImage}
                            DeletedImage={DeletedImage}
                            ScannedImage={ScannedImage}
                            SharedImage={SharedImage}
                            map={map}
                            toggleSplitModal={toggleSplitModal}
                            setShowTermsModal={setShowTermsModal}
                            setUrlString={setUrlString}
                            setPassAvailableQuantity={setPassAvailableQuantity}
                        />
                    ) : (
                        <div className={`rounded-[10px] border-[3px] border-[#FFD685]`} style={{ backgroundColor: backGroundColor }}>
                            <PassDetails passDetails={passDetails}
                                textColor={textColor}
                                backGroundColor={backGroundColor}
                                primaryColor={primaryColor} />
                            <div className="border-b-2 border-dashed my-3 mx-4 relative" style={{ borderColor: primaryColor }}>
                                <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                                <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                            </div>
                            <div className="relative">
                                <img src={img2} alt="img" className="inline-block absolute left-0 -top-[13px]" />
                                <img src={img3} alt="img" className="inline-block absolute right-0 -top-[13px]" />
                            </div>
                            <div className="p-[15px]">
                                <div className="text-center">
                                    <div className="max-w-[75%] m-auto">
                                        {(passDetails && passDetails.status !== null && (passDetails.status === "Expired" || passDetails.status === "Deleted" || passDetails.status === 'Scann' || passDetails.available_quantity === 0)) ? (
                                            <>
                                                {passDetails.status === "Expired" ? (
                                                    <img src={ExpiredImage} alt="img" className="inline-block my-4" />
                                                ) : passDetails.status === "Deleted" ? (
                                                    <img src={DeletedImage} alt="img" className="inline-block my-4" />
                                                ) : passDetails.status === "Scann" ? (
                                                    <img src={ScannedImage} alt="img" className="inline-block my-4" />
                                                ) : passDetails.available_quantity === 0 ? (
                                                    <img src={SharedImage} alt="img" className="inline-block my-4" />
                                                ) : passDetails && passDetails.event_pass && passDetails.event_pass.is_rfid == 'Yes' ? (
                                                    <img src={RFIDImage} alt="img" className="inline-block my-4" />
                                                ) : ''}
                                            </>
                                        ) : passDetails && passDetails.event_pass && passDetails.event_pass.is_rfid == 'Yes' ?
                                            <img src={RFIDImage} alt="img" className="inline-block my-4" />
                                            :
                                            <img src={passDetails && passDetails.qr_image} alt="img" className="inline-block my-4" />
                                        }
                                    </div>
                                    {((passDetails && passDetails.event_pass && passDetails.event_pass.is_rfid == 'Yes') || (passDetails && passDetails.status !== null && (passDetails.status === "Expired" || passDetails.status === "Deleted" || passDetails.status === 'Scann' || passDetails.available_quantity === 0))) ? (
                                        <>
                                            <div className="relative">
                                                <img src={img4} className="inline-block absolute -left-[7px] -top-[50px]" />
                                                <img src={img5} className="inline-block absolute -right-[7px] -top-[50px]" />
                                                <div className={'flex items-center justify-center'}>
                                                    {passDetails && passDetails.event_date && !isEmpty(passDetails.event_date.gmap_link) ? (
                                                        <div className="flex-none">
                                                            <Link to={passDetails && passDetails.event_date && passDetails.event_date.gmap_link} target="_blank"
                                                                rel="noopener noreferrer" className="bg-[#E7E7E7] rounded-full w-[56px] h-[56px] flex items-center justify-center ">
                                                                <img src={map} alt="img" className="inline-block" />
                                                            </Link>
                                                        </div>
                                                    ) :
                                                        <>
                                                            {passDetails && passDetails.event && !isEmpty(passDetails.event.gmap_link) && (
                                                                <div className="flex-none">
                                                                    <Link to={passDetails && passDetails.event && passDetails.event.gmap_link} target="_blank"
                                                                        rel="noopener noreferrer" className="bg-[#E7E7E7] rounded-full w-[56px] h-[56px] flex items-center justify-center ">
                                                                        <img src={map} alt="img" className="inline-block" />
                                                                    </Link>
                                                                </div>
                                                            )}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    ) :
                                        <>
                                            <div className="relative">
                                                <img src={img4} className="inline-block absolute -left-[7px] -top-[50px]" />
                                                <img src={img5} className="inline-block absolute -right-[7px] -top-[50px]" />
                                                <div className={passDetails && passDetails.is_transferable === 'No' ? 'flex items-center justify-center' : 'flex items-center justify-center'}>
                                                    {passDetails && passDetails.is_transferable === 'Yes' && (
                                                        <button onClick={toggleSplitModal} className={`splite-share-button rounded-[7px] text-base border-[2px] border-primary text-white font-semibold py-2 px-5 mr-3`}>
                                                            Split & Share
                                                        </button>
                                                    )}
                                                    {passDetails && passDetails.event_date && passDetails.event_date.gmap_link ? (
                                                        <Link to={passDetails && passDetails.event_date && passDetails.event_date.gmap_link} target="_blank"
                                                            rel="noopener noreferrer" className="bg-white border border-primary rounded-full w-[50px] h-[50px] flex items-center justify-center">
                                                            <img src={map} alt="img" className="inline-block" />
                                                        </Link>
                                                    ) :
                                                        <>
                                                            {passDetails && passDetails.event && !isEmpty(passDetails.event.gmap_link) ? (
                                                                <Link to={passDetails && passDetails.event && passDetails.event.gmap_link} target="_blank"
                                                                    rel="noopener noreferrer" className="bg-white border border-primary rounded-full w-[50px] h-[50px] flex items-center justify-center ">
                                                                    <img src={map} alt="img" className="inline-block" />
                                                                </Link>
                                                            ) : ''}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {passDetails && passDetails.event_pass && passDetails.event_pass.notes && (
                                        <p onClick={
                                            () => {
                                                setShowTermsModal(true)
                                            }
                                        }
                                            style={{ color: textColor }} className={`text-base font-body font-medium mt-4 text-[${textColor}] cursor-pointer`}>
                                            Highlight Notes</p>
                                    )}
                                </div>

                                <div className="mt-4">
                                    <>
                                        <PriceDetail passDetails={passDetails}
                                            pricingDetails={pricingDetails}
                                            textColor={textColor}
                                            secondaryColor={secondaryColor} />
                                    </>

                                </div>
                            </div>

                            <div className="border-b-2 border-dashed  my-3 mx-4 relative" style={{ borderColor: primaryColor }}>
                                <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                                <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                            </div>
                            <div className="p-[15px] pt-0">
                                <h2 className={`text-2xl text-[${textColor}] font-body font-semibold text-center`} style={{ color: textColor }}>Contact Support</h2>
                                <div className="flex items-center gap-2 mt-3">
                                    <Link
                                        to={`https://wa.me/${passDetails.contact_mobile_number}`}
                                        target="_blank"
                                        className="bg-white text-xs md:text-sm text-[#5A5A5A] border border-[#E7E7E7] rounded-[10px] py-2 md:py-3 px-3 md:px-4 w-full text-nowrap"
                                    >
                                        <i class="fab fa-whatsapp text-black text-xl mr-1 md:mr-2 align-middle"></i>WhatsApp us
                                    </Link>
                                    <Link
                                        to={`mailto:${passDetails.contact_email}`}
                                        target="_blank"
                                        className="bg-white text-[#5A5A5A] border border-[#E7E7E7] rounded-[10px] text-xs md:text-sm py-2 md:py-3 px-3 md:px-4 w-full text-nowrap">
                                        <i class="fas fa-envelope text-black text-xl mr-1 md:mr-2 align-middle"></i>
                                        support@mepass.in
                                    </Link>

                                </div>
                            </div>
                            {!isEmpty(sponsorList) && (
                                <SponserList sponsorList={sponsorList} textColor={textColor} />
                            )}
                            <div className="border-b-2 border-dashed  my-3 mx-4 relative" style={{ borderColor: primaryColor }}>
                                <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                                <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                            </div>
                            <div className="px-[15px] pb-[20px]">
                                <p className={`text-lg font-normal text-[${textColor}] text-center`} style={{ color: textColor }}>Download our App</p>
                                <div className="grid grid-cols-2 gap-5 mt-4">
                                    {process.env.REACT_APP_PLAY_STORE_URL ?
                                        <Link to={process.env.REACT_APP_PLAY_STORE_URL} target="_blank">
                                            <img src={img6} alt="img" className="inline-block w-full" />
                                        </Link>
                                        :
                                        <img src={img6} alt="img" className="inline-block w-full" />
                                    }
                                    {process.env.REACT_APP_APP_STORE_URL ?
                                        <Link to={process.env.REACT_APP_APP_STORE_URL} target="_blank">
                                            <img src={img7} alt="img" className="inline-block w-full" />
                                        </Link>
                                        :
                                        <img src={img7} alt="img" className="inline-block w-full" />
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="mt-4 text-center">
                        <p className={`text-sm text-[#1A1A1A] font-body font-normal text-center`}>Technology Partner :  <Link to="https://www.bsptechno.com/" target="blanck" className=" ml-1">BSP Technologies</Link></p>
                    </div>
                </div>
            </div >

        </>

    );
};

export default Navratri2024;