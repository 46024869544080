import React from "react";
import { Link } from "react-router-dom";
import icon from "../../assets/images/small/vector.webp";
import gifUrl from "../../assets/images/PaymentSuccessful.gif";
import copyIcon from "../../assets/light_theme_images/copy-icon.svg";
import withRouter from "../../components/Common/withRouter";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import api from "../../constants/api";
import { getData } from "../../store/AppMaster/actions";
import { useParams } from "react-router-dom";
const ThankYou = (props) => {
  const params = useParams();
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(0);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [billingData, setBillingData] = useState("");
  const authData = JSON.parse(localStorage.getItem("publicMePassUserDetails"))

  useEffect(() => {
    if (isApiCall === 0) {
      setIsUpdateData(true);
      const requestData = { order_id: params.order_id };
      dispatch(getData(requestData, props.router.navigate, api.BillingInfo));
      setIsApiCall(1);
    }
  }, [isApiCall, dispatch, props.router.navigate, params.order_id]);

  if (!isEmpty(data) && !isEmpty(data.billingDetail) && isUpdateData) {
    setBillingData(data);
    setIsUpdateData(false);
  }
  const [copySuccess, setCopySuccess] = useState(false);
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false); // Clear the message after 3 seconds
        }, 2000);
      })
      .catch((err) => {
        setCopySuccess(false);
      });
  };

  return (
    <div className="bg-white pt-14 pb-14">
      <div className="container">
        <div className="lg:px-14">
          <h2 className="text-3xl md:text-4xl text-[#101010] font-semibold text-center">
            Payment Successful!
          </h2>
          <p className="text-lg text-[#101010] font-normal text-center mt-2">
            Your Mepass is secured! Get ready to dive into an unforgettable experience.
          </p>
          <div className="flex justify-center">
            <div className="w-[400px] h-[400px]">
              <img src={gifUrl} alt="Example GIF" />
            </div>
          </div>

          <div className="bg-[#F9F9F9] p-3 md:p-7 rounded-lg mt-2">
            <h3 className="text-[22px] md:text-3xl text-[#101010] font-semibold mb-4">
              Booking Details
            </h3>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 md:gap-5">
              <div>
                <span className="text-lg text-[#101010] font-medium mb-2">
                  Name
                </span>
                <h4 className="text-base md:text-xl text-[#CB2129] font-semibold">
                  {authData && authData.name}
                </h4>
              </div>
              <div>
                <span className="text-lg text-[#101010] font-medium mb-2">
                  Email
                </span>
                <h4 className="text-base md:text-xl text-[#CB2129] font-semibold break-all">
                  {authData && authData.email}
                </h4>
              </div>
              <div>
                <span className="text-lg text-[#101010] font-medium mb-2">
                  Mobile no.
                </span>
                <h4 className="text-base md:text-xl text-[#CB2129] font-semibold">
                  {authData && authData.mobile}
                </h4>
              </div>
              <div>
                <span className="text-lg text-[#101010] font-medium mb-2">
                  Booking ID
                </span>
                <h4 className="text-base md:text-xl text-[#CB2129] font-semibold break-all">
                  {params.order_id ? params.order_id : ""}
                  <img
                    src={copyIcon}
                    alt=""
                    className="inline-block ml-3"
                    onClick={() =>
                      copyToClipboard(params.order_id ? params.order_id : "")
                    }
                  />
                  {copySuccess && (
                    <span className="text-[#00FF00] text-sm font-body font-normal ml-2">
                      Copied!
                    </span>
                  )}
                </h4>
              </div>
              <div>
                <span className="text-lg text-[#101010] font-medium mb-2">
                  Ticket
                </span>
                <h4 className="text-base md:text-xl text-[#CB2129] font-semibold">
                  {billingData &&
                    billingData.totalPass &&
                    billingData.totalPass}{" "}
                  ticket
                </h4>
              </div>
              <div>
                <span className="text-lg text-[#101010] font-medium mb-2">
                  Order total
                </span>
                <h4 className="text-base md:text-xl text-[#CB2129] font-semibold">
                  {" "}
                  ₹
                  {billingData &&
                    billingData.totalAmount &&
                    billingData.totalAmount}
                </h4>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5 mt-5 md:mt-9">
              <Link to="/home" className="text-[#CB2129] border border-[#CB2129] bg-white text-lg text-center rounded-lg font-semibold py-2 px-4">
                Go to home
              </Link>
              {billingData.billingDetail &&
                <Link to={"/qr/" + billingData.billingDetail[0].url_string} className="text-[#FFFFFF] btn-liner-gradient text-lg text-center rounded-lg font-semibold py-2 px-4">
                  View ticket
                </Link>
              }

            </div>
          </div>
        </div>

        {/* ----------------card-start--------------- */}
        {/* <div className="hidden md:block">
          <div className="bg-black p-5 rounded-lg">
            <h3 className="text-[22px] md:text-2xl text-white font-body font-normal mb-5">
              Booking ID : {params.order_id ? params.order_id : ""}
              <img
                src={icon}
                alt=""
                className="inline-block ml-4"
                onClick={() =>
                  copyToClipboard(params.order_id ? params.order_id : "")
                }
              />
              {copySuccess && (
                <span className="text-[#00FF00] text-sm font-body font-normal ml-2">
                  Copied!
                </span>
              )}
            </h3>
            <div className="flex items-center justify-between">
              <h3 className="text-[20px] text-white font-body font-semibold">
                Ticket Quantity
              </h3>
              <span className="text-[20px] text-white font-body font-semibold">
                {billingData && billingData.totalPass && billingData.totalPass}
              </span>
            </div>
            <div className="border-y border-[#F6F6F6] py-5 my-5">
              {billingData &&
                billingData.billingDetail &&
                billingData.billingDetail.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center justify-between mb-2"
                    >
                      <p className="text-base text-[#DCDCDC] font-body font-normal">
                        {item.pass_count} x {item.passName}
                      </p>
                      <p className="text-base text-[#DCDCDC] font-body font-medium">
                        ₹ {item.pass_amount}
                      </p>
                    </div>
                  );
                })}
            </div>
            <div className="flex items-center justify-between">
              <span className="text-xl text-white font-body font-semibold">
                Total Amount
              </span>
              <p className="text-xl text-white font-body font-semibold">
                ₹
                {billingData &&
                  billingData.totalAmount &&
                  billingData.totalAmount}
              </p>
            </div>
          </div>
        </div> */}
        {/* ----------------card-end---------------- */}
      </div>
    </div>
  );
};

export default withRouter(ThankYou);
