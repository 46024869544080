import React from "react";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
const PaymentUnsuccessfull = () => {

  const gifUrl =
    "https://s3-alpha-sig.figma.com/img/edad/6672/a36292c45799b50d3917c2d6f51f6e30?Expires=1733702400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=KuG6SvcraKPVfymx~2Ibk95vXODV5IMTSj7D8XRNuqjIy1KakJBROwCwI2ubVVHGhyXpdK~1Uorb6MyPMrEl5Y~3t2J8SjedmTZtM0RnA5i19SfU~VKIHPmhXQA73YsGErhkM84g-VP~wDuSw1OPKY3A~k5ddlw57bxlz6d6pnWSWHlEr-2fuphcvIiwsqN-NCgSJdIxIlrXGcSQpcz5KzowXfD3DJBueN0qoz5O6DBmbCmLd6Sr5bHS3k-W~WAy6TnPQDfyGCYB3R8vc2gnH1ZwHS7rKS~BtWreD5hbdHJqMM4lgxuNtztQ7JXYqayfTfQoGgDXU3~WTbsloFTZLw__";

  return (
    <div className="bg-white pt-14 pb-14">
      <div className="container">
        <div className="lg:px-14">
          <h2 className="text-3xl md:text-4xl text-[#101010] font-semibold text-center">
          Payment Unsuccessful!
          </h2>
          <p className="text-lg text-[#101010] font-normal text-center mt-2">
          Payment unsuccessful! Your Mepass adventure is on hold. Give it another shot!
          </p>
          <div className="flex justify-center mt-5 md:mt-9">
            <div className="w-[400px] h-[400px]">
              <img src={gifUrl} alt="Example GIF"/>
            </div>
          </div>
          <div className="flex justify-center mt-5 md:mt-9">
            <Link to="" 
            className="text-[#FFFFFF] btn-liner-gradient text-lg flex justify-center items-center rounded-lg font-semibold py-2 px-4 h-[56px] w-[172px] md:w-[524px]">
            Try Again
              </Link>
            </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PaymentUnsuccessfull);
