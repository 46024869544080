import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import api from "../../constants/api";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import { convertToAmPm } from "../../common/commonFunction";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
import { Link } from "react-router-dom";
import img from "../../assets/images/App-Store.png";
import img1 from "../../assets/images/google-play.png";
import img2 from "../../assets/images/small/pass-left-frame.svg"
import img3 from "../../assets/images/small/pass-right-frame.svg"
import img4 from "../../assets/images/small/dandiya-left-icon.svg"
import img5 from "../../assets/images/small/dandiya-right-icon.svg"
import PriceDetail from "./PriceDetail";
import SponserList from "./SponserList";
const DandiyaMepass = ({
    seasonArray,
    passDetails,
    textColor,
    primaryColor,
    secondaryColor,
    backGroundColor,
    RFIDImage,
    ExpiredImage,
    DeletedImage,
    ScannedImage,
    SharedImage,
    map,
    toggleSplitModal,
    setShowTermsModal,
    pricingDetails,
    sponsorList,
    setUrlString
}) => {

    return (

        <>
            <div className={`rounded-[10px] border-[3px] border-[#FFD685]`} style={{ backgroundColor: backGroundColor }}>
                <div className="grid grid-cols-3 gap-6 p-[15px]">
                    <div>
                        <img src={
                            passDetails && passDetails.event_pass && passDetails.event_pass.image
                                ? passDetails.event_pass.image
                                : passDetails && passDetails.event && passDetails.event.banner_image
                        } alt="img" className="inline-block aspect-[9/16] object-cover rounded-lg" />
                    </div>
                    <div className="col-span-2">
                        <div className="grid grid-flow-row-dense grid-row-5 h-full content-stretch	">
                            <div className="row-span-4">
                                <h2 style={{ color: textColor }} className={`text-lg text-[${textColor}] font-body font-semibold capitalize mb-2 `}>
                                    {passDetails && passDetails.event && passDetails.event.name}
                                </h2>
                            </div>
                            <div className="row-span-1">
                                <h2 className={`text-lg text-primary font-body font-semibold capitalize mb-2`}>
                                    Gold
                                </h2>
                                <Link to="" className="text-base font-normal text-[#5A5A5A] text-center underline decoration-1">View more details<i class="fas fa-arrow-right ml-2"></i></Link>
                            </div>
                        </div>
                        {/* <button style={{ color: textColor }} className={`bg-[${backGroundColor}] text-[${textColor}] px-[15px] mt-2 py-[8px] rounded-full border border-[#FFD1D3] font-body font-medium text-sm`}>
                            {passDetails && passDetails.event_pass.pass_type_name}
                        </button> */}
                    </div>
                </div>
                <div className="border-b-2 border-dashed border-[#EE3E45] my-3 mx-4 relative">
                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                </div>
                <div className="relative">
                    <img src={img2} alt="img" className="inline-block absolute left-0 -top-[13px]" />
                    <img src={img3} alt="img" className="inline-block absolute right-0 -top-[13px]" />
                </div>

                <div className="p-[15px]">
                    {/* <div className="flex items-center justify-between gap-4 max-w-[85%] m-auto">
                        <h2 className={`text-lg text-[${textColor}] font-body font-semibold`} style={{ color: secondaryColor }}> {passDetails && passDetails.event_pass && passDetails.event_pass.name}</h2>
                    </div> */}
                    {seasonArray.length > 0 && (

                        <div className="border border-[#C8C8C8] rounded-lg p-4 md:p-6 mt-4 bg-[#FAFAFA]">
                            <Swiper
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Pagination, Autoplay]}
                                className={`mySwiper season-pass dandiya-season-pass ${backGroundColor === '#000000' ? 'season-pass-white' : 'season-pass-black'}`}
                            >
                                {seasonArray.map((seasonSingle, indexSeason) => (
                                    <SwiperSlide key={indexSeason}>

                                        <div className="flex items-center justify-between gap-4 mb-3">
                                            <div className="flex-1">
                                                <div className="flex gap-3">
                                                    <p className={`text-base md:text-lg text-[${textColor}] font-normal text-center`} style={{ color: textColor }}><i class="fas fa-calendar-minus text-sm align-text-bottom mr-1 text-[#5A5A5A]"></i>{seasonSingle.date ? seasonSingle.date : ""}</p>
                                                    <p className={`text-base md:text-lg text-[${textColor}] font-normal text-center`} style={{ color: textColor }}><i class="fas fa-clock  text-sm align-text-bottom mr-1 text-[#5A5A5A]"></i>{seasonSingle.start_time ? convertToAmPm(seasonSingle.start_time) : ''}</p>
                                                </div>
                                            </div>
                                            <div className="flex-none">
                                                <div className="flex gap-1">
                                                    <p className="text-base md:text-lg text-[#5A5A5A]">Qty.</p>
                                                    <p className={`text-base md:text-lg text-[${textColor}] font-normal text-center`} style={{ color: textColor }}>
                                                        {seasonSingle.available_quantity}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-5 mx-6">
                                            {/* Qr Image Display */}
                                            {(seasonSingle && seasonSingle.status !== null && (seasonSingle.status === "Expired" || seasonSingle.status === "Deleted" || seasonSingle.status === 'Scann' || seasonSingle.available_quantity === 0)) ? (
                                                <>
                                                    {seasonSingle.status === "Expired" ? (
                                                        <img src={ExpiredImage} alt="img" className="inline-block" />
                                                    ) : seasonSingle.status === "Deleted" ? (
                                                        <img src={DeletedImage} alt="img" className="inline-block" />
                                                    ) : seasonSingle.status === "Scann" ? (
                                                        <img src={ScannedImage} alt="img" className="inline-block" />
                                                    ) : seasonSingle.available_quantity === 0 ? (
                                                        <img src={SharedImage} alt="img" className="inline-block my-4" />
                                                    ) : passDetails && passDetails.event_pass && passDetails.event_pass.is_rfid == 'Yes' ? (
                                                        <img src={RFIDImage} alt="img" className="inline-block" />
                                                    ) : <img src={seasonSingle.qr_image} alt="img" className="inline-block" />}
                                                </>
                                            ) : passDetails && passDetails.event_pass && passDetails.event_pass.is_rfid == 'Yes' ?
                                                <img src={RFIDImage} alt="img" className="inline-block" />
                                                :
                                                <img src={seasonSingle.qr_image} alt="img" className="inline-block" />
                                            }
                                        </div>
                                        {/* Spit & Share */}
                                        {/* <div className="flex gap-6 items-center justify-center mt-2 mb-6">

                                            {(seasonSingle && seasonSingle.status !== null && (seasonSingle.status === "Expired" || seasonSingle.status === "Deleted" || seasonSingle.status === 'Scann' || seasonSingle.available_quantity === 0)) ? (
                                                ''
                                            ) : passDetails && passDetails.event_pass && passDetails.event_pass.is_rfid == 'Yes' ?
                                                ''
                                                : (
                                                    <>
                                                        {passDetails && passDetails.is_transferable === 'Yes' && (
                                                            <div className="flex-1">
                                                                <button onClick={
                                                                    () => {
                                                                        toggleSplitModal()
                                                                        setUrlString(seasonSingle.url_string)
                                                                    }
                                                                } style={{ backgroundColor: primaryColor }} className={`bg-[${primaryColor}] rounded-[10px] flex items-center justify-center text-base text-white font-body font-normal w-full h-[45px] m-auto`}>
                                                                    <i className="far fa-grin-beam mr-2"
                                                                        style={{ color: secondaryColor }}
                                                                    ></i>
                                                                    Split & Share
                                                                </button>
                                                            </div>
                                                        )}
                                                    </>
                                                )}


                                            <div className="flex-none">
                                                {seasonSingle && !isEmpty(seasonSingle.gmap_link) ? (
                                                    <Link to={passDetails && seasonSingle.gmap_link} target="_blank" className="bg-[#E7E7E7] rounded-full w-[56px] h-[56px] flex items-center justify-center ">
                                                        <img src={map} alt="img" className="inline-block" />
                                                    </Link>
                                                ) :
                                                    <>
                                                        {passDetails && passDetails.event && !isEmpty(passDetails.event.gmap_link) && (
                                                            <Link to={passDetails && passDetails.event && passDetails.event.gmap_link} target="_blank" className="bg-[#E7E7E7] rounded-full w-[56px] h-[56px] flex items-center justify-center ">
                                                                <img src={map} alt="img" className="inline-block" />
                                                            </Link>
                                                        )}
                                                    </>
                                                }
                                            </div>
                                        </div> */}
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="relative">
                                <img src={img4} className="inline-block absolute -left-[16px] md:-left-[20px] -top-[50px] md:-top-[45px]" />
                                <img src={img5} className="inline-block absolute -right-[16px] md:-right-[21px] -top-[50px] md:-top-[45px]" />
                                <div className="mx-6 flex items-center justify-center gap-3">
                                    <button className="splite-share-button rounded-[7px] text-base border-[2px] border-primary text-white font-semibold py-2 px-5">Split & Share</button>
                                    <div className="flex-none">
                                        <Link to="" target="_blank" className="bg-white border border-primary rounded-full w-[50px] h-[50px] flex items-center justify-center ">
                                            <img src={map} alt="img" className="inline-block" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="bg-white border border-[#E7E7E7] rounded-[10px] p-4 mt-4">
                        <div className="flex items-center">
                            <p className="text-base text-black font-normal flex-1">Things to know</p>
                            <i className="fas fa-chevron-right text-black flex-none"></i>
                        </div>
                    </div>
                    <div className="mt-4">
                        <>
                            <PriceDetail passDetails={passDetails}
                                pricingDetails={pricingDetails}
                                textColor={textColor}
                                secondaryColor={secondaryColor} />
                        </>

                    </div>

                    <div className="text-center">
                        {passDetails && passDetails.event_pass && passDetails.event_pass.notes && (
                            <p onClick={
                                () => {
                                    setShowTermsModal(true)
                                }
                            }
                                style={{ color: textColor }} className={`text-base font-body font-medium mt-4 text-[${textColor}] cursor-pointer`}>
                                Terms & Condition</p>
                        )}
                    </div>

                </div>

                {!isEmpty(sponsorList) && (
                    <SponserList sponsorList={sponsorList} textColor={textColor} />
                )}
                <div className="border-b-2 border-dashed border-[#EE3E45] my-3 mx-4 relative">
                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                </div>
                <div className="p-[15px] pt-0">
                    <h2 className={`text-2xl text-[${textColor}] font-body font-semibold text-center`} style={{ color: textColor }}>Contact Support</h2>
                    <div className="flex items-center gap-2 mt-3">
                        <button className="bg-white text-xs md:text-sm text-[#5A5A5A] border border-[#E7E7E7] rounded-[10px] py-2 md:py-3 px-3 md:px-4 w-full text-nowrap"><i class="fab fa-whatsapp text-black text-xl mr-1 md:mr-2 align-middle"></i> WhatsApp us</button>
                        <button className="bg-white text-[#5A5A5A] border border-[#E7E7E7] rounded-[10px] text-xs md:text-sm py-2 md:py-3 px-3 md:px-4 w-full text-nowrap"><i class="fas fa-envelope text-black text-xl mr-1 md:mr-2 align-middle"></i> support@mepass.in</button>
                    </div>
                </div>
                <div className="border-b-2 border-dashed border-[#EE3E45] my-3 mx-4 relative">
                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                </div>
                <div className="px-[15px] pb-[20px]">
                    <p className="text-lg font-normal text-[#1A1A1A] text-center">Download our App</p>
                    <div className="grid grid-cols-2 gap-5 mt-4">
                        <img src={img} alt="img" className="inline-block w-full" />
                        <img src={img1} alt="img" className="inline-block w-full" />
                    </div>

                </div>
            </div>

        </>

    )
}
DandiyaMepass.propTypes = {

}
export default DandiyaMepass
