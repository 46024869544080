import React, { useEffect } from "react";
import banner3 from "../../assets/images/terms-condition-banner.png";
import { Link } from "react-router-dom";
import SeoDetails from "../../seo/SeoDetails";
function RefundCancellationPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SeoDetails
        title="mepass | Refund and Cancellation Policy"
        description="mepass | Refund and Cancellation Policy"
      />

      <section className="bg-white pt-14 pb-14">
        <div className="container">
          <h2 className="text-2xl md:text-4xl text-[#101010] font-semibold text-center mb-8">
            Refund and Cancellation Policy
          </h2>
          <h3 className="text-2xl md:text-3xl text-[#101010] font-semibold">
            User Refund and Cancellation Policy
          </h3>
          <div>
            <ol className="list-decimal list-inside	 mt-4">
              <li className="text-lg md:text-xl text-[#101010] font-semibold">
                Service Overview
              </li>
              <p className="text-lg text-[#101010] font-normal mt-2">
                Mepass provides services to our B2B clients, including company
                account registration, event hosting and management, and various
                event-related products and services available for purchase and
                use through our B2B customer portal or account. There are no
                different categories or tiers of services for merchants
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-2">
                Booking Process
              </li>
              <p className="text-lg text-[#101010] font-normal mt-2">
                Mepass provides Registration and Booking Procedure:** Merchants
                can visit our merchant portal or use the merchant application to
                register as an individual or company and join Mepass as a
                merchant. 
              </p>
              <p className="text-lg text-[#101010] font-normal">
                Mepass provides Deposit Requirements:** No deposit is required
                at the time of booking.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-2">
                Refund Conditions
              </li>
              <p className="text-lg text-[#101010] font-normal mt-2">
                Non-Refundable Services: Mepass does not initiate refunds for
                any services offered to merchants. Once a merchant purchases or
                activates a service for their respective purposes, it is
                non-refundable under any circumstances. Time Frames for Refund
                Requests:** No refunds are applicable.
              </p>
              <p className="text-lg text-[#101010] font-normal">
                Time Frames for Refund Requests:** No refunds are applicable.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-2">
                Cancellation Policy
              </li>
              <p className="text-lg text-[#101010] font-normal mt-2">
                Merchant-Initiated Cancellations: Once a merchant registers or
                purchases any services offered, they are not eligible to cancel
                those services or request a refund.
              </p>
              <p className="text-lg text-[#101010] font-normal">
                Merchant-Initiated Penalty or Fees for Cancellations:**
                Cancellations are not allowed, hence no penalties or fees apply.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-2">
                Non-Refundable Conditions
              </li>
              <p className="text-lg text-[#101010] font-normal mt-2">
                Non-Refundable Payments: All payments for services purchased by
                merchants are non-refundable, regardless of the type of service
                or event.
              </p>
              <li className="text-lg  md:text-xl text-[#101010] font-semibold mt-2">
                Force Majeure
              </li>
              <p className="text-lg text-[#101010] font-normal mt-2">
                Unforeseen Circumstances:** In the event of unforeseen
                circumstances such as natural disasters or pandemics, the
                purchased services will remain as a balance in your portal and
                can be redeemed for future service needs.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-2">
                Refund Processing
              </li>
              <p className="text-lg text-[#101010] font-normal mt-2">
                Refund Time Frame:** No refunds are processed.
              </p>
              <p className="text-lg text-[#101010] font-normal">
                Refund Method: No refunds are issued
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-2">
                Communication
              </li>
              <p className="text-lg text-[#101010] font-normal mt-2">
                Refund and Cancellation Requests:** As no refunds or
                cancellations are accepted, merchants should not request refunds
                or cancellations.
              </p>
              <p className="text-lg text-[#101010] font-normal">
                Customer Service Team: As refunds are not provided, there is no
                dedicated customer service team for handling such requests.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-2">
                Contact Information
              </li>
              <p className="text-lg text-[#101010] font-normal mt-2">
                For any queries related to this Merchant Refund and Cancellation
                Policy, please contact our support team at: Memorialeye Event
                Management Private Limited (Mepass)**
              </p>
              <div className="mt-5">
                <div className="flex gap-2 text-lg text-[#101010] font-normal">
                  <div className="flex-none">Email:</div>
                  <div className="flex-1">
                    <Link to="mailto:onboarding@mepass.in">
                      onboarding@mepass.in
                    </Link>
                  </div>
                </div>
                <div className="flex gap-2 text-lg text-[#101010] font-normal">
                  <div className="flex-none">Phone:</div>
                  <div className="flex-1">
                    <Link to="tel:9099060633">9099060633</Link>
                  </div>
                </div>
                <div className="flex gap-2 text-lg text-[#101010] font-normal">
                  <div className="flex-none">Address:</div>
                  <div className="flex-1">
                    A/209 Titanium Business Park, Near Makarba Railway
                    Underbridge, Behind Divya Bhaskar Ahmedabad 380051
                  </div>
                </div>
              </div>
              <p className="text-lg text-[#101010] font-normal">
                This policy ensures clarity and fairness for merchants using
                Mepass services, maintaining trust and transparency in our
                operations. Memorialeye Event Management Private Limited
                reserves the right to amend this policy at any time without
                prior notice. Any changes will be posted on our website and will
                be effective immediately upon posting.
              </p>
            </ol>
          </div>
          <div className="mt-5">
            <h3 className="text-2xl md:text-3xl text-[#101010] font-semibold">
              Merchant Refund and Cancellation Policy
            </h3>
            <h4 className="text-xl md:text-2xl text-[#101010] font-semibold mt-4">
              Effective Date: [Insert Date]
            </h4>
            <p className="text-lg md:text-xl text-[#101010] font-normal mt-2">
              Memorialeye Event Management Private Limited ("we," "us," "our")
              operates under the brand name Mepass. This Merchant Refund and
              Cancellation Policy applies to all merchants ("you," "your") who
              register and use our services through our platform, Mepass, which
              is accessible via our merchant portal or merchant application.
            </p>
            <div>
              <ol className="list-decimal list-inside	 mt-4">
                <li className="text-lg md:text-xl text-[#101010] font-semibold">
                  Service Overview
                </li>
                <p className="text-lg text-[#101010] font-normal mt-2">
                  Mepass is an event ticketing booking platform where users can
                  book tickets or register for a variety of live entertainment
                  events and activities. Our platform does not offer different
                  tiers of services for users.
                </p>
                <li className="text-lg md:text-xl text-[#101010] font-semibold mt-2">
                  Booking Process
                </li>
                <p className="text-lg text-[#101010] font-normal mt-2">
                  Booking Procedure: Users must log in to mepass.in, select an
                  event, review all event details and terms and conditions, and
                  then click the "Book" button. Users can select their preferred
                  pass type and quantity and will be redirected to the payment
                  page to complete the booking using the available payment
                  options.
                </p>
                <p className="text-lg text-[#101010] font-normal">
                  Free Events/Registrations: For free events or events requiring
                  registration, users must follow the specified registration
                  process.
                </p>
                <p className="text-lg text-[#101010] font-normal">
                  Deposit Requirements: Generally, no deposit is required at the
                  time of booking unless specified in the event's terms and
                  conditions. Any such requirements will be clearly mentioned
                  under the event's terms and conditions and refund policy.
                </p>
                <li className="text-lg md:text-xl text-[#101010] font-semibold mt-2">
                  Refund Conditions
                </li>
                <p className="text-lg text-[#101010] font-normal mt-2">
                  Event Cancellation: If an event is cancelled and you purchased
                  the pass through mepass.in using our payment gateway, Mepass
                  will initiate a refund, subject to certain conditions.
                </p>
                <p className="text-lg text-[#101010] font-normal">
                  Third-Party Purchases: If you have a ticket purchased through
                  a third party (e.g., event organiser team members), Mepass is
                  not liable for refunds.
                </p>
                <p className="text-lg text-[#101010] font-normal">
                  Individual Cancellation Requests:** For cancellations
                  initiated by users due to personal reasons, Mepass is not
                  liable for refunds. Users must contact the event organiser
                  directly for such requests.
                </p>
                <p className="text-lg text-[#101010] font-normal">
                  Refund Requests:** After booking tickets for any event, users
                  cannot request refunds or cancellations through Mepass. Refund
                  requests must be directed to the event organiser and their
                  team.
                </p>
                <p className="text-lg text-[#101010] font-normal">
                  Event Postponement:** If an event is cancelled or delayed,
                  Mepass will initiate a refund process for tickets purchased
                  through mepass.in.
                </p>
                <li className="text-lg md:text-xl text-[#101010] font-semibold mt-2">
                  Cancellation Policy
                </li>
                <p className="text-lg text-[#101010] font-normal mt-2">
                  User-Initiated Cancellations:** Once a ticket is booked, users
                  cannot cancel their booking through Mepass. Cancellation
                  requests must be directed to the event organizer or management
                  team.
                </p>
                <p className="text-lg text-[#101010] font-normal">
                  Convenience and Transaction Charges: In the event of an event
                  cancellation or delay, the decision on whether the convenience
                  charge and transaction charges are covered by the organizer or
                  deducted from the user's refund will depend on the organizer.
                  If the charges are to be borne by the user, the refund amount
                  will be processed after deducting these charges.
                </p>
                <li className="text-lg md:text-xl text-[#101010] font-semibold mt-2">
                  Non-Refundable Conditions
                </li>
                <p className="text-lg text-[#101010] font-normal mt-2">
                  Payments are non-refundable if the cancellation request is due
                  to individual reasons and not related to event cancellation or
                  delay.
                </p>
                <li className="text-lg  md:text-xl text-[#101010] font-semibold mt-2">
                  Force Majeure
                </li>
                <p className="text-lg text-[#101010] font-normal mt-2">
                  In cases of unforeseen circumstances such as natural disasters
                  or pandemics that lead to event cancellation, Mepass will
                  process refunds for tickets purchased through our platform.
                </p>
                <li className="text-lg md:text-xl text-[#101010] font-semibold mt-2">
                  Refund Processing
                </li>
                <p className="text-lg text-[#101010] font-normal mt-2">
                  Processing Time: Refunds will be processed within 15 days
                  after receiving confirmation from the management and organizer
                  team regarding the event cancellation.
                </p>
                <p className="text-lg text-[#101010] font-normal">
                  Refund Method: Refunds will be credited back to the original
                  payment source.
                </p>
                <li className="text-lg md:text-xl text-[#101010] font-semibold mt-2">
                  Communication
                </li>
                <p className="text-lg text-[#101010] font-normal mt-2">
                  Refund Requests: For event cancellations, users can contact
                  our support and helpline to request a refund. Individual
                  reasons for refund or cancellation requests will not be
                  accepted.
                </p>
                <p className="text-lg text-[#101010] font-normal">
                  Customer Service: Mepass has a dedicated customer service team
                  to handle refund and cancellation requests related to event
                  cancellations.
                </p>
                <li className="text-lg md:text-xl text-[#101010] font-semibold mt-2">
                  Contact Information
                </li>
                <p className="text-lg text-[#101010] font-normal mt-2">
                  For any queries related to this Refund and Cancellation
                  Policy, please contact our customer service team at:
                  Memorialeye Event Management Private Limited (Mepass)
                </p>
                <div className="mt-5">
                  <div className="flex gap-2 text-lg text-[#101010] font-normal">
                    <div className="flex-none">Email:</div>
                    <div className="flex-1">
                      <Link to="mailto:support@mepass.in">
                        support@mepass.in
                      </Link>
                    </div>
                  </div>
                  <div className="flex gap-2 text-lg text-[#101010] font-normal">
                    <div className="flex-none">Phone:</div>
                    <div className="flex-1 break-all">
                      <Link className="ml-1" to="tel:+9925060619">
                        9925060619
                      </Link>
                      {/* <span className="mx-1">|</span>
                      <Link className="mx-1" to="tel:+917228930704">
                        7228930704
                      </Link>
                      <span className="mx-1">|</span>
                      <Link to="tel:+917228930705">7228930705</Link>
                      <span className="mx-1">|</span>
                      <Link to="tel:+917228930706">7228930706</Link>
                      <span className="mx-1">|</span>
                      <Link to="tel:+917228930707">7228930707</Link>
                      <span className="mx-1">|</span>
                      <Link to="tel:+917228930708">7228930708</Link>
                      <span className="mx-1">|</span>
                      <Link to="tel:+917228930709">7228930709</Link> */}
                    </div>
                  </div>
                </div>
                <p className="text-lg text-[#101010] font-normal">
                  This policy is designed to ensure clarity and fairness for
                  users booking through Mepass, maintaining trust and
                  transparency in our services. Memorialeye Event Management
                  Private Limited reserves the right to amend this policy at any
                  time without prior notice. Any changes will be posted on our
                  website and will be effective immediately upon posting
                </p>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RefundCancellationPolicy;
