import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "../../components/Common/withRouter";
import api from "../../constants/api";
import { clearData, getData } from "../../store/AppMaster/actions";
import Artist from "../Home/Artist";
import Banner from "../Home/Banner";
import Event from "../Home/Event";
import SeoDetails from "../../seo/SeoDetails";
import SkeletonLoaderBanner from "../../components/SkeletonLoader/Home/Banner";
import SkeletonLoaderEvent from "../../components/SkeletonLoader/Home/Event";
import SkeletonLoaderArtist from "../../components/SkeletonLoader/Home/Artist";
import { messaging, getToken } from "../../firebase";
import AppDetails from "../Home/AppDetails"

const Home = (props) => {
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));
  const section_titles = ["featured", "exclusive_offers", "events"];
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(0);
  const [tradingData, setTradingData] = useState([]);
  const [featuredData, setFeaturedData] = useState([]);
  const [exclusiveOfferData, setExclusiveOfferData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [artistData, setArtistData] = useState([]);
  const [isTradingDataGet, setIsTradingDataGet] = useState(true);
  const [isFeaturedDataGet, setIsFeaturedDataGet] = useState(true);
  const [isExclusiveOfferDataGet, setIsExclusiveOfferDataGet] = useState(true);
  const [isEventDataGet, setIsEventDataGet] = useState(true);
  const [isArtistDataGet, setIsArtistDataGet] = useState(true);
  const [fcmToken, setFcmToken] = useState("");
  const [isFcmTokenUpdate, setIsFcmTokenUpdate] = useState(false);

  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        });
        console.log("FCM Token:", token);
        setFcmToken(token);
        // Save the token to your backend or use it as needed
      } else {
        console.log("Notification permission denied");
      }
    } catch (error) {
      console.error("Error getting notification token:", error);
    }
  };

  // Call this function, e.g., on component mount
  useEffect(() => {
    const userDetail = JSON.parse(
      localStorage.getItem("publicMePassUserDetails")
    );
    if (userDetail && userDetail.id && !isFcmTokenUpdate) {
      requestNotificationPermission();
    }
  }, []);

  const [location, setLocation] = useState({
    city: "",
    state: "",
    country: "",
    latitude: "",
    longitude: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        // Fetch location data from a free API
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        if (data.city && data.country_name) {
          setLocation({
            city: data.city ? data.city : "",
            state: data.region ? data.region : "",
            country: data.country_name ? data.country_name : "",
            latitude: data.latitude ? data.latitude : "",
            longitude: data.longitude ? data.longitude : "",
          });
        } else {
          setError("Could not retrieve location.");
        }
      } catch (err) {
        setError("Failed to fetch location.");
      }
    };

    fetchLocation();
  }, []);

  if (fcmToken && location && location.city && !isFcmTokenUpdate) {
    const userDetail = JSON.parse(
      localStorage.getItem("publicMePassUserDetails")
    );
    // if (userDetail && userDetail.id) {
    //     let requestData = {
    //         user_id: userDetail && userDetail.id ? userDetail.id : '',
    //         push_notification_id: fcmToken,
    //         device_type: "web",
    //         city: location && location.city ? location.city : "",
    //         state: location && location.state ? location.state : "",
    //         country: location && location.country ? location.country : "",
    //         latitude: location && location.latitude ? location.latitude : "",
    //         longitude: location && location.longitude ? location.longitude : "",
    //     }
    //     dispatch(clearData());
    //     dispatch(getData(requestData, props.router.navigate, api.UpdateFcmToken));
    //     setIsFcmTokenUpdate(true);
    // }
  }

  const tradingDataGet = useCallback(() => {
    setTradingData([]);
    setIsTradingDataGet(true);
    let requestData = {
      section_titles: "trending",
    };
    if (props.router.params.city_name) {
      requestData.city = props.router.params.city_name;
    }
    if (props.router.params.category_name) {
      requestData.category = props.router.params.category_name;
    }
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.CmsPage));
  }, [
    dispatch,
    props.router.navigate,
    props.router.params.city_name,
    props.router.params.category_name,
  ]);

  const featuredDataGet = useCallback(() => {
    setFeaturedData([]);
    setIsFeaturedDataGet(true);
    let requestData = {
      section_titles: "feature",
    };
    if (props.router.params.city_name) {
      requestData.city = props.router.params.city_name;
    }
    if (props.router.params.category_name) {
      requestData.category = props.router.params.category_name;
    }
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.CmsPage));
  }, [
    dispatch,
    props.router.navigate,
    props.router.params.city_name,
    props.router.params.category_name,
  ]);

  const exclusiveOfferDataGet = useCallback(() => {
    setExclusiveOfferData([]);
    setIsExclusiveOfferDataGet(true);
    let requestData = {
      section_titles: "exclusive_offers",
    };
    if (props.router.params.city_name) {
      requestData.city = props.router.params.city_name;
    }
    if (props.router.params.category_name) {
      requestData.category = props.router.params.category_name;
    }
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.CmsPage));
  }, [
    dispatch,
    props.router.navigate,
    props.router.params.city_name,
    props.router.params.category_name,
  ]);

  const eventDataGet = useCallback(() => {
    setEventData([]);
    setIsEventDataGet(true);
    let requestData = {
      section_titles: "events",
    };
    if (props.router.params.city_name) {
      requestData.city = props.router.params.city_name;
    }
    if (props.router.params.category_name) {
      requestData.category = props.router.params.category_name;
    }
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.CmsPage));
  }, [
    dispatch,
    props.router.navigate,
    props.router.params.city_name,
    props.router.params.category_name,
  ]);

  const artistDataGet = useCallback(() => {
    setArtistData([]);
    setIsArtistDataGet(true);
    const userDetail = JSON.parse(
      localStorage.getItem("publicMePassUserDetails")
    );
    let requestData = {
      section_titles: "artists",
      user_id: userDetail && userDetail.id ? userDetail.id : "",
    };
    if (props.router.params.city_name) {
      requestData.city = props.router.params.city_name;
    }
    if (props.router.params.category_name) {
      requestData.category = props.router.params.category_name;
    }
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.CmsPage));
  }, [
    dispatch,
    props.router.navigate,
    props.router.params.city_name,
    props.router.params.category_name,
  ]);

  useEffect(() => {
    tradingDataGet();
    featuredDataGet();
    exclusiveOfferDataGet();
    eventDataGet();
    artistDataGet();
  }, [
    tradingDataGet,
    featuredDataGet,
    exclusiveOfferDataGet,
    eventDataGet,
    artistDataGet,
  ]);

  useEffect(() => {
    if (
      !isEmpty(data) &&
      !isEmpty(data.cmsPage) &&
      !isEmpty(data.cmsPage.trending) &&
      isTradingDataGet
    ) {
      setTradingData(data.cmsPage.trending);
      setIsTradingDataGet(false);
    }
    if (
      !isEmpty(data) &&
      !isEmpty(data.cmsPage) &&
      !isEmpty(data.cmsPage.events) &&
      isEventDataGet
    ) {
      setEventData(data.cmsPage.events);
      setIsEventDataGet(false);
    }
    if (
      !isEmpty(data) &&
      !isEmpty(data.cmsPage) &&
      !isEmpty(data.cmsPage.exclusive_offers) &&
      isExclusiveOfferDataGet
    ) {
      setExclusiveOfferData(data.cmsPage.exclusive_offers);
      setIsExclusiveOfferDataGet(false);
    }
    if (
      !isEmpty(data) &&
      !isEmpty(data.cmsPage) &&
      !isEmpty(data.cmsPage.feature) &&
      isFeaturedDataGet
    ) {
      setFeaturedData(data.cmsPage.feature);
      setIsFeaturedDataGet(false);
    }
    if (
      !isEmpty(data) &&
      !isEmpty(data.cmsPage) &&
      !isEmpty(data.cmsPage.artists) &&
      isArtistDataGet
    ) {
      setArtistData(data.cmsPage.artists);
      setIsArtistDataGet(false);
    }
  }, [
    data,
    isTradingDataGet,
    isEventDataGet,
    isFeaturedDataGet,
    isExclusiveOfferDataGet,
    isArtistDataGet,
  ]);

  //FCM Token Update
  return (
    <React.Fragment>
      <SeoDetails
        title={data.cmsPage?.seo_title}
        description={data.cmsPage?.seo_description}
        ogImage={data.cmsPage?.seo_image}
      />
      <Banner events={tradingData} props={props} />
      <section className="py-16">
        <div className="container">
          {section_titles.map((section_title, index) => (
            <div key={index}>
              {section_title === "featured" ? (
                <>
                  <Event
                    events={featuredData}
                    props={props}
                    section_title="Popular Events Near You"
                  />
                </>
              ) : (
                ""
              )}
              {section_title === "exclusive_offers" ? (
                <>
                  <Event
                    events={exclusiveOfferData}
                    props={props}
                    section_title="Events With Exclusive Offers"
                  />
                </>
              ) : (
                ""
              )}
              {section_title === "events" ? (
                <>
                  <Event
                    events={eventData}
                    props={props}
                    section_title="Upcoming Events Near You"
                  />
                </>
              ) : (
                ""
              )}
              {/* {section_title === "events" ? (
                  <>
                    <Event
                      events={eventData}
                      props={props}
                      section_title="Upcoming Exhibitions Near You"
                    />
                  </>
                ) : (
                  ""
                )} */}
            </div>
          ))}

          {<Artist artists={artistData} props={props} pageTitle="Home" />}
          <AppDetails />
        </div>
      </section>
    </React.Fragment>
  );
};

export default withRouter(Home);
