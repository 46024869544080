import { isEmpty } from "lodash";
import React from "react";
import { convertToAmPm } from "../../../common/commonFunction";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Link } from "react-router-dom";
import { Pagination } from "swiper/modules";
import img from "../../../assets/images/App-Store.png";
import img1 from "../../../assets/images/google-play.png";
import img4 from "../../../assets/images/small/dandiya-left-icon.svg";
import img5 from "../../../assets/images/small/dandiya-right-icon.svg";
import img2 from "../../../assets/images/small/pass-left-frame.svg";
import img3 from "../../../assets/images/small/pass-right-frame.svg";
import img6 from "../../../assets/images/google-play-light.svg";
import img7 from "../../../assets/images/App-Store-light.svg";
import PriceDetail from "./PriceDetail";
import SponserList from "../SponserList";
const SeasonMePass = ({
  seasonArray,
  passDetails,
  textColor,
  primaryColor,
  secondaryColor,
  backGroundColor,
  RFIDImage,
  ExpiredImage,
  DeletedImage,
  ScannedImage,
  SharedImage,
  map,
  toggleSplitModal,
  setShowTermsModal,
  pricingDetails,
  sponsorList,
  setUrlString,
  setPassAvailableQuantity,
}) => {
  return (
    <>
      <div
        className={`rounded-[10px] border-[3px] border-[#FFD685]`}
        style={{ backgroundColor: backGroundColor }}
      >
        <div className="grid grid-cols-3 gap-6 p-[15px]">
          <div>
            <img
              src={
                passDetails &&
                passDetails.event_pass &&
                passDetails.event_pass.image
                  ? passDetails.event_pass.image
                  : passDetails &&
                    passDetails.event &&
                    passDetails.event.banner_image
              }
              alt="img"
              className="inline-block aspect-[9/16] object-cover rounded-lg"
            />
          </div>
          <div className="col-span-2">
            <div className="grid grid-flow-row-dense grid-row-5 h-full content-stretch	">
              <div className="row-span-4">
                <h2
                  style={{ color: textColor }}
                  className={`text-lg text-[${textColor}] font-body font-semibold capitalize mb-2 `}
                >
                  {passDetails && passDetails.event && passDetails.event.name}
                </h2>
              </div>
              <div className="row-span-1">
                <h2
                  className={`text-lg font-body font-semibold capitalize`}
                  style={{ color: primaryColor }}
                >
                  {passDetails &&
                    passDetails.event_pass &&
                    passDetails.event_pass.name &&
                    passDetails.event_pass.name}
                </h2>
                <div className="flex gap-2 mt-2">
                  <div className="flex-1">
                    {passDetails && passDetails.comments && (
                      <h2
                        className={`text-base font-body font-normal`}
                        style={{ color: textColor }}
                      >
                        {passDetails && passDetails.comments}
                      </h2>
                    )}
                  </div>
                  <div className="flex-none">
                    {passDetails &&
                      passDetails.event &&
                      passDetails.event.slug && (
                        <Link
                          to={`/events/${passDetails.event.slug}/book-tickets`}
                          target="_blank"
                          className="text-[#5A5A5A] text-sm font-normal underline"
                        >
                          To Purchase More
                        </Link>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="border-b-2 border-dashed my-3 mx-4 relative"
          style={{ borderColor: primaryColor }}
        >
          <span
            className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}
          ></span>
          <span
            className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}
          ></span>
        </div>
        <div className="relative">
          <img
            src={img2}
            alt="img"
            className="inline-block absolute left-0 -top-[13px]"
          />
          <img
            src={img3}
            alt="img"
            className="inline-block absolute right-0 -top-[13px]"
          />
        </div>

        <div className="p-[15px]">
          {/* <div className="flex items-center justify-between gap-4 max-w-[85%] m-auto">
                        <h2 className={`text-lg text-[${textColor}] font-body font-semibold`} style={{ color: secondaryColor }}> {passDetails && passDetails.event_pass && passDetails.event_pass.name}</h2>

                    </div> */}
          {seasonArray.length > 0 && (
            <div
              className="border border-secondary rounded-lg p-6 mt-8 relative"
              style={{ backgroundColor: backGroundColor }}
            >
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className={`mySwiper season-pass ${
                  backGroundColor === "#000000"
                    ? "season-pass-white"
                    : "season-pass-black"
                }`}
              >
                {seasonArray.map((seasonSingle, indexSeason) => (
                  <SwiperSlide key={indexSeason}>
                    <div className="flex items-center justify-between gap-4 mb-4 mx-2">
                      <p
                        className={`text-lg text-[${textColor}] font-normal text-center`}
                        style={{ color: textColor }}
                      >
                        {seasonSingle.date ? seasonSingle.date : ""} |{" "}
                        {seasonSingle.start_time
                          ? convertToAmPm(seasonSingle.start_time)
                          : ""}
                      </p>
                      <div
                        className={`text-lg text-[${textColor}] font-normal text-center`}
                        style={{ color: textColor }}
                      >
                        {seasonSingle.available_quantity}
                      </div>
                    </div>
                    <div className="mb-5 max-w-[80%] m-auto">
                      {/* Qr Image Display */}
                      {seasonSingle &&
                      seasonSingle.status !== null &&
                      (seasonSingle.status === "Expired" ||
                        seasonSingle.status === "Deleted" ||
                        seasonSingle.status === "Scann" ||
                        seasonSingle.available_quantity === 0) ? (
                        <>
                          {seasonSingle.status === "Expired" ? (
                            <img
                              src={ExpiredImage}
                              alt="img"
                              className="inline-block"
                            />
                          ) : seasonSingle.status === "Deleted" ? (
                            <img
                              src={DeletedImage}
                              alt="img"
                              className="inline-block"
                            />
                          ) : seasonSingle.status === "Scann" ? (
                            <img
                              src={ScannedImage}
                              alt="img"
                              className="inline-block"
                            />
                          ) : seasonSingle.available_quantity === 0 ? (
                            <img
                              src={SharedImage}
                              alt="img"
                              className="inline-block"
                            />
                          ) : passDetails &&
                            passDetails.event_pass &&
                            passDetails.event_pass.is_rfid == "Yes" ? (
                            <img
                              src={RFIDImage}
                              alt="img"
                              className="inline-block"
                            />
                          ) : (
                            <img
                              src={seasonSingle.qr_image}
                              alt="img"
                              className="inline-block"
                            />
                          )}
                        </>
                      ) : passDetails &&
                        passDetails.event_pass &&
                        passDetails.event_pass.is_rfid == "Yes" ? (
                        <img
                          src={RFIDImage}
                          alt="img"
                          className="inline-block"
                        />
                      ) : (
                        <img
                          src={seasonSingle.qr_image}
                          alt="img"
                          className="inline-block"
                        />
                      )}
                    </div>
                    {/* Spit & Share */}
                    <div>
                      <div className="flex gap-2 md:gap-4 items-center justify-center mt-2 mb-7">
                        {seasonSingle &&
                        seasonSingle.status !== null &&
                        (seasonSingle.status === "Expired" ||
                          seasonSingle.status === "Deleted" ||
                          seasonSingle.status === "Scann" ||
                          seasonSingle.available_quantity === 0) ? (
                          ""
                        ) : passDetails &&
                          passDetails.event_pass &&
                          passDetails.event_pass.is_rfid == "Yes" ? (
                          ""
                        ) : (
                          <>
                            {passDetails &&
                              passDetails.is_transferable === "Yes" && (
                                <div className="flex-none">
                                  <button
                                    onClick={() => {
                                      toggleSplitModal();
                                      setUrlString(seasonSingle.url_string);
                                      setPassAvailableQuantity(
                                        seasonSingle.available_quantity
                                      );
                                    }}
                                    style={{ backgroundColor: primaryColor }}
                                    className={`splite-share-button rounded-[7px] text-base border-[2px] border-primary text-white font-semibold py-1 md:py-2 px-3 md:px-5`}
                                  >
                                    Split & Share
                                  </button>
                                </div>
                              )}
                          </>
                        )}

                        <div className="flex-none">
                          {seasonSingle && !isEmpty(seasonSingle.gmap_link) ? (
                            <Link
                              to={passDetails && seasonSingle.gmap_link}
                              target="_blank"
                              className="bg-white border border-primary rounded-full w-[45px] h-[45px] md:w-[53px] md:h-[53px] flex items-center justify-center "
                            >
                              <img
                                src={map}
                                alt="img"
                                className="inline-block"
                              />
                            </Link>
                          ) : (
                            <>
                              {passDetails &&
                                passDetails.event &&
                                !isEmpty(passDetails.event.gmap_link) && (
                                  <Link
                                    to={
                                      passDetails &&
                                      passDetails.event &&
                                      passDetails.event.gmap_link
                                    }
                                    target="_blank"
                                    className="bg-[#E7E7E7] rounded-full w-[56px] h-[56px] flex items-center justify-center "
                                  >
                                    <img
                                      src={map}
                                      alt="img"
                                      className="inline-block"
                                    />
                                  </Link>
                                )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <img
                src={img4}
                className="inline-block absolute left-[5px] bottom-[26px] z-0"
              />
              <img
                src={img5}
                className="inline-block absolute right-[5px] bottom-[26px] z-10"
              />
            </div>
          )}

          <div className="text-center">
            {passDetails &&
              passDetails.event_pass &&
              passDetails.event_pass.notes && (
                <p
                  onClick={() => {
                    setShowTermsModal(true);
                  }}
                  style={{ color: textColor }}
                  className={`text-base font-body font-medium mt-4 text-[${textColor}] cursor-pointer`}
                >
                  Highlight Notes
                </p>
              )}
          </div>
        </div>

        <div className="border-b-2 border-dashed border-[#f4f4f4] my-3 mx-4 relative">
          <span
            className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border border-[#f4f4f4] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[25px] before:h-[21px] before:rounded-full before:border-[#f4f4f4] box-border flex items-center p-1`}
          ></span>
          <span
            className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border border-[#f4f4f4] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[25px] before:h-[21px] before:rounded-full before:border-[#f4f4f4] box-border flex items-center p-1`}
          ></span>
        </div>
        <div className="p-[15px]">
          <>
            <PriceDetail
              passDetails={passDetails}
              pricingDetails={pricingDetails}
              textColor={textColor}
              secondaryColor={secondaryColor}
            />
          </>
        </div>
        <div
          className="border-b-2 border-dashed  my-3 mx-4 relative"
          style={{ borderColor: primaryColor }}
        >
          <span
            className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}
          ></span>
          <span
            className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}
          ></span>
        </div>
        <div className="p-[15px] pt-0">
          <h2
            className={`text-2xl text-[${textColor}] font-body font-semibold text-center`}
            style={{ color: textColor }}
          >
            Contact Support
          </h2>
          <div className="flex items-center gap-2 mt-3">
            <Link
              to={`https://wa.me/${passDetails.contact_mobile_number}`}
              target="_blank"
              className="bg-white text-xs md:text-sm text-[#5A5A5A] border border-[#E7E7E7] rounded-[10px] py-2 md:py-3 px-3 md:px-4 w-full text-nowrap"
            >
              <i class="fab fa-whatsapp text-black text-xl mr-1 md:mr-2 align-middle"></i>
              WhatsApp us
            </Link>
            <Link
              to={`mailto:${passDetails.contact_email}`}
              target="_blank"
              className="bg-white text-[#5A5A5A] border border-[#E7E7E7] rounded-[10px] text-xs md:text-sm py-2 md:py-3 px-3 md:px-4 w-full text-nowrap"
            >
              <i class="fas fa-envelope text-black text-xl mr-1 md:mr-2 align-middle"></i>
              support@mepass.in
            </Link>
          </div>
        </div>
        {!isEmpty(sponsorList) && (
          <SponserList sponsorList={sponsorList} textColor={textColor} />
        )}
        <div
          className="border-b-2 border-dashed  my-3 mx-4 relative"
          style={{ borderColor: primaryColor }}
        >
          <span
            className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}
          ></span>
          <span
            className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}
          ></span>
        </div>
        <div className="px-[15px] pb-[20px]">
          <p
            className={`text-lg font-normal text-[${textColor}] text-center`}
            style={{ color: textColor }}
          >
            Download our App
          </p>
          <div className="grid grid-cols-2 gap-5 mt-4">
            {process.env.REACT_APP_PLAY_STORE_URL ? (
              <Link to={process.env.REACT_APP_PLAY_STORE_URL} target="_blank">
                <img src={img6} alt="img" className="inline-block w-full" />
              </Link>
            ) : (
              <img src={img6} alt="img" className="inline-block w-full" />
            )}
            {process.env.REACT_APP_APP_STORE_URL ? (
              <Link to={process.env.REACT_APP_APP_STORE_URL} target="_blank">
                <img src={img7} alt="img" className="inline-block w-full" />
              </Link>
            ) : (
              <img src={img7} alt="img" className="inline-block w-full" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
SeasonMePass.propTypes = {};
export default SeasonMePass;
