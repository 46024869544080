const regexConstant = {
  MOBILE: /[^0-9]/gi,
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/gi,
  NUMBER: /[^0-9]/gi,
  PRICE: /[^0-9.]/gi,
  NAME: /[^a-zA-Z0-9_ - ]/gi,
  NAME_MIN: 3,
  NAME_MAX: 50,
  SHORT_DESC_MIN: 10,
  SHORT_DESC_MAX: 1500,
}
export default regexConstant
