import React, { useEffect } from "react";
import banner3 from "../../assets/images/privacy-policy-banner.png";
import SeoDetails from "../../seo/SeoDetails";
function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SeoDetails
        title="mepass | Privacy Policy"
        description="mepass | Privacy Policy"
      />
      <section className="bg-white pt-14 pb-14">
        <div className="container">
          <h2 className="text-2xl md:text-4xl text-[#101010] font-semibold text-center mb-8">
            Privacy Policy
          </h2>
          <h3 className="text-2xl md:text-3xl text-[#101010] font-semibold">
            User Terms & Conditions
          </h3>
          <p className="text-lg md:text-xl text-[#101010] font-normal mt-2">
            Mepass ("Event Company," "we," "our," or "us") values your privacy
            and is committed to safeguarding your personal information. This
            Privacy Policy outlines how we collect, use, and protect your
            information when you access our website and related services
            ("Services"). By using our Services, you agree to the terms of this
            Privacy Policy. If you do not agree, please discontinue use of our
            Services.
          </p>
          <div>
            <ol className="list-decimal list-inside	 mt-4">
              <li className="text-lg md:text-xl text-[#101010] font-semibold">
                Account Creation and Use
              </li>
              <div className="pl-5">
                <h3 className="text-lg md:text-xl text-[#101010] font-semibold mt-2">
                  Personal Information
                </h3>
                <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                  This includes any information that can identify you, such as
                  your name, email address, phone number, and payment details.
                  We collect only the essential Personal Information needed to
                  deliver and improve our Services.
                </p>
                <h3 className="text-lg md:text-xl text-[#101010] font-semibold mt-3">
                  Non-Personal Information
                </h3>
                <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                  We also collect non-personal data, such as usage statistics,
                  which cannot identify you individually. Personal Information
                  may be anonymized to create non-personal data, allowing us to
                  gain insights without revealing your identity.
                </p>
              </div>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-3">
                Use of Your Information
              </li>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                We use your information for the following purposes:
              </p>
              <ul className="list-disc pl-10 md:pl-[60px] mt-2">
                <li className="text-lg text-[#101010] font-normal">
                  To facilitate event scheduling and networking for participants
                </li>
                <li className="text-lg text-[#101010] font-normal">
                  To process transactions, fulfill your requests, and manage
                  your account
                </li>
                <li className="text-lg text-[#101010] font-normal">
                  To send important notifications, invoices, and promotional
                  messages
                </li>
                <li className="text-lg text-[#101010] font-normal">
                  To keep you informed about updates, news, and offers related
                  to our Services
                </li>
              </ul>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                We may process Personal Information as permitted or required by
                law, and all Mepass employees are obligated to maintain the
                confidentiality of your data.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-3">
                Legal Basis for Processing
              </li>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                In compliance with the General Data Protection Regulation
                (GDPR), we process Personal Information based on the following
                legal grounds:
              </p>
              <ul className="list-disc pl-10 md:pl-[60px] mt-2">
                <li className="text-lg text-[#101010] font-semibold">
                  Consent{" "}
                  <span className="font-normal ml-1">
                    (GDPR Art. 6(1)(a)): When you give explicit consent for
                    processing your data for specific purposes.
                  </span>
                </li>
                <li className="text-lg text-[#101010] font-semibold">
                  Contractual Necessity{" "}
                  <span className="font-normal ml-1">
                    {" "}
                    (GDPR Art. 6(1)(b)): To fulfill contractual obligations,
                    such as providing our Services.
                  </span>
                </li>
                <li className="text-lg text-[#101010] font-semibold">
                  Legal Obligations{" "}
                  <span className="font-normal ml-1">
                    {" "}
                    (GDPR Art. 6(1)(c)): To meet legal requirements, including
                    tax obligations.
                  </span>
                </li>
                <li className="text-lg text-[#101010] font-semibold">
                  Vital Interests{" "}
                  <span className="font-normal ml-1">
                    {" "}
                    (GDPR Art. 6(1)(d)): To protect your or another person’s
                    vital interests in exceptional cases.
                  </span>
                </li>
                <li className="text-lg text-[#101010] font-semibold">
                  Legitimate Interests{" "}
                  <span className="font-normal ml-1">
                    {" "}
                    (GDPR Art. 6(1)(f)): To pursue our legitimate business
                    interests, such as marketing, provided these interests do
                    not override your privacy rights.
                  </span>
                </li>
              </ul>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-3">
                Data Collection and Retention
              </li>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                We collect data when you interact with our Services, including
                when you sign up for our newsletter. Mepass retains Personal
                Information only as long as necessary to fulfil the purposes
                outlined in this Privacy Policy or as required by law. Once the
                data is no longer needed, we will securely delete or anonymize
                it.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-3">
                Cookie Policy and Tracking Technologies
              </li>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                Mepass uses cookies and similar tracking technologies to enhance
                your experience, analyse usage patterns, and improve our
                Services. You can manage your cookie preferences through your
                browser settings. For more information, please review our
                [Cookie Policy].
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-3">
                International Data Transfers
              </li>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                We may transfer your Personal Information to other countries,
                including those outside the European Economic Area (EEA). When
                doing so, we ensure that appropriate safeguards, such as
                Standard Contractual Clauses, are in place to protect your data
                in compliance with international data protection standards.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-3">
                Third-Party Sharing and Hosting
              </li>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                Mepass does not share your Personal Information for direct
                marketing without your consent. We may engage trusted
                third-party service providers to help operate and enhance our
                Services (e.g., payment processors, analytics providers). These
                partners only access Personal Information as necessary to
                perform their functions and are bound by confidentiality
                obligations.
              </p>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-2.5">
                In specific circumstances, such as mergers, acquisitions, or
                legal obligations, we may share Personal Information to comply
                with legal requirements or execute our Terms of Service. We may
                also share aggregated or anonymized data with business partners,
                advertisers, or for other lawful purposes.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-3">
                Data Security
              </li>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                We use industry-standard technical and organisational measures
                to protect your information from unauthorised access or
                disclosure. Only authorised personnel and partners have access,
                and all access is password-protected. While we strive to secure
                your data, please note that absolute security on our servers
                cannot be guaranteed.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-3">
                Your Rights and Contact Information
              </li>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                <span className="font-semibold">Access and Correction:</span>{" "}
                You have the right to access and correct your Personal
                Information. Contact us if you need to update or delete
                inaccurate or outdated information.
              </p>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-2.5">
                <span className="font-semibold">
                  Right to Erasure and Restriction:
                </span>{" "}
                You may request deletion of your data from our records. We will
                comply unless a legitimate reason requires us to retain it.
                While we will make every effort to completely delete your data,
                complete removal from all systems may take time due to backup
                procedures.
              </p>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-2.5">
                <span className="font-semibold">Data Portability:</span> You
                have the right to receive a copy of your Personal Information in
                a structured, commonly used format.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-3">
                Children's Privacy
              </li>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                Our Services are not intended for children under the age of 16.
                We do not knowingly collect or process information from
                children. If we discover that we have inadvertently collected
                data from a minor, we will promptly delete it. Please contact us
                if you believe we may have gathered information from a child.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-3">
                Data Breach Notification
              </li>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                In the unlikely event of a data breach that may affect your
                rights or freedoms, we will promptly notify you and relevant
                authorities as required by data protection laws. Our
                notification will include the nature of the breach, potential
                consequences, and the measures we are taking to address it.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-3">
                Third-Party Links
              </li>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                Our Services may contain links to third-party websites or
                services that are not governed by this Privacy Policy. We
                encourage you to review the privacy policies of these external
                sites as we are not responsible for their data practices.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-3">
                Automated Decision-Making and Profiling
              </li>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                Currently, Mepass does not use automated decision-making or
                profiling processes that may impact your rights. If we implement
                any such activities in the future, we will notify you and obtain
                your consent where required by law.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-3">
                Changes to This Privacy Policy
              </li>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                Mepass may update this Privacy Policy periodically. We encourage
                you to review it regularly. Any changes will be posted on this
                page, and we will notify you if significant updates are made.
              </p>
              <li className="text-lg md:text-xl text-[#101010] font-semibold mt-3">
                Contact Us
              </li>
              <p className="text-lg md:text-xl text-[#101010] font-normal mt-1">
                If you have any questions regarding this Privacy Policy or wish
                to exercise any of your rights concerning your data, please
                contact us at <span className="font-semibold">909906</span>
              </p>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
