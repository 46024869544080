import React, { useState } from "react"
import PropTypes from "prop-types"
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import "react-image-crop/dist/ReactCrop.css";
const FileUpload = ({
  validation,
  selectedFile,
  handleDivClick,
  fileInputRef,
  handleFileChange,
  name,
  isProfile,
  isCropModalOpen,
  setIsCropModalOpen,
  crop,
  setCrop,
  imageRef,
  setSelectedFile,
  minWidth,
  minHeight,
  formErrors
}) => {

  const [completedCrop, setCompletedCrop] = useState(null);
  function onImageLoad(e) {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width: 300,
          height: 300,
        },
        1,
        width,
        height
      ),
      width,
      height
    )

    setCrop(crop)
  }

  // Convert data URL to Blob
  const dataURLtoBlob = (dataURL) => {
    const [header, base64] = dataURL.split(',');
    const mime = header.match(/:(.*?);/)[1];
    const binary = atob(base64);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mime });
  };

  const onCropClick = () => {
    if (imageRef.current && completedCrop) {
      const croppedImageUrl = getCroppedImg(imageRef.current, completedCrop);
      setSelectedFile(croppedImageUrl);
      // Set the cropped image as a file for upload
      const croppedImageBlob = dataURLtoBlob(croppedImageUrl);
      validation.setFieldValue(name, croppedImageBlob);
      setIsCropModalOpen(false);
    }
  };
  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return canvas.toDataURL('image/jpeg');
  };


  const handleCropModalClose = () => {
    setIsCropModalOpen(false);
  };

  const isFileObject = selectedFile && selectedFile instanceof File
  const isURL = selectedFile && typeof selectedFile === "string"
  return (
    <div className="text-center">
      <div>
        <div className={isProfile ? "flex justify-center lg:justify-start mb-3  m-auto w-[160px]" : "flex justify-center lg:justify-start mb-3"}>
          <div className="bg-[#F6F6F6] p-4 rounded-lg w-full">
            <div
              className="flex flex-col items-center p-1 rounded-lg cursor-pointer border-2 border-[#5A5A5A] border-dashed aspect-square justify-center relative"
            >
              {selectedFile ? (
                <div className="bg-[#FFD1D3] rounded-full p-1 w-[28px] h-[28px] flex items-center justify-center text-primary absolute top-[13px] right-[9px]">
                  <i onClick={handleDivClick} className="fas fa-pen cursor-pointer text-sm"></i>
                </div>
              ) : null}
              {selectedFile ? (
                <div className="text-center">
                  {isFileObject && selectedFile.type.startsWith("image/") && (
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="Selected"
                      className="w-full aspect-square object-cover"
                    />
                  )}
                  {isURL && (
                    <img
                      src={selectedFile}
                      alt="Selected"
                      className="w-full aspect-square object-cover"
                    />
                  )}
                </div>
              ) : (
                <>
                  <i onClick={handleDivClick} className=" cursor-pointer fa-solid fa-plus text-[#5A5A5A] text-xl"></i>
                </>
              )}
              <input
                type="file"
                className="hidden"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Crop Modal */}
      {isCropModalOpen && (
        <div className="fixed z-30 overflow-y-auto top-0 w-full left-0">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-900 opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
              &#8203;
            </span>
            <div
              className="inline-block align-center bg-white border border-[#4D4444] rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] xl:w-[35%]"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-gradient-to-b from-[#FFFFFF] to-[#a2a2a245] px-5 py-4">
                <div className="flex items-center justify-between gap-6">
                  <h2 className="text-primary text-2xl font-body font-medium uppercase">
                    Crop Image
                  </h2>
                  <button
                    type="button"
                    className="py-2 px-2"
                    onClick={handleCropModalClose}
                  >
                    <i className="fas fa-times text-2xl"></i>
                  </button>
                </div>
              </div>
              <div className=" p-5 justify-end flex">
                <button className="border border-primary bg-primary text-white text-base font-body font-medium px-4 py-2 rounded-[5px]" type="button"
                  onClick={onCropClick}>Crop</button>
              </div>
              <div className="bg-white p-5 pt-0 md:h-[544px] lg:h-[594px] xxl:h-[677px] overflow-auto">
                {selectedFile && (
                  <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)}
                    maxHeight={minHeight}
                    maxWidth={minWidth}
                    minHeight={minHeight}
                    minWidth={minWidth}
                    onComplete={(c) => setCompletedCrop(c)}>
                    <img ref={imageRef} src={selectedFile} onLoad={onImageLoad} />
                  </ReactCrop>
                )}

              </div>

            </div>
          </div>
        </div >
      )
      }
      {validation.touched[name] && validation.errors[name] ? (
        <div className="text-red-500">{validation.errors[name]}</div>
      ) : null}
      {formErrors && formErrors[name] ? (
        <div className="text-red-500">{formErrors[name]}</div>
      ) : null}
    </div>

  )
}
FileUpload.propTypes = {
  validation: PropTypes.object,
  selectedFile: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  handleDivClick: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  fileInputRef: PropTypes.object,
  handleFileChange: PropTypes.func,
}
export default FileUpload