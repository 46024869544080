import React, { useState, useEffect } from "react";
import about from "../../assets/images/about-us.png";
import img from "../../assets/images/saas-ticket.png";
import img2 from "../../assets/images/platform.png";
import coupon from "../../assets/images/small/coupon-light.png";
import coupon1 from "../../assets/images/small/calander-yellow.png";
import coupon2 from "../../assets/images/small/copy-text.png";
import coupon3 from "../../assets/images/small/nishant.png";
import coupon4 from "../../assets/images/small/friends.png";
import coupon5 from "../../assets/images/small/rupee.png";
import SeoDetails from "../../seo/SeoDetails";
function Business() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? null : accordionId);
  };

  const faqItems = [
    {
      title: 'How to Register as a Merchant?',
      content:
        'To register as a merchant on Mepass, visit our Mepass Business Page and download the Mepass merchant application. Initiate the registration process by submitting your company details and agreement. After completing the registration and document verification, your merchant account will become active.',
    },
    {
      title: 'What kind of documents are required to get registered on Mepass?',
      content:
        'You need to register with your company PAN, TAN, and GST documents, along with the company registration certificate issued by the government. If company documents are not available, an individual PAN card and documents can be submitted.',
    },
    {
      title: 'What is the event hosting charge, and why am I asking for payment of event registration?',
      content:
        'The event hosting charge is basically a service charge for hosting an event on Mepass. Once you submit initial registration details, the Mepass team will assign you a dedicated executive to help you modify and apply advanced settings and maintain your event campaign.',
    },
    {
      title: 'How can I set up GST on the ticket price and manage the convenience charge on tickets?',
      content:
        'You can set up GST as inclusive or exclusive of the pass price. In the case of exclusive, that GST amount will be added to the Pass Price and for the inclusive feature, user will only pay the Mepass price. The convenience charge can be set up on the User side/Organiser side. Depending on this, we will charge the convenience fee.',
    },
    {
      title: 'How will Mepass initiate a refund to users if an event is cancelled, and can I get a refund for Mepass services?',
      content:
        ' Mepass will initiate an automatic reverse payment to refund all audience members for their event tickets if the event is cancelled. However, only users who made payments directly to Mepass (not through the merchant) will receive refunds. No, all Mepass services are non-refundable.',
    },

  ];

  return (
    <>
      <SeoDetails title="mepass | Business"
        description="mepass | Business"
      />
      <div
        className="h-[450px] md:h-[686px] bg-neutral bg-blend-normal pt-44 md:pb-0 pb-28 md:bg-center bg-center bg-no-repeat flex items-center"
        style={{ backgroundImage: `url(${about})` }}
      >
        <div className="container">
          <div className="md:mt-24 mt-0">
            <h2 className="text-white text-[30px] md:text-5xl lg:text-[64px] font-body font-semibold">
              Elevate Your Events with Mepass
            </h2>
            <p className="text-white text-base font-body font-normal mt-0 md:mt-3">
              "Effortlessly Manage, Promote, and Sell Tickets for Your Events"
            </p>
            <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[0.3rem] py-2 px-8 rounded-[5px] font-body hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-5">
              Get Started
            </button>
          </div>
        </div>
      </div>
      <div className="bg-neutral">
        <div className="container">
          {/* -----------------------------------------------start-section-------------------- */}
          <div className="pt-24">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="bg-[#131313] border border-[#5A5A5A] p-12 rounded-lg text-center">
                <h3 className="text-white text-[40px] font-body font-light uppercase">
                  EVENTS
                </h3>
                <span className="text-[40px] text-[#FFD254] font-body font-semibold mt-3">
                  250+
                </span>
              </div>
              <div className="bg-[#131313] border border-[#5A5A5A] p-12 rounded-lg text-center">
                <h3 className="text-white text-[40px] font-body font-light uppercase">
                  CITY
                </h3>
                <span className="text-[40px] text-[#FFD254] font-body font-semibold mt-3">
                  58+
                </span>
              </div>
              <div className="bg-[#131313] border border-[#5A5A5A] p-12 rounded-lg text-center">
                <h3 className="text-white text-[40px] font-body font-light uppercase">
                  CROWD
                </h3>
                <span className="text-[40px] text-[#FFD254] font-body font-semibold mt-3">
                  7000000+
                </span>
              </div>
            </div>
          </div>
          {/* --------------------------------------------------end-section----------------------------- */}

          {/* ------------------------------------choose-mepass-start-section-------------------------------- */}
          <div className="pt-24">
            <div className="bg-[#131313] border border-[#5A5A5A] py-[60px] px-[30px] rounded-lg">
              <div className="text-center">
                <h2 className="text-3xl md:text-5xl text-white font-body font-semibold">
                  Why Choose Mepass?
                </h2>
                <p className="text-base text-white font-body font-regular mt-3">
                  Unlock seamless event management with Mepass. Boost ticket
                  sales and simplify attendee management effortlessly
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-[30px]">
                <div className="bg-[#424242] p-4 border border-secondary rounded-[10px]">
                  <div className="grid grid-cols-4 items-center gap-6 md:gap-4 xl:gap-6">
                    <div className="bg-[#313131] w-[50px] h-[50px] p-3 xl:p-0 xl:w-[80px] xl:h-[80px] flex items-center justify-center rounded-[10px]">
                      <img src={coupon} alt="img" className="inline-block" />
                    </div>
                    <div className="col-span-3">
                      <p className="text-base md:text-sm lg:text-[0.775rem] 2xl:text-base text-[#DCDCDC] font-body font-regular">
                        Struggling to Sell Enough Event Tickets?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-[#424242] p-4 border border-secondary rounded-[10px]">
                  <div className="grid grid-cols-4 items-center gap-6 md:gap-4 xl:gap-6">
                    <div className="bg-[#313131] w-[50px] h-[50px] p-3 xl:p-0 xl:w-[80px] xl:h-[80px] flex items-center justify-center rounded-[10px]">
                      <img src={coupon1} alt="img" className="inline-block" />
                    </div>
                    <div className="col-span-3">
                      <p className="text-base md:text-sm lg:text-[0.775rem] 2xl:text-base text-[#DCDCDC] font-body font-regular">
                        Need Help with Effective Event Promotion and Awareness?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-[#424242] p-4 border border-secondary rounded-[10px]">
                  <div className="grid grid-cols-4 items-center gap-6 md:gap-4 xl:gap-6">
                    <div className="bg-[#313131] w-[50px] h-[50px] p-3 xl:p-0 xl:w-[80px] xl:h-[80px] flex items-center justify-center rounded-[10px]">
                      <img src={coupon2} alt="img" className="inline-block" />
                    </div>
                    <div className="col-span-3">
                      <p className="text-base md:text-sm lg:text-[0.775rem] 2xl:text-base text-[#DCDCDC] font-body font-regular">
                        Facing Issues with Pass Duplication?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-[#424242] p-4 border border-secondary rounded-[10px]">
                  <div className="grid grid-cols-4 items-center gap-6 md:gap-4 xl:gap-6">
                    <div className="bg-[#313131] w-[50px] h-[50px] p-3 xl:p-0 xl:w-[80px] xl:h-[80px] flex items-center justify-center rounded-[10px]">
                      <img src={coupon3} alt="img" className="inline-block" />
                    </div>
                    <div className="col-span-3">
                      <p className="text-base md:text-sm lg:text-[0.775rem] 2xl:text-base text-[#DCDCDC] font-body font-regular">
                        Finding Efficient Pass Distribution a Challenge?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-[#424242] p-4 border border-secondary rounded-[10px]">
                  <div className="grid grid-cols-4 items-center gap-6 md:gap-4 xl:gap-6">
                    <div className="bg-[#313131] w-[50px] h-[50px] p-3 xl:p-0 xl:w-[80px] xl:h-[80px] flex items-center justify-center rounded-[10px]">
                      <img src={coupon4} alt="img" className="inline-block" />
                    </div>
                    <div className="col-span-3">
                      <p className="text-base md:text-sm lg:text-[0.775rem] 2xl:text-base text-[#DCDCDC] font-body font-regular">
                        Wrestling with Complicated Attendee Management?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-[#424242] p-4 border border-secondary rounded-[10px]">
                  <div className="grid grid-cols-4 items-center gap-6 md:gap-4 xl:gap-6">
                    <div className="bg-[#313131] w-[50px] h-[50px] p-4 xl:p-0 xl:w-[80px] xl:h-[80px] flex items-center justify-center rounded-[10px]">
                      <img src={coupon5} alt="img" className="inline-block" />
                    </div>
                    <div className="col-span-3">
                      <p className="text-base md:text-sm lg:text-[0.775rem] 2xl:text-base text-[#DCDCDC] font-body font-regular">
                        Limited Reach to Your Target Audience Hindering Ticket
                        Sales?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ------------------------------------choose-mepass-end-section-------------------------------- */}

          {/* ------------------------------------Our-Services-start-section-------------------------------- */}
          <div className="pt-24">
            <div className="bg-[#131313] border border-[#5A5A5A] py-[22px] px-[22px] lg:px-[117px] rounded-lg">
              <div className="text-center">
                <h2 className="text-3xl md:text-5xl text-white font-body font-semibold">
                  Our Services for Event Organizers
                </h2>
                <p className="text-base text-white font-body font-regular mt-3">
                  Mepass offers comprehensive solutions to enhance your event
                  management and ticket sales
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-8 items-center pt-[30px]">
                <div>
                  <img src={img} alt="img" className="rounded-[20px]" />
                </div>
                <div className="col-span-2 mt-4 md:mt-0">
                  <h3 className="text-2xl md:text-4xl text-white font-body font-medium">
                    SaaS Ticketing Software
                  </h3>
                  <p className="text-md md:text-base text-white font-body font-regular mt-[10px]">
                    Effortlessly create, manage, and track all aspects of your
                    event ticketing with our customizable SaaS ticketing
                    software. Enjoy streamlined ticket creation, real-time
                    tracking of sales and attendance, comprehensive reporting
                    tools, and seamless integration with your event marketing
                    strategies. Our software is designed to simplify the
                    ticketing process, increase operational efficiency, and
                    provide valuable insights to optimize your event success.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center pt-[30px]">
                <div className="col-span-2 order-2 md:order-1">
                  <h3 className="text-2xl md:text-4xl text-white font-body font-medium">
                    E-commerce Platform
                  </h3>
                  <p className="text-md md:text-base text-white font-body font-regular mt-[10px]">
                    With our e-commerce platform, you can list and showcase your
                    events to a wide audience, gaining maximum exposure and
                    driving ticket sales. Benefit from customizable event pages,
                    integrated marketing tools, secure payment processing, and a
                    user-friendly interface that enhances the ticket purchasing
                    experience for your customers. Expand your reach, attract
                    more attendees, and create memorable event experiences with
                    our powerful e-commerce platform.
                  </p>
                </div>
                <div className="order-1 md:order-2">
                  <img src={img2} alt="img" />
                </div>
              </div>
            </div>
          </div>
          {/* ------------------------------------Our-Services-end-section-------------------------------- */}

          {/* ----------------------------------qeustion-section-start----------------------------------- */}
          <div className="pt-24 pb-24">
            <div className="text-center mb-5">
              <button className="bg-primary border border-primary md:px-8 px-4 py-2 md:py-5 rounded-[5px] text-white text-base font-body font-medium tracking-[0.1rem] md:tracking-[0.3rem] uppercase">Join as organiser</button>
            </div>
            <h2 className="text-white text-xl md:text-2xl lg:text-[40px] font-body font-light uppercase tracking-[0.3rem] text-center">
              Frequently asked questions
            </h2>
            <p className="text-base text-[#DCDCDC] font-body font-normal mt-5 text-center">
            Here's everything you need to know about us!
            </p>
            <div className="flex flex-col w-full mt-8">
              {faqItems.map((item, index) => (
                <div key={index} className="group border-b border-[#323232] py-3">
                  <button
                    className="w-full flex items-start md:items-center justify-between gap-4 font-semibold focus:outline-none"
                    onClick={() => toggleAccordion(index)}
                  >
                    <span
                      className={`text-${openAccordion === index ? "secondary" : "white"
                        } font-body font-medium text-base md:text-2xl mr-3 text-left max-w-[85%] md:max-w-full`}
                    >
                      {item.title}
                    </span>
                    <div className="border border-white p-1 rounded-full w-[25px] h-[25px] flex items-center justify-center">
                      <i
                        className={`fas fa-${openAccordion === index ? "minus" : "plus"
                          } text-white text-sm mt-[2px]`}
                      ></i>
                    </div>
                  </button>
                  <div
                    className={`overflow-hidden transition-max-height duration-300 ${openAccordion === index ? "max-h-[1000px]" : "max-h-0"
                      }`}
                  >
                    <p className="text-base text-[#DCDCDC] font-body font-normal mt-2 text-left max-w-[85%] md:max-w-full">
                      {item.content}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* ----------------------------------qeustion-section-end----------------------------------- */}
        </div>
      </div>
    </>
  );
}

export default Business;
