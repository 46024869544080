import PropTypes from "prop-types"
import React from "react"
import icon from "../../assets/images/small/download.png"

const TANUpload = ({
  validation,
  preview,
  handleChange,
  label,
  nameInput,
  fileType,
  handleDeleteFile,
}) => {
  const handleFileClick = () => {
    const element = document.getElementById(nameInput)
    if (element) {
      element.click()
    }
  }

  return (
    <div>
      <label className="text-xl text-black font-body font-medium">
        {label}
      </label>
      <div
        className="bg-[#FAFAFA] p-3 xl:p-5 lg:p-2 rounded-lg border-dashed border-2 cursor-pointer mt-2"
        onClick={handleFileClick}
      >
        {preview ? (
          fileType === "application/pdf" ? (
            <div className="flex items-center">
              <div className="flex-none	bg-[#E7E7E7] p-3">
                <iframe
                  src={preview}
                  title="Document Preview"
                  className="inline-block w-32 h-32 object-cover"
                ></iframe>
              </div>
              <div className="flex-1 ml-3">
                <div className="flex items-center justify-between">
                  <i
                    className="fas fa-trash text-primary"
                    onClick={handleDeleteFile}
                  ></i>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="flex items-center">
                <div className="flex-none	bg-[#E7E7E7] p-3">
                  <img
                    src={preview}
                    alt="Preview"
                    className="inline-block w-16 aspect-[9/16] object-cover"
                  />
                </div>
                <div className="flex-1 ml-3">
                  <div className="flex items-center justify-end">
                    <i
                      className="fas fa-trash text-primary"
                      onClick={handleDeleteFile}
                    ></i>
                  </div>
                </div>
              </div>
            </>
          )
        ) : (
          <div className="text-center">
            <img src={icon} className="inline-block" alt="icon" />
          </div>
        )}
        {!preview ? (
          <>
            <p className="text-base font-body text-center font-normal text-[#111827] leading-6 py-[10px]">
              Browse and choose the files you
              <br /> want to upload from your Data
            </p>
            <span className="bg-primary rounded-md w-[32px] h-[32px] m-auto flex items-center justify-center text-white p-2">
              <i className="fa-solid fa-plus"></i>
            </span>
            <input
              id={nameInput}
              type="file"
              accept="image/*, application/pdf"
              style={{ display: "none" }}
              onChange={handleChange}
            />
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}
TANUpload.propTypes = {
  validation: PropTypes.object,
  preview: PropTypes.string,
  handleChange: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  label: PropTypes.string,
  nameInput: PropTypes.string,
  tanFileType: PropTypes.string,
  gstFileType: PropTypes.string,
}
export default TANUpload
