import React from 'react';
import { Link } from "react-router-dom";
import { convertToAmPm } from "../../../common/commonFunction";

const PassDetails = ({
    passDetails,
    textColor,
    backGroundColor,
    primaryColor
}) => {

    return (

        <>
            <div className="grid grid-cols-3 gap-6 p-[15px]">
                <div>
                    <img src={
                        passDetails && passDetails.event_pass && passDetails.event_pass.image
                            ? passDetails.event_pass.image
                            : passDetails && passDetails.event && passDetails.event.banner_image
                    } alt="img" className="inline-block aspect-[9/16] object-cover rounded-lg" />
                </div>
                <div className="col-span-2">
                    <div className="grid grid-flow-row-dense grid-row-5 h-full content-stretch	">
                        <div className="row-span-4">
                            <h2 style={{ color: textColor }} className={`text-lg text-[${textColor}] font-body font-semibold capitalize mb-2`}>
                                {passDetails && passDetails.event && passDetails.event.name}
                            </h2>
                            <p style={{ color: textColor }} className={`text-lg font-normal mb-[2px] text-[${textColor}]`}>
                                <i class="fas fa-calendar-minus text-[#5A5A5A] mr-2"></i>{passDetails && passDetails.event_pass && passDetails.event_pass.pass_date}
                            </p>
                            {passDetails && passDetails.event_date && passDetails.event_date.start_time ?
                                <p style={{ color: textColor }} className={`text-lg font-normal text-[${textColor}]`}>
                                    <i class="fas fa-clock text-[#5A5A5A] mr-2"></i> {convertToAmPm(passDetails.event_date.start_time)}
                                </p>
                                : ''}
                        </div>
                        <div className="row-span-1">
                            <div className="flex items-center mb-2">
                                <div className="flex-1">
                                    <h2 className={`text-lg font-body font-semibold capitalize`} style={{ color: primaryColor }}>
                                        {passDetails && passDetails.event_pass && passDetails.event_pass.name}
                                    </h2>
                                </div>
                                <div className="flex-none">
                                    <div className="flex items-center gap-1 justify-end">
                                        <p className="text-sm text-[#5A5A5A]">Qty.</p>
                                        <p className="text-lg font-normal text-center" style={{ color: primaryColor }}>
                                            {passDetails.available_quantity}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-2">
                                <div className="flex-1">
                                    {passDetails && passDetails.comments &&
                                        <h2 className={`text-base font-body font-normal`} style={{ color: textColor }}>
                                            {passDetails && passDetails.comments}
                                        </h2>
                                    }
                                </div>
                                <div className="flex-none">
                                    {passDetails && passDetails.event && passDetails.event.slug &&
                                        <Link to={`/events/${passDetails.event.slug}/book-tickets`} target="_blank" className='text-[#5A5A5A] text-sm font-normal underline'>To Purchase More</Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
PassDetails.propTypes = {

}
export default PassDetails
