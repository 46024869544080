import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import api from "../../constants/api";
import user from "../../assets/images/default/user-image.png";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import EventView from "../Event/View";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
// import required modules
import { Autoplay, FreeMode, Pagination } from "swiper/modules";

const Interest = ({ props, isApiCall, setIsApiCall }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));

  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isUpdateFavoriteEventData, setIsUpdateFavoriteEventData] =
    useState(false);
  const [favoriteList, setFavoriteList] = useState([]);
  const [artistList, setArtistList] = useState([]);
  const [favoriteEventList, setFavoriteEventList] = useState([]);
  const [totalArtist, setTotalArtist] = useState(0);
  const [totalFavoriteEvent, setTotalFavoriteEvent] = useState(0);
  const [totalFavorite, setTotalFavorite] = useState(0);
  const [isUpdateFavoriteData, setIsUpdateFavoriteData] = useState(false);
  const [activeTab, setActiveTab] = useState(1); // Renamed activeButton to activeTab
  const [requestData, setRequestData] = useState({
    start: 0,
    limit: 10,
    type: "Artist",
  });
  const [requestFavoriteData, setRequestFavoriteData] = useState({
    start: 0,
    limit: 100,
    type: "Artist",
  });
  const [requestFavoriteEventData, setRequestFavoriteEventData] = useState({
    start: 0,
    limit: 10,
    type: "Event",
  });
  // Function to handle button click and change active tab
  const handleTabClick = (tabId) => {
    if (tabId === 1) {
      setArtistList([]);
      setTotalArtist(0);
      setTotalFavorite(0);
      setFavoriteList([]);
      getArtistList();
      getFavoriteList();
    } else {
      setTotalFavoriteEvent(0);
      setFavoriteEventList([]);
      getFavoriteEventList();
    }
    setActiveTab(tabId);
  };
  useEffect(() => {
    if (isApiCall === 0) {
      if (activeTab === 1) {
        getArtistList();
        getFavoriteList();
      } else {
        getFavoriteEventList();
      }
      setIsApiCall(1);
    }
  }, [isApiCall, dispatch, props.router.navigate]);

  const getFavoriteEventList = () => {
    setTotalFavoriteEvent(0);
    setFavoriteEventList([]);
    setIsUpdateFavoriteEventData(true);
    dispatch(
      getData(
        requestFavoriteEventData,
        props.router.navigate,
        api.addFavoritesArtistList
      )
    );
  };

  const getArtistList = () => {
    setArtistList([]);
    setTotalArtist(0);
    setIsUpdateData(true);
    dispatch(getData(requestData, props.router.navigate, api.ArtistList));
  };
  const getFavoriteList = () => {
    setTotalFavorite(0);
    setFavoriteList([]);
    setIsUpdateFavoriteData(true);
    dispatch(
      getData(
        requestFavoriteData,
        props.router.navigate,
        api.addFavoritesArtistList
      )
    );
  };

  if (!isEmpty(data) && !isEmpty(data.allArtists) && isUpdateData) {
    setArtistList((prevArtists) => [...prevArtists, ...data.allArtists]);
    setTotalArtist(data.filter_count);
    setIsUpdateData(false);
  }
  if (!isEmpty(data) && !isEmpty(data.favoriteList) && isUpdateFavoriteData) {
    setFavoriteList((prevFavorite) => [...prevFavorite, ...data.favoriteList]);
    setTotalFavorite(data.filter_count);
    setIsUpdateFavoriteData(false);
  }
  if (
    !isEmpty(data) &&
    !isEmpty(data.favoriteList) &&
    isUpdateFavoriteEventData
  ) {
    setFavoriteEventList((prevFavorite) => [
      ...prevFavorite,
      ...data.favoriteList,
    ]);
    setTotalFavoriteEvent(data.filter_count);
    setIsUpdateFavoriteEventData(false);
  }

  const loadMoreArtist = () => {
    setRequestData({
      start: requestData.start + 10,
      limit: 10,
    });
    getArtistList();
  };
  const loadMoreFavoriteEvent = () => {
    setRequestFavoriteEventData({
      start: requestFavoriteEventData.start + 10,
      limit: 10,
    });
    getFavoriteEventList();
  };
  const loadMoreFavorite = () => {
    setRequestFavoriteData({
      start: requestFavoriteData.start + 10,
      limit: 10,
    });
    getFavoriteList();
  };
  const [isAddData, setIsAddData] = useState(false);
  const addToFavorite = (artistId) => {
    setIsAddData(true);
    const newData = {
      favorite_id: artistId,
      type: "Artist",
    };
    dispatch(
      addUpdateNewData(newData, props.router.navigate, api.AddFavorites)
    );
  };

  if (!isEmpty(data) && !isEmpty(data.favoritesAdd) && isAddData) {
    setIsAddData(false);
    getFavoriteList();
  }

  // Remove favorite
  const [isRemoveData, setIsRemoveData] = useState(false);
  const removeToFavorite = (artistId) => {
    setIsRemoveData(true);
    const newData = {
      favorite_id: artistId,
      type: "Artist",
    };
    dispatch(
      addUpdateNewData(newData, props.router.navigate, api.RemoveFavorites)
    );
  };
  if (!isEmpty(data) && !isEmpty(data.favoritesDelete) && isRemoveData) {
    setIsRemoveData(false);
    getFavoriteList();
  }
  return (
    <>
      <div className="bg-[#F9F9F9] p-4 md:p-[40px] rounded-lg md:rounded-[2px]">
        <div className="flex items-center justify-between bg-white p-3 rounded-lg mb-7">
          <button
            className={`button ${activeTab === 1
              ? "active-button bg-[#CB2129] rounded-[4px] !text-white "
              : ""
              } py-[10px] px-[30px] text-[#101010] font-semibold text-base md:text-xl w-1/2`}
            onClick={() => handleTabClick(1)}
          >
            Artist
          </button>
          <button
            className={`button ${activeTab === 2
              ? "active-button bg-[#CB2129] rounded-[4px] !text-white "
              : ""
              } py-[10px] px-[30px] text-[#101010] font-semibold text-base md:text-xl w-1/2`}
            onClick={() => handleTabClick(2)}
          >
            Event
          </button>
        </div>
        {activeTab === 1 ? (
          <>
            <div className="flex flex-wrap items-center gap-3">
              {favoriteList &&
                favoriteList.map((favorite, indexKey) => (
                  <div
                    key={indexKey}
                    className="text-white text-sm font-body font-normal p-2 inline bg-black rounded-full border border-black cursor-default"
                  >
                    {favorite.name}
                    <i
                      className="fas fa-times ml-2.5 cursor-pointer"
                      onClick={() => {
                        removeToFavorite(favorite.id);
                      }}
                    ></i>
                  </div>
                ))}
            </div>

            {totalFavorite > favoriteList.length ? (
              <div className="flex justify-center mt-5">
                <button
                  className="button bg-secondary text-black font-body font-normal text-[18px] py-2 px-5 rounded-md"
                  onClick={loadMoreFavorite}
                >
                  Load More
                </button>
              </div>
            ) : (
              ""
            )}

            <div className="mt-4 md:mt-6">
              {/* <h3 className="text-[#101010] text-xl font-semibold mb-4">
                Trending
              </h3> */}
              {/* <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-5">
                {artistList &&
                  artistList.map((artist, index) => (
                    <div
                      key={index}
                      className="cursor-pointer"
                      onClick={() => {
                        addToFavorite(artist.id);
                      }}
                    >
                      <img
                        src={artist.image ? artist.image : user}
                        alt=""
                        className="border border-secondary rounded-[4px]"
                      />
                      <h4 className="text-[#101010] text-base font-semibold mt-2">
                        {artist.name}
                      </h4>
                      <div className="flex mt-1">
                        <div className="flex-1">
                          <p className="text-[#737373] text-sm font-medium">
                            Musician
                          </p>
                        </div>
                        <div className="flex-none">
                          <div class="border border-[#CB2129] py-[2px] px-[4px] inline-block rounded-full text-nowrap flex-none">
                            <p class="text-black text-[11px] font-semibold">
                              <i class="fa-solid fa-star mr-1 text-[#F0BA0E] text-[10px]"></i>
                              <b>4.2</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div> */}
              {/* {artistList && (
                <div className="swiper-container interest-slider">
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    pagination={{
                      clickable: true,
                    }}
                    breakpoints={{
                      320: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      640: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                      1200: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                      },
                    }}
                    modules={[Pagination]}
                    className="mySwiper gallary-slider !pb-14"
                  >
                    {artistList.map((artist, index) => (
                      <SwiperSlide key={index}>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            addToFavorite(artist.id);
                          }}
                        >
                          <img
                            src={artist.image ? artist.image : user}
                            alt={artist.name}
                            className="border border-secondary rounded-[4px] w-full"
                          />
                          <h4 className="text-[#101010] text-[12px] md:text-base font-semibold mt-2">
                            {artist.name}
                          </h4>
                          <div className="flex mt-1">
                            <div className="flex-1">
                              <p className="text-[#737373] text-[12px] md:text-sm font-medium">
                                Musician
                              </p>
                            </div>
                            <div className="flex-none">
                              <div className="border border-[#CB2129] py-[2px] px-[4px] inline-block rounded-full text-nowrap flex-none">
                                <p className="text-black text-[11px] font-semibold">
                                  <i className="fa-solid fa-star mr-1 text-[#F0BA0E] text-[10px]"></i>
                                  <b>4.2</b>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )} */}
            </div>
            <div className="mt-8">
              {/* <h3 className="text-[#101010] text-xl font-semibold mb-4">
              Other
              </h3> */}
              {artistList && (
                <div className="swiper-container interest-slider">
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    pagination={{
                      clickable: true,
                    }}
                    breakpoints={{
                      320: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      640: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                      },
                    }}
                    modules={[Pagination]}
                    className="mySwiper gallary-slider !pb-14"
                  >
                    {artistList.map((artist, index) => (
                      <SwiperSlide key={index}>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            addToFavorite(artist.id);
                          }}
                        >
                          <img
                            src={artist.image ? artist.image : user}
                            alt={artist.name}
                            className="rounded-[4px] w-full"
                          />
                          <h4 className="text-[#101010] text-[12px] md:text-base font-semibold mt-2">
                            {artist.name}
                          </h4>
                          <div className="flex mt-1">
                            <div className="flex-1">
                              <p className="text-[#737373] text-[12px] md:text-sm font-medium">
                                {artist.artist_category}
                              </p>
                            </div>
                            {artist.rating > 0 &&
                              <div className="flex-none">
                                <div className="border border-[#CB2129] py-[2px] px-[4px] inline-block rounded-full text-nowrap flex-none">
                                  <p className="text-black text-[11px] font-semibold">
                                    <i className="fa-solid fa-star mr-1 text-[#F0BA0E] text-[10px]"></i>
                                    <b>{artist.rating}</b>
                                  </p>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )}
            </div>
            <div className="flex justify-center mt-5">
              {totalArtist > artistList.length ? (
                <button
                  className="button bg-secondary text-black font-body font-normal text-base md:text-[18px] py-2 px-5 rounded-md"
                  onClick={loadMoreArtist}
                >
                  Load More
                </button>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-10">
              {favoriteEventList &&
                favoriteEventList.map((singleEvent, index) => (
                  <Link to={"/events/" + singleEvent.slug}>
                    <EventView singleEvent={singleEvent} key={index} />
                  </Link>
                ))}
            </div>
            {favoriteEventList.length === 0 && (
              <div className="text-[#101010] text-base font-normal text-center">
                No favorite event found.
              </div>
            )}

            <div className="flex justify-center mt-5">
              {totalFavoriteEvent > favoriteEventList.length ? (
                <button
                  className="button bg-secondary text-black font-body font-normal text-base md:text-[18px] py-2 px-5 rounded-md"
                  onClick={loadMoreFavoriteEvent}
                >
                  Load More
                </button>
              ) : (
                ""
              )}
            </div>
          </>
        )}
        <div>
          {/* <div className="flex justify-center md:justify-end gap-4">
            <button
              type="button"
              className="text-[#CB2129] border border-[#CB2129] text-base font-medium rounded-lg py-2 px-4 inline-block"
            >
              Cancle
            </button>
            <button
              type="submit"
              className="text-white btn-liner-gradient text-base font-semibold rounded-lg py-2 px-4 inline-block"
            >
              Save changes
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};
Interest.propTypes = {
  props: PropTypes.object,
};
export default Interest;
