import { useState, useEffect } from "react";
import React from "react";
import userImageDefault from "../../assets/images/default/user-image.png";
import { numberFormatter } from "../../common/commonFunction";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getData,
  clearData,
  addUpdateNewData,
} from "../../store/AppMaster/actions";
import starIcon from "../../assets/light_theme_images/star.svg";
import api from "../../constants/api";
import { isEmpty } from "lodash";

const ArtistView = ({ singleArtist, pageTitle }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));

  const [isAddData, setIsAddData] = useState(false);
  const addToFavorite = (favorite_id) => {
    setIsAddData(true);
    const newData = {
      favorite_id: favorite_id,
      type: "Artist",
    };
    dispatch(addUpdateNewData(newData, "", api.AddFavorites));
  };

  if (!isEmpty(data) && !isEmpty(data.favoritesAdd) && isAddData) {
    setIsAddData(false);
    singleArtist.is_favorite = "Yes";
  }

  // Remove favorite
  const [isRemoveData, setIsRemoveData] = useState(false);
  const removeToFavorite = (favorite_id) => {
    setIsRemoveData(true);
    const newData = {
      favorite_id: favorite_id,
      type: "Artist",
    };
    dispatch(addUpdateNewData(newData, "", api.RemoveFavorites));
  };
  if (!isEmpty(data) && !isEmpty(data.favoritesDelete) && isRemoveData) {
    setIsRemoveData(false);
    singleArtist.is_favorite = "No";
  }

  return (
    <div>
      <div className="flex flex-col gap-3">
        <div className="relative">
          <Link to={"/artiest/" + singleArtist.slug}>
            <img
              src={singleArtist.image ? singleArtist.image : userImageDefault}
              alt=""
              className="w-full rounded-xl aspect-[1/1] object-cover"
            />
          </Link>
        </div>
        <div>
          <h3 className="text-[#101010] text-base md:text-lg font-semibold">
            {singleArtist.name}
          </h3>
          <div className="flex items-center mt-2">
            <p className="text-[#737373] text-base font-medium flex-1">
              {singleArtist.category_of_artist ? singleArtist.category_of_artist : ""}
            </p>
            {singleArtist.rating > 0 ? (
              <div className="border border-[#CB2129] py-[2px] px-[4px] inline-block rounded-full text-nowrap flex-none">
                <Link to={"/artiest/" + singleArtist.slug}>
                  <p className="text-black text-[14px] font-semibold">
                    <i className="fa-solid fa-star mr-1 text-[#F0BA0E] text-sm"></i>
                    <b>{singleArtist.rating}</b>
                  </p>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* <div>
        <div className="flex items-center mt-3 justify-between">
          {singleArtist.fanbase && singleArtist.fanbase ? (
            <p className="text-white text-sm font-body font-medium mr-2">
              <i className="fas fa-user-friends mr-2"></i>
              Fan Base: {numberFormatter(singleArtist.fanbase)}
            </p>
          ) : (
            ""
          )}
        </div>
      </div> */}

      {localStorage.getItem("publicMePassUser") ? (
        singleArtist.is_favorite === "Yes" ? (
          <button
            onClick={() => removeToFavorite(singleArtist.id)}
            className="border border-[#CB2129] text-[#CB2129] text-sm lg:text-xs xl:text-sm xxl:text-base font-normal py-3 px-2 rounded-[6px] md:rounded-lg w-full mt-3"

          >
            Remove Favorite <i className="fal fa-minus ml-2"></i>
          </button>
        ) : (
          <button
            onClick={() => addToFavorite(singleArtist.id)}
            className="border border-[#CB2129] text-[#CB2129] text-sm lg:text-xs xl:text-sm xxl:text-base font-normal py-3 px-2 rounded-[6px] md:rounded-lg w-full mt-3"
          >
            Add To Favorite <i className="fal fa-plus ml-2"></i>
          </button>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default ArtistView;
