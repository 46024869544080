import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useFormik } from "formik"
import * as Yup from "yup"
import map from "../../assets/images/small/map.png";
import succesfully from "../../assets/images/small/succesfully.png";
import withRouter from "../../components/Common/withRouter";
import api from "../../constants/api";
import message from "../../constants/message";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import img from "../../assets/images/App-Store.png";
import img1 from "../../assets/images/google-play.png";
import img2 from "../../assets/images/small/pass-left-frame.svg"
import img3 from "../../assets/images/small/pass-right-frame.svg"
import img4 from "../../assets/images/small/dandiya-left-icon.svg"
import img5 from "../../assets/images/small/dandiya-right-icon.svg"
import img6 from "../../assets/images/google-play-light.svg";
import img7 from "../../assets/images/App-Store-light.svg"
import logo from "../../assets/images/bsp-technologies.svg"
import logo2 from '../../assets/images/weone-logo.jpeg'
import { convertToAmPm, convertToDate } from "../../common/commonFunction";
import DeletedImage from "../../assets/images/default/Deleted-image.png";
import ExpiredImage from "../../assets/images/default/Expired-image.png";
import ScannedImage from "../../assets/images/default/Scanned-image.png";
import RFIDImage from "../../assets/images/default/RFID-image.png";
import SharedImage from "../../assets/images/default/Shared-image.png";
import DandiyaMepass from "./DandiyaMepass";
import PassDetails from "./PassDetails";
import SponserList from "./SponserList";
import QrPassImage from "./QrPassImage";
import PriceDetail from "./PriceDetail";
const DandiyaQrPass = props => {
    const params = useParams();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => ({
        data: state.AppMaster.data,
    }));
    const { loading } = useSelector(state => ({
        loading: state.AppMaster.loading,
    }))
    const [isApiCall, setIsApiCall] = useState(0);
    const [isUpdateData, setIsUpdateData] = useState(false);
    const [passDetails, setPassDetails] = useState("");
    const [sponsorList, setSponsorList] = useState("");
    const [isSponsorData, setIsSponsorData] = useState(false);
    const [backGroundColor, setBackGroundColor] = useState("");
    const [textColor, setTextColor] = useState("");
    const [primaryColor, setPrimaryColor] = useState("");
    const [secondaryColor, setSecondaryColor] = useState("");
    const [ticketPrice, setTicketPrice] = useState(0);
    const [pricingDetails, setPricingDetails] = useState("");
    const [otherFees, setOtherFees] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    // const [gstAmount, setGstAmount] = useState(0);
    const [popupList, setPopupList] = useState('');
    const [isPopupData, setIsPopupData] = useState(false);
    const [showPopupModal, setIsShowPopupModal] = useState(false);
    const [addFlag, setAddFlag] = useState(false);

    const [showSplitModal, setShowSplitModal] = useState(false)
    const [showSuccessfullyModal, setShowSuccessfullyModal] = useState(false)
    const [showTermsModal, setShowTermsModal] = useState(false)
    const [seasonArray, setSeasonArray] = useState([])
    const [urlString, setUrlString] = useState('')

    //Submit form
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            mobile: "",
            confirm_mobile: "",
            count: 1,
        },
        validationSchema: Yup.object({
            mobile: Yup.string()
                .required(message.MOBILE_REQUIRED)
                .matches(/^[0-9]+$/, message.MOBILE_INVALID)
                .min(10, message.MOBILE_MIN_LENGTH)
                .max(10, message.MOBILE_MAX_LENGTH),
            /**Mobile and Conformation mobile same or not*/
            confirm_mobile: Yup.string()
                .required(message.CONFIRM_MOBILE_REQUIRED)
                .oneOf([Yup.ref("mobile"), null], message.MOBILE_NOT_MATCH),
            /**If count greater then available count */
            count: Yup.number()
                .required(message.COUNT_REQUIRED)
                .min(1, message.COUNT_MIN_LENGTH)
                .max(passDetails.available_quantity, message.COUNT_MAX_LENGTH),
        }),
        onSubmit: values => {
            setAddFlag(true)
            const requestData = {
                url_string: urlString ? urlString : params.uuid,
                mobile: values.mobile,
                confirm_mobile: values.confirm_mobile,
                count: values.count,
            };
            dispatch(addUpdateNewData(requestData, "", api.SplitSharePass))
        },
    })

    if (!isEmpty(data) && !isEmpty(data.splitAdd) && addFlag) {
        setUrlString('')
        setAddFlag(false)
        validation.resetForm()
        setShowSuccessfullyModal(true)
    }


    const getQrSponsorList = () => {
        setIsSponsorData(true);
        const requestData = { url_string: params.uuid };
        dispatch(getData(requestData, "", api.QrSponsorList));
    };

    useEffect(() => {
        if (isApiCall === 0) {
            setIsUpdateData(true);
            const requestData = { url_string: params.uuid };
            dispatch(getData(requestData, props.router.navigate, api.QrPassDetails));

            setIsPopupData(true);
            dispatch(getData(requestData, "", api.QrPopupList));
            setIsApiCall(1);
        }
    }, [isApiCall, dispatch, props.router.navigate, params.uuid]);

    if (!isEmpty(data) && !isEmpty(data.EventPopup) && isPopupData) {
        setPopupList(data.EventPopup[0]);
        setIsPopupData(false);
        if (data.EventPopup[0].image) {
            setIsShowPopupModal(true);
        }
    }
    if (!isEmpty(data) && !isEmpty(data.QRPassView) && isUpdateData) {
        const qrPassView = data.QRPassView;
        setPassDetails(qrPassView);
        if (!isEmpty(data.pricingDetails)) {
            setPricingDetails(data.pricingDetails);
        }
        if (!isEmpty(data.QRPassView.event_pass.background_color)) {
            setBackGroundColor(data.QRPassView.event_pass.background_color);
            let textColorNew = data.QRPassView.event_pass.background_color === "#000000" ? "#FFFFFF" : "#000000";
            setTextColor(textColorNew);
        } else {
            setBackGroundColor("#FFFFFF");
            setTextColor("#000000");
        }
        if (!isEmpty(data.QRPassView.event_pass.primary_color)) {
            setPrimaryColor(data.QRPassView.event_pass.primary_color ? data.QRPassView.event_pass.primary_color : "#e74c3c");
        }
        if (!isEmpty(data.QRPassView.event_pass.secondary_color)) {
            setSecondaryColor(data.QRPassView.event_pass.secondary_color ? data.QRPassView.event_pass.secondary_color : "#FFE272");
        }
        if (data.seasonArray.length > 0) {
            setSeasonArray(data.seasonArray);
        }
        setIsUpdateData(false);
        getQrSponsorList();
    }
    if (!isEmpty(data) && !isEmpty(data.EventSponsor) && isSponsorData) {
        setSponsorList(data.EventSponsor);
        setIsSponsorData(false);
    }

    const toggleSplitModal = () => {
        setShowSplitModal(!showSplitModal)
    }

    if (!loading && validation.isSubmitting) {
        validation.isSubmitting = false
    }
    return (
        <>
            {!isEmpty(passDetails) && (
                <div className={`bg-[#f4f4f4] min-h-screen h-full`}>
                    <div className="w-[90%] md:w-[398px] m-auto py-10">
                        <div className="flex items-center justify-between gap-3 mb-6">
                            <h1 className="text-primary text-3xl font-semibold mb-0">mepass</h1>
                            <p className="text-primary text-base font-medium mb-0">Made in <span className="font-semibold">BHARAT</span></p>
                        </div>
                        {passDetails && passDetails.pass_type_slug == 'season-mepass' ? (
                            <DandiyaMepass
                                seasonArray={seasonArray}
                                passDetails={passDetails}
                                pricingDetails={pricingDetails}
                                sponsorList={sponsorList}
                                textColor={textColor}
                                primaryColor={primaryColor}
                                secondaryColor={secondaryColor}
                                backGroundColor={backGroundColor}
                                RFIDImage={RFIDImage}
                                ExpiredImage={ExpiredImage}
                                DeletedImage={DeletedImage}
                                ScannedImage={ScannedImage}
                                SharedImage={SharedImage}
                                map={map}
                                toggleSplitModal={toggleSplitModal}
                                setShowTermsModal={setShowTermsModal}
                                setUrlString={setUrlString}

                            />
                        ) : (
                            <div className={`rounded-[10px] border-[3px] border-[#FFD685]`} style={{ backgroundColor: backGroundColor }}>
                                <PassDetails passDetails={passDetails} textColor={textColor} backGroundColor={backGroundColor} />
                                <div className="border-b-2 border-dashed border-[#EE3E45] my-3 mx-4 relative">
                                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                                </div>
                                <div className="relative">
                                    <img src={img2} alt="img" className="inline-block absolute left-0 -top-[13px]" />
                                    <img src={img3} alt="img" className="inline-block absolute right-0 -top-[13px]" />
                                </div>
                                <div className="p-[15px]">
                                    {/* <div className="flex items-center justify-between gap-4 max-w-[90%] m-auto">
                                        <h2 className={`text-lg text-[${textColor}] font-body font-semibold`} style={{ color: textColor }}> {passDetails && passDetails.event_pass && passDetails.event_pass.name}</h2>
                                        <div style={{ color: secondaryColor }} className={`text-base text-[${textColor}] font-body font-semibold `}>
                                            {passDetails.available_quantity}
                                        </div>
                                    </div> */}
                                    <div className="text-center">
                                        <div className="max-w-[75%] m-auto">
                                            {(passDetails && passDetails.status !== null && (passDetails.status === "Expired" || passDetails.status === "Deleted" || passDetails.status === 'Scann' || passDetails.available_quantity === 0)) ? (
                                                <>
                                                    {passDetails.status === "Expired" ? (
                                                        <img src={ExpiredImage} alt="img" className="inline-block my-4" />
                                                    ) : passDetails.status === "Deleted" ? (
                                                        <img src={DeletedImage} alt="img" className="inline-block my-4" />
                                                    ) : passDetails.status === "Scann" ? (
                                                        <img src={ScannedImage} alt="img" className="inline-block my-4" />
                                                    ) : passDetails.available_quantity === 0 ? (
                                                        <img src={SharedImage} alt="img" className="inline-block my-4" />
                                                    ) : passDetails && passDetails.event_pass && passDetails.event_pass.is_rfid == 'Yes' ? (
                                                        <img src={RFIDImage} alt="img" className="inline-block my-4" />
                                                    ) : ''}
                                                </>
                                            ) : passDetails && passDetails.event_pass && passDetails.event_pass.is_rfid == 'Yes' ?
                                                <img src={RFIDImage} alt="img" className="inline-block my-4" />
                                                :
                                                <img src={passDetails && passDetails.qr_image} alt="img" className="inline-block my-4" />
                                            }
                                        </div>
                                        {((passDetails && passDetails.event_pass && passDetails.event_pass.is_rfid == 'Yes') || (passDetails && passDetails.status !== null && (passDetails.status === "Expired" || passDetails.status === "Deleted" || passDetails.status === 'Scann' || passDetails.available_quantity === 0))) ? (
                                            <>
                                                <div className={'flex items-center justify-center'}>
                                                    {passDetails && passDetails.event_date && !isEmpty(passDetails.event_date.gmap_link) ? (
                                                        <div className="flex-none">
                                                            <Link to={passDetails && passDetails.event_date && passDetails.event_date.gmap_link} target="_blank"
                                                                rel="noopener noreferrer" className="bg-[#E7E7E7] rounded-full w-[56px] h-[56px] flex items-center justify-center ">
                                                                <img src={map} alt="img" className="inline-block" />
                                                            </Link>
                                                        </div>
                                                    ) :
                                                        <>
                                                            {passDetails && passDetails.event && !isEmpty(passDetails.event.gmap_link) && (
                                                                <div className="flex-none">
                                                                    <Link to={passDetails && passDetails.event && passDetails.event.gmap_link} target="_blank"
                                                                        rel="noopener noreferrer" className="bg-[#E7E7E7] rounded-full w-[56px] h-[56px] flex items-center justify-center ">
                                                                        <img src={map} alt="img" className="inline-block" />
                                                                    </Link>
                                                                </div>
                                                            )}
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        ) :
                                            <>
                                                <div className="relative">
                                                    <img src={img4} className="inline-block absolute -left-[7px] -top-[50px]" />
                                                    <img src={img5} className="inline-block absolute -right-[7px] -top-[50px]" />
                                                    <div className={passDetails && passDetails.is_transferable === 'No' ? 'flex items-center justify-center' : 'flex items-center justify-center'}>
                                                        {passDetails && passDetails.is_transferable === 'Yes' && (
                                                            <button onClick={toggleSplitModal} className={`splite-share-button rounded-[7px] text-base border-[2px] border-primary text-white font-semibold py-2 px-5 mr-3`}>
                                                                Split & Share
                                                            </button>
                                                        )}
                                                        {passDetails && passDetails.event_date && passDetails.event_date.gmap_link ? (
                                                            <Link to={passDetails && passDetails.event_date && passDetails.event_date.gmap_link} target="_blank"
                                                                rel="noopener noreferrer" className="bg-white border border-primary rounded-full w-[50px] h-[50px] flex items-center justify-center">
                                                                <img src={map} alt="img" className="inline-block" />
                                                            </Link>
                                                        ) :
                                                            <>
                                                                {passDetails && passDetails.event && !isEmpty(passDetails.event.gmap_link) ? (
                                                                    <Link to={passDetails && passDetails.event && passDetails.event.gmap_link} target="_blank"
                                                                        rel="noopener noreferrer" className="bg-white border border-primary rounded-full w-[50px] h-[50px] flex items-center justify-center ">
                                                                        <img src={map} alt="img" className="inline-block" />
                                                                    </Link>
                                                                ) : ''}
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {passDetails && passDetails.event_pass && passDetails.event_pass.notes && (
                                            <p onClick={
                                                () => {
                                                    setShowTermsModal(true)
                                                }
                                            }
                                                style={{ color: textColor }} className={`text-base font-body font-medium mt-4 text-[${textColor}] cursor-pointer`}>
                                                Terms & Condition</p>
                                        )}
                                    </div>
                                    <div className="bg-white border border-[#E7E7E7] rounded-[10px] p-4 mt-6">
                                        <div className="flex items-center">
                                            <p className="text-base text-black font-normal flex-1">Things to know</p>
                                            <i className="fas fa-chevron-right text-black flex-none"></i>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <>
                                            <PriceDetail passDetails={passDetails}
                                                pricingDetails={pricingDetails}
                                                textColor={textColor}
                                                secondaryColor={secondaryColor} />
                                        </>

                                    </div>
                                </div>

                                <div className="border-b-2 border-dashed border-[#EE3E45] my-3 mx-4 relative">
                                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                                </div>
                                <div className="p-[15px] pt-0">
                                    <h2 className={`text-2xl text-[${textColor}] font-body font-semibold text-center`} style={{ color: textColor }}>Contact Support</h2>
                                    <div className="flex items-center gap-2 mt-3">
                                        <button className="bg-white text-xs md:text-sm text-[#5A5A5A] border border-[#E7E7E7] rounded-[10px] py-2 md:py-3 px-3 md:px-4 w-full text-nowrap"><i class="fab fa-whatsapp text-black text-xl mr-1 md:mr-2 align-middle"></i> WhatsApp us</button>
                                        <button className="bg-white text-[#5A5A5A] border border-[#E7E7E7] rounded-[10px] text-xs md:text-sm py-2 md:py-3 px-3 md:px-4 w-full text-nowrap"><i class="fas fa-envelope text-black text-xl mr-1 md:mr-2 align-middle"></i> support@mepass.in</button>
                                    </div>
                                </div>
                                {!isEmpty(sponsorList) && (
                                    <SponserList sponsorList={sponsorList} textColor={textColor} />
                                )}
                                <div className="border-b-2 border-dashed border-[#EE3E45] my-3 mx-4 relative">
                                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                                </div>
                                <div className="px-[15px] pb-[20px]">
                                    <p className="text-lg font-normal text-[#1A1A1A] text-center">Download our App</p>
                                    <div className="grid grid-cols-2 gap-5 mt-4">
                                        <img src={img7} alt="img" className="inline-block w-full" />
                                        <img src={img6} alt="img" className="inline-block w-full" />
                                    </div>
                                    {/* <div className="grid grid-cols-2 gap-5 mt-4">
                                        <img src={img} alt="img" className="inline-block w-full" />
                                        <img src={img1} alt="img" className="inline-block w-full" />
                                    </div> */}
                                </div>
                                <div className="border-b-2 border-dashed border-[#EE3E45] my-3 mx-4 relative">
                                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-left-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                                    <span className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border-[3px] border-[#FFD685] before:bg-[#f4f4f4] before:-right-[3px] before:absolute before:w-[8px] before:h-[24px] box-border flex items-center p-1`}></span>
                                </div>
                                <div className="px-[15px] pb-[20px]">
                                    <div className="grid grid-cols-2 gap-7">
                                        <div>
                                            <h2 className={`text-sm text-[${textColor}] font-body font-normal text-center`} style={{ color: textColor }}>Technology Partner</h2>
                                            <Link to="https://www.bsptechno.com/" target="blanck">
                                                <div className="mt-3">
                                                    <img src={logo} alt="BSP-Technologies" />
                                                </div>
                                            </Link>
                                        </div>
                                        {/* <div>
                                            <h2 className={`text-sm text-[${textColor}] font-body font-normal text-center`} style={{ color: textColor }}>Ventured By</h2>
                                            <Link to="" target="blanck">
                                                <div className="mt-3">
                                                    <img src={logo2} alt="BSP-Technologies" />
                                                </div>
                                            </Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div >
            )}


            {/* ----------------------------------split-share-modal-start------------------- */}
            {
                showSplitModal ? (
                    <>
                        <div className="fixed z-30 overflow-y-auto top-0 w-full left-0">
                            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                <div className="fixed inset-0 transition-opacity">
                                    <div className="absolute inset-0 bg-gray-900 opacity-75" />
                                </div>
                                <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                                    &#8203;
                                </span>
                                <div
                                    className="inline-block align-center bg-white border border-[#4D4444] rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] xl:w-[35%]"
                                    role="dialog"
                                    aria-modal="true"
                                    aria-labelledby="modal-headline"
                                >
                                    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#a2a2a245] px-5 py-4">
                                        <div className="flex items-center justify-between gap-6">
                                            <h2 className="text-primary text-2xl font-body font-medium uppercase">
                                                Split & Share
                                            </h2>
                                            <button
                                                type="button"
                                                className="py-2 px-2"
                                                onClick={
                                                    () => {
                                                        toggleSplitModal()
                                                        setUrlString('')
                                                    }
                                                }
                                            >
                                                <i className="fas fa-times text-2xl"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="bg-white p-7">
                                        <form
                                            onSubmit={e => {
                                                e.preventDefault()
                                                validation.handleSubmit()
                                            }}
                                        >
                                            <h2 className="text-3xl font-body font-medium text-[#1C1C1C] text-center">Your balance is {passDetails.available_quantity}</h2>

                                            <input
                                                name='mobile'
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values['mobile'] || ""}
                                                className="py-3 px-3 block w-full border border-[##DCDCDC] font-body font-medium rounded-lg text-base text-[#131313] placeholder:text-[#131313] mt-7"
                                                placeholder={"Enter your mobile number"}
                                            />
                                            {validation.errors['mobile'] ? (
                                                <div className="text-red-500">{validation.errors['mobile']} </div>
                                            ) : null}
                                            <input
                                                name='confirm_mobile'
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values['confirm_mobile'] || ""}
                                                className="py-3 px-3 block w-full border border-[##DCDCDC] font-body font-medium rounded-lg text-base text-[#131313] placeholder:text-[#131313] mt-7"
                                                placeholder={"Enter your confirm mobile number"}
                                            />
                                            {validation.errors['confirm_mobile'] ? (
                                                <div className="text-red-500">{validation.errors['confirm_mobile']} </div>
                                            ) : null}
                                            <div className="flex items-center justify-center gap-7 mt-10">
                                                <p className="text-black text-xl font-body font-semibold">Add your QTY</p>
                                                <div className="w-[150px]">
                                                    <div className="flex items-center justify-between">
                                                        <button type="button" onClick={() => validation.values.count - 1 > 0 && validation.setFieldValue('count', validation.values.count - 1)} className="h-[44px] w-[44px] flex items-center justify-center rounded-md border bg-primary disabled:pointer-events-none ">
                                                            <i className="fas fa-minus text-white"></i>
                                                        </button>
                                                        <div className="bg-transparent w-[30px] border-0 outline-none text-center text-[#1C1C1C] text-4xl font-body font-medium" >
                                                            {validation.values.count}
                                                        </div>
                                                        <button onClick={
                                                            () => validation.values.count + 1 > 0 && validation.setFieldValue('count', validation.values.count + 1)

                                                        } type="button" className="h-[44px] w-[44px] flex items-center justify-center rounded-md border bg-primary disabled:pointer-events-none ">
                                                            <i className="fas fa-plus text-white"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {validation.errors['count'] ? (
                                                <div className="text-red-500">{validation.errors['count']} </div>
                                            ) : null}
                                            <button
                                                type="submit"
                                                disabled={validation.isSubmitting}
                                                className="bg-primary border border-primary px-8 py-4 rounded-lg text-white text-base font-body font-medium w-full uppercase tracking-[0.3rem] mt-10">
                                                Share now
                                            </button>

                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div >
                    </>
                ) : null
            }
            {/* ----------------------------------split-share-modal-end------------------- */}

            {/* ----------------------------------successfully-modal-start------------------- */}
            {
                showSuccessfullyModal ? (
                    <>
                        <div className="fixed z-30 overflow-y-auto top-0 w-full left-0">
                            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                <div className="fixed inset-0 transition-opacity">
                                    <div className="absolute inset-0 bg-gray-900 opacity-75" />
                                </div>
                                <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                                    &#8203;
                                </span>
                                <div
                                    className="inline-block align-center bg-white border border-[#4D4444] rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] xl:w-[35%]"
                                    role="dialog"
                                    aria-modal="true"
                                    aria-labelledby="modal-headline"
                                >
                                    <div className="bg-white p-7 ">
                                        <h2 className="text-2xl font-body font-medium text-primary text-center uppercase">Your passes are shared <br />successfully</h2>
                                        <img src={succesfully} alt="img" className="block m-auto mt-10" />
                                        <button
                                            onClick={
                                                () => {
                                                    setShowSuccessfullyModal(false)
                                                    setTimeout(() => {
                                                        window.location.reload()
                                                    }, 700)
                                                }
                                            }
                                            className="bg-primary border border-primary px-8 py-4 rounded-lg text-white text-base font-body font-medium w-full uppercase tracking-[0.3rem] mt-10">
                                            ok
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </>
                ) : null
            }
            {/* ----------------------------------successfully-modal-end------------------- */}

            {/* ----------------------------------Tearms-modal-start------------------- */}
            {
                showTermsModal ? (
                    <>
                        <div className="fixed z-30 overflow-y-auto top-0 w-full left-0">
                            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                <div className="fixed inset-0 transition-opacity">
                                    <div className="absolute inset-0 bg-gray-900 opacity-75" />
                                </div>
                                <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                                    &#8203;
                                </span>
                                <div
                                    className="inline-block align-center bg-white border border-[#4D4444] rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] xl:w-[35%]"
                                    role="dialog"
                                    aria-modal="true"
                                    aria-labelledby="modal-headline"
                                >
                                    <div className="bg-white p-7 ">
                                        {/* <p className={`text-2xl font-body font-medium text-primary text-center uppercase ${passDetails && passDetails.event_pass && passDetails.event_pass.notes && passDetails.event_pass.notes.includes('<ul>') ? "bullet-point" : passDetails.event_pass && passDetails.event_pass.notes && passDetails.event_pass.notes.includes('<ol>') ? "number-point" : ""}`}
                                            dangerouslySetInnerHTML={
                                                {
                                                    __html: passDetails.event_pass.notes
                                                }
                                            }> </p> */}

                                        <div className={`${passDetails && passDetails.event_pass && passDetails.event_pass.notes && passDetails.event_pass.notes.includes('<ul>') ? "bullet-point" : passDetails && passDetails.event_pass && passDetails.event_pass.notes && passDetails.event_pass.notes.includes('<ol>') ? "number-point" : ""}`}
                                            dangerouslySetInnerHTML={{ __html: passDetails.event_pass.notes }} />
                                        <button
                                            onClick={
                                                () => {
                                                    setShowTermsModal(false)
                                                }
                                            }
                                            className="bg-primary border border-primary px-8 py-4 rounded-lg text-white text-base font-body font-medium w-full uppercase tracking-[0.3rem] mt-10">
                                            ok
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </>
                ) : null
            }
            {/* ----------------------------------successfully-modal-end------------------- */}


            {/* ----------------------------------popup-modal-start------------------- */}
            {
                showPopupModal ? (
                    <>
                        <div className="fixed z-30 overflow-y-auto top-[50%] origin-center w-full left-[50%] -translate-y-1/2 -translate-x-1/2">
                            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                <div className="fixed inset-0 transition-opacity ">
                                    <div className="absolute inset-0 bg-[#000000B2]" />
                                </div>

                                <div
                                    className="inline-block align-center rounded-lg text-left overflow-hidden transform transition-all sm:align-middle w-full md:w-[80%] xl:w-[21%] mt-5"
                                    role="dialog"
                                    aria-modal="true"
                                    aria-labelledby="modal-headline"
                                >

                                    <div className="text-center mt-14">
                                        <div className="flex items-center justify-end mb-4">
                                            <button
                                                type="button"
                                                className="py-2 px-2 flex items-center justify-center bg-white h-[30px] w-[30px] rounded-full"
                                                onClick={
                                                    () => {
                                                        setIsShowPopupModal(false)
                                                    }
                                                }
                                            >
                                                <i className="fas fa-times text-xl"></i>
                                            </button>
                                        </div>
                                        <Link to={popupList.promotional_link ? popupList.promotional_link : '#'} target="_blank"
                                            rel="noopener noreferrer" >
                                            <img src={popupList.image} alt="img" className="inline-block" />
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </>
                ) : null
            }

        </>

    );
};

export default withRouter(DandiyaQrPass);

