import React, { useState } from "react";
import banner from "../../assets/light_theme_images/blog-detail.png";
import blogItmes from "../../assets/light_theme_images/blog-detail-items.png";
import blogItem from "../../assets/light_theme_images/blog-item.png";
import blogItem1 from "../../assets/light_theme_images/blog-item1.png";
import blogItem2 from "../../assets/light_theme_images/blog-item2.png";
import blogItem3 from "../../assets/light_theme_images/blog-item3.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  FreeMode,
  Keyboard,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
function OurBlogDetails() {
  const [activeTab, setActiveTab] = useState("tab1");

  const tabs = [
    { id: "tab1", label: "Tab 1", content: "Content of Tab 1" },
    { id: "tab2", label: "Tab 2", content: "Content of Tab 2" },
    { id: "tab3", label: "Tab 3", content: "Content of Tab 3" },
  ];
  return (
    <>
      <section className="bg-white pt-14 pb-24">
        <div className="container">
          <div className="flex flex-col text-center gap-6">
            <h2 className="text-[#101010] text-4xl font-semibold">
              How to Build a Powerful Ticket Booking Platform
            </h2>
            <div className="flex items-center justify-center gap-4">
              <p className="text-[#CB2129] text-base font-medium">
                November 14, 2024
              </p>
              <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
              <p className="text-[#CB2129] text-base font-medium">7 min read</p>
            </div>
            <img src={banner} alt="img" className="rounded-2xl" />
          </div>
          <div className="grid grid-cols-3 gap-10 mt-10">
            <div className="col-span-2">
              {activeTab === "tab1" && (
                <>
                  <p className="text-[#101010] text-lg font-medium">
                    Creating a robust ticket booking platform is not just about
                    selling tickets—it’s about crafting an intuitive and
                    engaging experience for your users. Whether you're targeting
                    concerts, conferences, sports events, or virtual gatherings,
                    the success of your platform lies in its ability to cater to
                    both event organizers and attendees with ease. This guide
                    will walk you through the essential steps to build a ticket
                    booking platform that’s reliable, scalable, and
                    user-friendly.
                  </p>
                  <div className="mt-5">
                    <h3 className="text-[#101010] text-2xl font-semibold">
                      Steps to Build the Platform
                    </h3>
                    <ol className="mt-3 flex flex-col gap-1.5 list-decimal pl-[20px]">
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Understand Your Target Audience: </b>Before diving
                          into development, research your target users. Are you
                          catering to large-scale music festivals or intimate
                          workshops? Understanding your audience will help you
                          design a platform tailored to their preferences.
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Choose the Right Technology Stack: </b> Select a
                          technology stack that supports scalability and
                          performance
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>ntegrate Advanced Analytics: </b>Embed analytics
                          tools to track user behavior, sales trends, and
                          marketing performance. These insights can guide your
                          strategies for future improvements.
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Focus on Scalability: </b> Ensure your platform can
                          handle high traffic, especially during ticket launches
                          for popular events. Cloud hosting solutions like AWS
                          or Azure offer scalable infrastructure.
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Test Rigorously: </b> Conduct thorough testing to
                          eliminate bugs and ensure seamless performance. Beta
                          testing with a small group of users can provide
                          valuable feedback.
                        </p>
                      </li>
                    </ol>
                  </div>
                  <img
                    src={blogItmes}
                    alt="img"
                    className="rounded-[12px] mt-8"
                  />
                  <div className="mt-5">
                    <h3 className="text-[#101010] text-2xl font-semibold">
                      The Benefits of Building Your Own Ticketing Platform
                    </h3>
                    <ol className="mt-3 flex flex-col gap-1.5 list-disc pl-[20px]">
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Enhanced Branding: </b>Your platform reflects your
                          brand identity, creating a consistent experience for
                          users.
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Increased Revenue: </b> By bypassing third-party
                          platforms, you save on commission fees and retain more
                          revenue.
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Customer Insights: </b>Direct access to user data
                          helps you understand your audience better and refine
                          your offerings.
                        </p>
                      </li>
                    </ol>
                  </div>
                  <div className="mt-5">
                    <h3 className="text-[#101010] text-2xl font-semibold">
                      Conclusion
                    </h3>
                    <p className="text-[#101010] text-base font-medium mt-3">
                      Building a powerful ticket booking platform is a
                      combination of innovative design, reliable technology, and
                      user-focused features. By prioritizing security, ease of
                      use, and scalability, you can create a platform that meets
                      the needs of both event organizers and attendees, ensuring
                      long-term success.
                    </p>
                  </div>
                </>
              )}
              {activeTab === "tab2" && (
                <>
                  <p className="text-[#101010] text-lg font-medium">
                    Creating a robust ticket booking platform is not just about
                    selling tickets—it’s about crafting an intuitive and
                    engaging experience for your users. Whether you're targeting
                    concerts, conferences, sports events, or virtual gatherings,
                    the success of your platform lies in its ability to cater to
                    both event organizers and attendees with ease. This guide
                    will walk you through the essential steps to build a ticket
                    booking platform that’s reliable, scalable, and
                    user-friendly.
                  </p>
                  <div className="mt-5">
                    <h3 className="text-[#101010] text-2xl font-semibold">
                      Steps to Build the Platform
                    </h3>
                    <ol className="mt-3 flex flex-col gap-1.5 list-decimal pl-[20px]">
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Understand Your Target Audience: </b>Before diving
                          into development, research your target users. Are you
                          catering to large-scale music festivals or intimate
                          workshops? Understanding your audience will help you
                          design a platform tailored to their preferences.
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Choose the Right Technology Stack: </b> Select a
                          technology stack that supports scalability and
                          performance
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>ntegrate Advanced Analytics: </b>Embed analytics
                          tools to track user behavior, sales trends, and
                          marketing performance. These insights can guide your
                          strategies for future improvements.
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Focus on Scalability: </b> Ensure your platform can
                          handle high traffic, especially during ticket launches
                          for popular events. Cloud hosting solutions like AWS
                          or Azure offer scalable infrastructure.
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Test Rigorously: </b> Conduct thorough testing to
                          eliminate bugs and ensure seamless performance. Beta
                          testing with a small group of users can provide
                          valuable feedback.
                        </p>
                      </li>
                    </ol>
                  </div>
                  <img
                    src={blogItmes}
                    alt="img"
                    className="rounded-[12px] mt-8"
                  />
                  <div className="mt-5">
                    <h3 className="text-[#101010] text-2xl font-semibold">
                      The Benefits of Building Your Own Ticketing Platform
                    </h3>
                    <ol className="mt-3 flex flex-col gap-1.5 list-disc pl-[20px]">
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Enhanced Branding: </b>Your platform reflects your
                          brand identity, creating a consistent experience for
                          users.
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Increased Revenue: </b> By bypassing third-party
                          platforms, you save on commission fees and retain more
                          revenue.
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Customer Insights: </b>Direct access to user data
                          helps you understand your audience better and refine
                          your offerings.
                        </p>
                      </li>
                    </ol>
                  </div>
                  <div className="mt-5">
                    <h3 className="text-[#101010] text-2xl font-semibold">
                      Conclusion
                    </h3>
                    <p className="text-[#101010] text-base font-medium mt-3">
                      Building a powerful ticket booking platform is a
                      combination of innovative design, reliable technology, and
                      user-focused features. By prioritizing security, ease of
                      use, and scalability, you can create a platform that meets
                      the needs of both event organizers and attendees, ensuring
                      long-term success.
                    </p>
                  </div>
                </>
              )}
              {activeTab === "tab3" && (
                <>
                  <p className="text-[#101010] text-lg font-medium">
                    Creating a robust ticket booking platform is not just about
                    selling tickets—it’s about crafting an intuitive and
                    engaging experience for your users. Whether you're targeting
                    concerts, conferences, sports events, or virtual gatherings,
                    the success of your platform lies in its ability to cater to
                    both event organizers and attendees with ease. This guide
                    will walk you through the essential steps to build a ticket
                    booking platform that’s reliable, scalable, and
                    user-friendly.
                  </p>
                  <div className="mt-5">
                    <h3 className="text-[#101010] text-2xl font-semibold">
                      Steps to Build the Platform
                    </h3>
                    <ol className="mt-3 flex flex-col gap-1.5 list-decimal pl-[20px]">
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Understand Your Target Audience: </b>Before diving
                          into development, research your target users. Are you
                          catering to large-scale music festivals or intimate
                          workshops? Understanding your audience will help you
                          design a platform tailored to their preferences.
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Choose the Right Technology Stack: </b> Select a
                          technology stack that supports scalability and
                          performance
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>ntegrate Advanced Analytics: </b>Embed analytics
                          tools to track user behavior, sales trends, and
                          marketing performance. These insights can guide your
                          strategies for future improvements.
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Focus on Scalability: </b> Ensure your platform can
                          handle high traffic, especially during ticket launches
                          for popular events. Cloud hosting solutions like AWS
                          or Azure offer scalable infrastructure.
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Test Rigorously: </b> Conduct thorough testing to
                          eliminate bugs and ensure seamless performance. Beta
                          testing with a small group of users can provide
                          valuable feedback.
                        </p>
                      </li>
                    </ol>
                  </div>
                  <img
                    src={blogItmes}
                    alt="img"
                    className="rounded-[12px] mt-8"
                  />
                  <div className="mt-5">
                    <h3 className="text-[#101010] text-2xl font-semibold">
                      The Benefits of Building Your Own Ticketing Platform
                    </h3>
                    <ol className="mt-3 flex flex-col gap-1.5 list-disc pl-[20px]">
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Enhanced Branding: </b>Your platform reflects your
                          brand identity, creating a consistent experience for
                          users.
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Increased Revenue: </b> By bypassing third-party
                          platforms, you save on commission fees and retain more
                          revenue.
                        </p>
                      </li>
                      <li>
                        <p className="text-[#101010] text-base font-medium">
                          <b>Customer Insights: </b>Direct access to user data
                          helps you understand your audience better and refine
                          your offerings.
                        </p>
                      </li>
                    </ol>
                  </div>
                  <div className="mt-5">
                    <h3 className="text-[#101010] text-2xl font-semibold">
                      Conclusion
                    </h3>
                    <p className="text-[#101010] text-base font-medium mt-3">
                      Building a powerful ticket booking platform is a
                      combination of innovative design, reliable technology, and
                      user-focused features. By prioritizing security, ease of
                      use, and scalability, you can create a platform that meets
                      the needs of both event organizers and attendees, ensuring
                      long-term success.
                    </p>
                  </div>
                </>
              )}
            </div>
            <div>
              {/* Tab Headers */}
              <div style={{ display: "flex", borderBottom: "1px solid #ddd" }}>
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                    style={{
                      padding: "10px 20px",
                      cursor: "pointer",
                      border: "none",
                      borderBottom:
                        activeTab === tab.id ? "2px solid blue" : "none",
                      background: "transparent",
                      color: activeTab === tab.id ? "blue" : "black",
                    }}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-10">
            <h2 className="text-2xl md:text-3xl font-semibold text-[#101010] mb-5">
              Recent Blog
            </h2>
            <Swiper
              cssMode={true}
              mousewheel={true}
              keyboard={true}
              slidesPerView={3}
              spaceBetween={40}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
              className="mySwiper gallary-slider blog-slider !pb-14"
            >
              <SwiperSlide>
                <img src={blogItem} alt="img" className="rounded-xl" />
                <div className="flex flex-col gap-2.5 mt-3">
                  <h2 className="text-[#101010] text-2xl font-semibold">
                    Virtual Event Ticketing: 5 Tips for Success
                  </h2>
                  <p className="text-[#101010] text-base font-normal">
                    Learn how to seamlessly manage ticketing for virtual events
                    and maximize attendance with our expert tips.
                  </p>
                  <div className="flex items-center gap-4">
                    <p className="text-[#737373] text-base font-medium">
                      November 13, 2024
                    </p>
                    <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                    <p className="text-[#737373] text-base font-medium">
                      5 min read
                    </p>
                  </div>
                  <div>
                    <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                      Read Article
                    </button>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={blogItem1} alt="img" className="rounded-xl" />
                <div className="flex flex-col gap-2.5 mt-3">
                  <h2 className="text-[#101010] text-2xl font-semibold">
                    Book Now, Sell Anytime: Your Ticketing Solution
                  </h2>
                  <p className="text-[#101010] text-base font-normal">
                    Explore how flexible and secure ticketing systems can help
                    you sell tickets around the clock. A mobile phone showing a
                    live event ticket sale...
                  </p>
                  <div className="flex items-center gap-4">
                    <p className="text-[#737373] text-base font-medium">
                      November 12, 2024
                    </p>
                    <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                    <p className="text-[#737373] text-base font-medium">
                      4 min read
                    </p>
                  </div>
                  <div>
                    <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                      Read Article
                    </button>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={blogItem2} alt="img" className="rounded-xl" />
                <div className="flex flex-col gap-2.5 mt-3">
                  <h2 className="text-[#101010] text-2xl font-semibold">
                    Behind the Curtain: Inside the Event Industry
                  </h2>
                  <p className="text-[#101010] text-base font-normal">
                    Take a deep dive into the behind-the-scenes efforts that
                    make events successful. A backstage view of a concert or
                    conference...
                  </p>
                  <div className="flex items-center gap-4">
                    <p className="text-[#737373] text-base font-medium">
                      November 10, 2024
                    </p>
                    <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                    <p className="text-[#737373] text-base font-medium">
                      7 min read
                    </p>
                  </div>
                  <div>
                    <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                      Read Article
                    </button>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={blogItem3} alt="img" className="rounded-xl" />
                <div className="flex flex-col gap-2.5 mt-3">
                  <h2 className="text-[#101010] text-2xl font-semibold">
                    Behind the Curtain: Inside the Event Industry
                  </h2>
                  <p className="text-[#101010] text-base font-normal">
                    Take a deep dive into the behind-the-scenes efforts that
                    make events successful. A backstage view of a concert or
                    conference...
                  </p>
                  <div className="flex items-center gap-4">
                    <p className="text-[#737373] text-base font-medium">
                      November 10, 2024
                    </p>
                    <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                    <p className="text-[#737373] text-base font-medium">
                      7 min read
                    </p>
                  </div>
                  <div>
                    <button className="text-[#CB2129] text-base font-semibold border border-[#CB2129] py-2 px-4 rounded-lg inline-block">
                      Read Article
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurBlogDetails;
