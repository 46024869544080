import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import api from "../../constants/api";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import { convertToAmPm, convertToDate } from "../../common/commonFunction";
import img from "../../assets/light_theme_images/eventimg.png";
import zoomIcon from "../../assets/light_theme_images/zoom-icon.svg";
import modalImg from "../../assets/light_theme_images/purchase-modal-img.png";
import bannerImageDefault from "../../assets/images/default/event-image.jpg";

const EventDetails = ({ event, eventDates }) => {
  const [showMasterSettingModal, setShowMasterSettingModal] = useState(false);
  const modalRef = useRef(null);

  const toggleMasterSettingModal = () => {
    setShowMasterSettingModal(!showMasterSettingModal);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowMasterSettingModal(false);
    }
  };

  useEffect(() => {
    if (showMasterSettingModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMasterSettingModal]);

  const eventDateDisplay = () => {
    if (eventDates && eventDates[0]) {
      //First and Last Array Date not Same then Display Date
      if (eventDates.length > 1) {
        if (
          eventDates[0]["date"] !=
          eventDates[eventDates.length - 1]["date"]
        ) {
          //First and Last Array Date in Year Same then only Date and month Display Remove Year
          if (
            eventDates[0]["date"].split("-")[2] ==
            eventDates[eventDates.length - 1][
              "date"
            ].split("-")[2]
          ) {
            let firstDate = convertToDate(eventDates[0]["date"]);
            let lastDate = convertToDate(
              eventDates[eventDates.length - 1]["date"]
            );
            return (
              firstDate.split(",")[0] +
              ", " +
              firstDate.split(",")[1] +
              " - " +
              lastDate
            );
          } else {
            return (
              convertToDate(eventDates[0]["date"]) +
              " - " +
              convertToDate(
                eventDates[eventDates.length - 1]["date"]
              )
            );
          }
        } else {
          return convertToDate(eventDates[0]["date"]);
        }
      } else {
        return convertToDate(eventDates[0]["date"]);
      }
    }
  };
  return (
    <>
      <div className="flex">
        <div className="flex-none mr-5">
          <div className="w-[120px] md:w-[200px] h-[120px] md:h-[200px]">
            <img src={event.banner_image ? event.banner_image : bannerImageDefault} className="rounded-lg aspect-[1/1]" />
          </div>
        </div>
        <div className="flex-1">
          <div className="block md:flex gap-3 h-full">
            <div className="flex-1 h-full">
              <div className="grid grid-flow-row grid-row-6 h-full">
                <div className="row-span-5">
                  <h2 className="text-xl lg:text-5xl text-[#101010] font-bold">
                    {event.name ? event.name : ""}
                  </h2>
                  <p className="text-[#101010] text-lg font-medium mt-1">
                    {event.tagline ? event.tagline : ""}
                  </p>
                </div>
                <div className="mt-5 lg:mt-0">
                  <p className="text-[#101010] text-sm md:text-base font-body font-normal">
                    {(event.address || event.city) &&
                      < i className="fas fa-map-marker-alt text-[#CB2129] mr-2"></i>
                    }
                    {event.address ? event.address : ""}
                    {event.address ? ',' : ""}
                    {event.city ? event.city : ""}
                  </p>
                  <div className="block lg:flex items-center mt-2 lg:mt-3 gap-6">
                    <p className="text-[#101010] text-sm md:text-base font-body font-normal">
                      <i className="fa-solid fa-calendar text-[#CB2129] mr-2"></i>
                      {eventDateDisplay()}
                    </p>
                    <p className="text-[#101010] text-sm md:text-base font-body font-normal mr-2 mt-2 lg:mt-0">
                      {event.start_time ? <i className="fas fa-clock text-[#CB2129] mr-2"></i> : ""}
                      {event.start_time ? convertToAmPm(event.start_time) : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {event.event_popup_image ? (
              <div className="flex-none">
                <div className="hidden md:block">
                  <div className="w-[200px] h-[200px] relative">
                    <img src={event.event_popup_image} className="rounded-lg aspect-[1/1]" />
                    <div
                      onClick={toggleMasterSettingModal}
                      className="absolute bottom-2 right-2 bg-[#CB2129] size-8 rounded-[4px] flex items-center justify-center cursor-pointer"
                    >
                      <img src={zoomIcon} />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div >
      {
        event.event_popup_image ? (
          <div className="block md:hidden relative mt-5">
            <img src={event.event_popup_image} className="w-full" />
            <div
              onClick={toggleMasterSettingModal}
              className="absolute bottom-2 right-2 bg-[#CB2129] size-8 rounded-[4px] flex items-center justify-center cursor-pointer"
            >
              <img src={zoomIcon} />
            </div>
          </div>
        ) : null
      }
      {
        showMasterSettingModal ? (
          <div>
            <div className="fixed z-30 overflow-y-auto top-0 w-full left-0">
              <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                  &#8203;
                </span>
                <div
                  ref={modalRef}
                  className="inline-block align-center text-left overflow-hidden transform transition-all sm:align-middle w-full md:w-[90%] xl:w-[50%]"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <div className="flex justify-center items-center p-5  md:p-10">
                    <img src={modalImg} alt="img" className="aspect-[25/24]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null
      }
    </>
  );
};
EventDetails.propTypes = {
  event: PropTypes.object,
};
export default EventDetails;
