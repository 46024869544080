import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import api from "../../constants/api";
import { addUpdateNewData, getData } from "../../store/AppMaster/actions";
import { convertToAmPm } from "../../common/commonFunction";

const SelectPass = ({ pass, handleIncrement, handleDecrement }) => {
  return (
    <>
      <div className="bg-white p-5 rounded-lg mt-5">
        <div className="flex gap-2">
          <div className="flex-1">
            <h3 className="text-lg md:text-3xl text-[#CB2129] font-semibold">
              {pass.name}
            </h3>
          </div>
          <div className="flex-none">
            {pass.quantity && pass.quantity > 0 ? (
              <>
                <div className="btn-liner-gradient text-white py-2 px-4 rounded-lg">
                  <div className="relative flex items-center gap-3">
                    <button
                      type="button"
                      onClick={() => handleDecrement(pass.id, pass.quantity)}
                      className="text-white"
                    >
                      <i className="fas fa-minus"></i>
                    </button>
                    <input
                      type="text"
                      id="quantity-input"
                      value={pass.quantity ? pass.quantity : 0}
                      className="w-[20px] h-[20px] flex items-center justify-center text-center text-2xl font-body font-normal text-white bg-transparent focus-visible:border-0 focus-visible:outline-0"
                      placeholder="999"
                      required
                      readOnly
                    />
                    <button
                      type="button"
                      onClick={() => handleIncrement(pass.id, pass.quantity)}
                      className=" text-white"
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </>

            ) : (
              <button type="button"
                onClick={() => handleIncrement(pass.id, pass.quantity)}
                className="border border-[#CB2129]  text-[#CB2129] text-base md:text-lg font-semibold py-1 md:py-2 px-3 rounded-lg w-[97px]">
                Add
              </button>
            )}
          </div>
        </div>
        <div>
          <div className="flex mt-2">
            {pass.amount > 0 && pass.amount != pass.amount_after_discount ? (
              <h5 className="text-sm md:text-lg font-medium text-[#101010] mr-3">
                <del>₹ {pass.amount}</del>
              </h5>
            ) : (
              ""
            )}
            <span className="text-lg md:text-3xl text-[#101010] font-body font-bold">
              ₹{pass.amount_after_discount}
            </span>
          </div>
          {pass.percentage > 0 && (
            <p className="text-sm md:text-lg font-medium text-[#388e3c]">
              {pass.percentage}% off
            </p>
          )}
        </div>
        <div className="border-b-2 border-[#E7E7E7] my-3"></div>
        <p
          className={`text-black w-full lg:w-[88%] ${pass.notes && pass.notes.includes("<ul>")
            ? "bullet-point"
            : pass.notes && pass.notes.includes("<ol>")
              ? "number-point"
              : ""
            }`}
          dangerouslySetInnerHTML={{
            __html: pass.notes,
          }}
        ></p>
      </div>
    </>
  );
};
SelectPass.propTypes = {
  pass: PropTypes.object,
};
export default SelectPass;
