
import React from "react";
import { Link} from "react-router-dom";
import logo from "../../assets/images/logo.png";
const BusinessHeader = props => {

    return (
        <React.Fragment>
            <div className="position-relative">
                <nav className="bg-gradient-to-t from-[#00000000] to-[#00000099]  absolute top-0 w-full">
                    <div className="container mx-auto py-[15px] ">
                        <div className="flex items-center justify-between gap-4">
                            <div className="flex items-center gap-0 md:gap-16">
                                <div className='w-full md:w-[163.63px]'>
                                    <Link to="/home">
                                    <img className="w-auto" src={logo} alt="Your Company" />
                                    </Link>
                                </div>

                            </div>
                            <button className="bg-primary border border-primary md:px-8 px-4 py-2 md:py-5 rounded-[5px] text-white text-base md:text-lg font-body font-medium tracking-[0.1rem] md:tracking-[0.3rem] uppercase">Join as organiser</button>
                        </div>
                    </div>

                </nav>
            </div>

        </React.Fragment>
    );
};

export default BusinessHeader;
