
import { useFormik } from "formik"
import { isEmpty } from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import withRouter from "../../components/Common/withRouter"
import LoginPage from "../../components/Login/Login"
import api from "../../constants/api"
import { addUpdateNewData, getData, loginUser, clearData } from "../../store/AppMaster/actions"
import SeoDetails from "../../seo/SeoDetails";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { messaging, getToken } from "../../firebase";
import { GoogleOAuthProvider } from "@react-oauth/google";
const Login = props => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const { data } = useSelector(state => ({
        data: state.AppMaster.data,
    }))
    const { loading } = useSelector(state => ({
        loading: state.AppMaster.loading,
    }))

    const dispatch = useDispatch()
    const [userDetails, setUserDetails] = useState('')
    const [mobileNumber, setMobileNumber] = useState("")
    const [isOtpSent, setIsOtpSent] = useState(false)
    const [isApiCall, setIsApiCall] = useState(0);
    const [cmsData, setCmsData] = useState("");
    const [isCmsData, setIsCmsData] = useState(false);
    const [recaptchaResponse, setRecaptchaResponse] = useState("")
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
    const [isCheckMobile, setIsCheckMobile] = useState(false)
    const [fcmToken, setFcmToken] = useState("")
    const { executeRecaptcha } = useGoogleReCaptcha();
    const handleReCaptchaVerifyOnSubmit = async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return '';
        }

        const token = await executeRecaptcha('submit');
        return token;
    };
    const requestNotificationPermission = async () => {
        try {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                const token = await getToken(messaging, {
                    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
                });
                console.log("FCM Token:", token);
                setFcmToken(token);
                // Save the token to your backend or use it as needed
            } else {
                console.log("Notification permission denied");
            }
        } catch (error) {
            console.error("Error getting notification token:", error);
        }
    };

    // Call this function, e.g., on component mount
    useEffect(() => {
        requestNotificationPermission();
    }, []);

    const [location, setLocation] = useState({ city: '', state: '', country: '', latitude: "", longitude: "" });
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchLocation = async () => {
            try {
                // Fetch location data from a free API
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                if (data.city && data.country_name) {
                    setLocation({
                        city: data.city ? data.city : '',
                        state: data.region ? data.region : '',
                        country: data.country_name ? data.country_name : '',
                        latitude: data.latitude ? data.latitude : '',
                        longitude: data.longitude ? data.longitude : '',
                    });
                } else {
                    setError('Could not retrieve location.');
                }
            } catch (err) {
                setError('Failed to fetch location.');
            }
        };

        fetchLocation();
    }, []);

    //CMS Data
    const CmsPage = useCallback(() => {
        setIsCmsData(true);
        let requestCmsData = {
            section_titles: "trending",
            is_page: "Event",
        };
        dispatch(clearData());
        dispatch(getData(requestCmsData, props.router.navigate, api.CmsPage));
    }, [dispatch, props.router.navigate]);

    useEffect(() => {
        if (isApiCall === 0) {
            CmsPage();
            setIsApiCall(1);
        }
    }, [isApiCall, CmsPage]);


    useEffect(() => {
        if (
            !isEmpty(data) &&
            !isEmpty(data.cmsPage) &&
            !isEmpty(data.cmsPage.trending) &&
            isCmsData
        ) {
            setCmsData(data.cmsPage.trending);
            setIsCmsData(false);
        }
    }, [data, isCmsData]);


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            mobile: "",
        },
        validationSchema: Yup.object({
            mobile: Yup.string()
                .required("Please Enter Your Mobile")
                .length(10, "Mobile number should be exactly 10 digits"),
        }),
        onSubmit: values => {
            // setIsCheckMobile(true)
            // const checkEmail = {
            //     mobile: values.mobile,
            // }
            setIsOtpSent(true)
            setMobileNumber(values.mobile)
            dispatch(getData({ mobile: values.mobile }, "", api.sendOtp))
            //   dispatch(getData(checkEmail, props.router.navigate, api.checkMobile))
        },
    })

    // if (!isEmpty(data) && data.STATUS_CODE === 200 && !isOtpSent && isCheckMobile) {
    //     setIsOtpSent(true)
    //     setIsCheckMobile(false)
    //     dispatch(getData({ mobile: mobileNumber }, "", api.sendOtp))
    // }

    // if (!isEmpty(data) && data.STATUS_CODE === 400 && !isOtpSent && isCheckMobile) {
    //     validation.setFieldError("mobile", data.MESSAGE || "Unknown error");
    //     setIsCheckMobile(false);
    // }


    const validationOtp = useFormik({
        enableReinitialize: true,

        initialValues: {
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
            mobile: mobileNumber,
        },
        validationSchema: Yup.object({
            otp1: Yup.string().required("Please Enter Your OTP"),
            otp2: Yup.string().required("Please Enter Your OTP"),
            otp3: Yup.string().required("Please Enter Your OTP"),
            otp4: Yup.string().required("Please Enter Your OTP"),
            otp5: Yup.string().required("Please Enter Your OTP"),
            otp6: Yup.string().required("Please Enter Your OTP"),
        }),
        onSubmit: async values => {
            const newToken = await handleReCaptchaVerifyOnSubmit();
            const requestData = {
                mobile: validation.values.mobile,
                otp:
                    values.otp1 +
                    values.otp2 +
                    values.otp3 +
                    values.otp4 +
                    values.otp5 +
                    values.otp6,
                gRecaptchaResponse: newToken,
                push_notification_id: fcmToken,
                device_type: "web",
                city: location && location.city ? location.city : "",
                state: location && location.state ? location.state : "",
                country: location && location.country ? location.country : "",
                latitude: location && location.latitude ? location.latitude : "",
                longitude: location && location.longitude ? location.longitude : "",
            }
            dispatch(loginUser(requestData, props.router.navigate, api.login))
        },
    })

    const resendOtp = () => {
        dispatch(addUpdateNewData({ mobile: mobileNumber }, "", api.sendOtp))
    }

    if (!loading && validation.isSubmitting) {
        validation.isSubmitting = false
    }
    if (!loading && validationOtp.isSubmitting) {
        validationOtp.isSubmitting = false
    }

    if (!isEmpty(data) && !isEmpty(data.userDetails) && isEmpty(userDetails)) {
        setUserDetails(data.userDetails)
        if (props.router.location.state && props.router.location.state.previousPage == "Login") {
            props.router.navigate('/home')
        } else {
            props.router.navigate(-1)
        }

    }
    return (
        <>
            <SeoDetails title="Mepass - Login"
                description="Mepass - Login"
            />
            <GoogleOAuthProvider clientId={clientId}>
                <LoginPage
                    validation={validation}
                    isOtpSent={isOtpSent}
                    validationOtp={validationOtp}
                    resendOtp={resendOtp}
                    recaptchaResponse={recaptchaResponse}
                    props={props}
                    cmsData={cmsData}
                    loading={loading}
                />
            </GoogleOAuthProvider>,

        </>
    );
}

export default withRouter(Login)