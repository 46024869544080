import React from "react";
import withRouter from "../../components/Common/withRouter";
import img from "../../assets/light_theme_images/404-img.png";
import { Link } from "react-router-dom";

const Page404 = (props) => {
  React.useEffect(() => {
    const timer = setTimeout(() => {
      props.router.navigate("/");
    }, 30000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div className="container">
        <div className="text-center py-24">
          <img src={img} alt="404" className="inline-block" />
          <h2 className="text-[#101010] text-lg md:text-3xl font-semibold w-[257px] md:w-[421px] m-auto mt-9">
            Uh-oh! This page is missing. Head back to safety!
          </h2>
          <Link
            to={"/"}
            className="btn-liner-gradient text-white text-sm lg:text-base text-center font-medium py-[6px] lg:py-[10px] px-2  md:px-3 lg:px-7 rounded-lg duration-1000 ease-in-out h-[48px] flex items-center justify-center mt-8 w-[152px] m-auto"
          >
            Go to Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default withRouter(Page404);
