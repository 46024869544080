import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { authProtectedRoutes, publicRoutes, publicRoutesWithoutHeader, publicRoutesWithoutHeaderFooter } from "./routes";
import { isEmpty } from "lodash"
// Import layouts and middleware
import { onMessage } from "firebase/messaging";
import TagManager from 'react-gtm-module';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/App.css";
import { redirectToNewUrl } from "./common/commonFunction";
import Layout from "./components/Layout/";
import LayoutWithoutHeader from "./components/LayoutWithoutHeader/";
import LayoutWithoutHeaderFooter from "./components/LayoutWithoutHeaderFooter/";
import { messaging } from "./firebase";
import Authmiddleware from "./routes/route";

const domain = window.location.hostname;

const App = () => {
  // Initialize Google Tag Manager for specific domains
  if (domain === "www.mepass.in" || domain === "mepass.in") {
    const tagManagerArgs = {
      gtmId: 'GTM-WWKL7MMC'
    };
    TagManager.initialize(tagManagerArgs);
  }

  //firebase messaging
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      if (payload) {
        console.log("Message received:", payload);

        const unreadCount = payload.data?.unread_notification_count || 0;
        const redirectionUrl = payload.data?.redirection_url || `${domain}/notifications`;

        localStorage.setItem(
          "unread_notification_count",
          JSON.stringify(unreadCount)
        );
        console.log("Unread count updated:", unreadCount);

        setTimeout(() => {
          window.location.href = redirectionUrl;
        }, 100);
      } else {
        console.log("No payload received.");
      }
    });

    return unsubscribe;
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <Routes>
        {redirectToNewUrl()}
        {publicRoutesWithoutHeader.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <LayoutWithoutHeader>
                {route.component}
              </LayoutWithoutHeader>
            }
            key={idx}
            exact={true}
          />
        ))}
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Layout>
                {route.component}
              </Layout>
            }
            key={idx}
            exact={true}
          />
        ))}
        {publicRoutesWithoutHeaderFooter.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <LayoutWithoutHeaderFooter>
                {route.component}
              </LayoutWithoutHeaderFooter>
            }
            key={idx}
            exact={true}
          />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>}
            key={idx}
            exact={true}
          />
        ))}


        {/* Handle the 301 redirects */}
        <Route path="/index.html" element={<Navigate replace to="/" state="301" />} />
        <Route path="/home" element={<Navigate replace to="/" state="301" />} />

        {/* Fallback for all other routes */}
        <Route path="*" element={<Navigate replace to="/page-404" state="301" />} />
      </Routes>
    </React.Fragment>
  );
};

export default App;