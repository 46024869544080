
import React, { useEffect, useRef } from 'react';
import comingsoon from '../../assets/images/coming-soon.png'
import video from '../../assets/images/video/comming-soon.mp4'
function ComingSoon() {
    const videoRef = useRef(null);

    useEffect(() => {
        const currentVideo = videoRef.current;

        const handleEnded = () => {
            currentVideo.currentTime = 0;
            currentVideo.play();
        };

        if (currentVideo) {
            currentVideo.addEventListener('ended', handleEnded);
            currentVideo.play().catch(error => {
                console.log('Autoplay error:', error);
            });

            return () => {
                // Cleanup: Remove event listener
                currentVideo.removeEventListener('ended', handleEnded);
            };
        }
    }, []);

    return (
        <>
            <div className="h-[975px] bg-neutral flex items-center bg-blend-normal pt-10 pb-10 md:pb-20  bg-no-repeat" style={{ backgroundImage: `url(${comingsoon})` }}>
                <div className="container">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-4 items-center">
                        <div className='col-span-2 order-2 md:order-1 mt-7 md:mt-0'>
                            <h2 className="text-4xl md:text-[50px] text-white font-semibold text-center md:text-left">COMING SOON</h2>
                            <p className="text-base text-white font-normal font-body max-w-[595px] mt-4">Get ready to revolutionize the way you discover, book, and enjoy events. Stay tuned for our launch and exclusive offers. Your unforgettable event experiences await with Mepass!</p>
                            {/* <div className='flex items-center justify-start mt-8'>
                                <Link to="">
                                    <div className='bg-white w-[32px] h-[32px] rounded justify-center flex items-center mr-3'>
                                        <i className="fab fa-facebook-f text-black text-base"></i>
                                    </div>
                                </Link>
                                <Link to="">
                                    <div className='bg-white w-[32px] h-[32px] rounded justify-center flex items-center mr-3'>
                                        <i className="fab fa-twitter text-black text-base"></i>
                                    </div>
                                </Link>
                                <Link to="">
                                    <div className='bg-white w-[32px] h-[32px] rounded justify-center flex items-center mr-3'>
                                        <i className="fab fa-youtube text-black text-base"></i>
                                    </div>
                                </Link>
                                <Link to="">
                                    <div className='bg-white w-[32px] h-[32px] rounded justify-center flex items-center'>
                                        <i className="fab fa-instagram text-black text-base"></i>
                                    </div>
                                </Link>
                            </div> */}
                        </div>
                        <div className='order-1 md:order-2'>
                            <video className="!h-[440px] md:!h-[600px] m-auto md:hidden block" ref={videoRef} src={video} autoPlay muted controls={false} />
                            <video className="!h-[440px] md:!h-[600px] ms-auto hidden md:block" ref={videoRef} src={video} autoPlay muted controls={false} />
                        </div>
                    </div>


                </div>
            </div>

        </>
    );
}

export default ComingSoon;
