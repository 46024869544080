import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import messageConstant from "../../constants/message";
import labelConstants from "../../constants/label";
import regex from "../../constants/regex";
import Text from "../../components/Common/Text";
import icon from "../../assets/images/small/info-light.png";
import icon2 from "../../assets/images/small/phone-pay.png";
import img from "../../assets/light_theme_images/event-details-items.png";
import offerIcon from "../../assets/light_theme_images/apply-offer-icon.svg";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
// import required modules
import { Autoplay, FreeMode, Pagination } from "swiper/modules";
import bannerImageDefault from "../../assets/images/default/event-image.jpg";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  addUpdateNewDataMultipleForm,
  getData,
} from "../../store/AppMaster/actions";
import api from "../../constants/api";

const PaymentOption = ({
  event,
  paymentDetails,
  passList,
  processedToPay,
  setIsProcessedToPay,
  passPaymentCalculation,
  passOffers,
  handlePromoCodeApply,
  promoCode,
  setPromoCode,
}) => {

  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const [isUpdateFormData, setIsUpdateFormData] = useState(false)
  const gstAmount = passList.reduce(
    (total, pass) => (total + pass.quantity > 0 ? pass.igst : 0),
    0
  );
  const gstAmountQty =
    gstAmount * passList.reduce((total, pass) => total + pass.quantity, 0);

  const authData = JSON.parse(localStorage.getItem("publicMePassUserDetails"))
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: authData.name,
      mobile: authData.mobile,
      email: authData.email,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(messageConstant.FULL_NAME_REQUIRED)
        .min(regex.NAME_MIN, messageConstant.FULL_NAME_MIN_LENGTH)
        .max(regex.NAME_MAX, messageConstant.FULL_NAME_MAX_LENGTH),
      mobile: Yup.string()
        .required(messageConstant.MOBILE_REQUIRED)
        .matches(/^[0-9]+$/, messageConstant.MOBILE_INVALID)
        .min(10, messageConstant.MOBILE_MIN_LENGTH)
        .max(10, messageConstant.MOBILE_MAX_LENGTH),
      email: Yup.string()
        .required(messageConstant.EMAIL_REQUIRED)
        .email(messageConstant.EMAIL_INVALID),
    }),
    onSubmit: (values) => {
      setIsUpdateFormData(true)
      const requestData = {
        name: values.name,
        mobile: values.mobile,
        email: values.email,
      };
      dispatch(
        addUpdateNewDataMultipleForm(
          requestData,
          '',
          api.updateUserProfile
        )
      );
    },
  });

  if (!isEmpty(data) && !isEmpty(data.profileUpdate) && isUpdateFormData) {
    let publicMePassUserDetails = JSON.parse(
      localStorage.getItem("publicMePassUserDetails")
    );
    if (data.profileUpdate.id == publicMePassUserDetails.id) {
      publicMePassUserDetails.name = data.profileUpdate.name;
      publicMePassUserDetails.email = data.profileUpdate.email;
      localStorage.setItem(
        "publicMePassUserDetails",
        JSON.stringify(publicMePassUserDetails)
      );
    }
    setIsUpdateFormData(false);
  }
  return (
    <div className="bg-white pt-6 md:pt-14 pb-14">
      <div className="container">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl md:text-3xl text-[#101010] font-semibold text-center">
            Checkout
          </h2>
          <button
            onClick={
              () => setIsProcessedToPay(false)
            }
            className="text-end bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-3 px-8 rounded font-body  hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-3 md:mt-0">
            <i className="fa fa-arrow-left"></i>  Back
          </button>
        </div>
        <div className="bg-[#F9F9F9] rounded-xl p-3 md:p-6 mt-6 md:mt-10">
          <h3 className="text-2xl md:text-3xl text-[#101010] font-semibold">
            Step 1
          </h3>
          <p className="text-lg md:text-xl  text-[#101010] font-normal">
            Contact Details
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}
          >
            <div className="bg-white rounded-lg p-3 md:p-5 mt-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
                <Text
                  validation={validation}
                  name="name"
                  label={labelConstants.FULL_NAME}
                  isRequire={true}
                  regexCompare={regex.NAME}
                  className="bg-white border border-[#E7E7E7] text-[#CB2129] text-[12px] md:text-base font-semibold font-body rounded-[4px] placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full p-2 md:p-4"
                />
                {/* <Text
                validation={validation}
                name="name"
                label={labelConstants.LAST_NAME}
                isRequire={true}
                regexCompare={regex.NAME}
                className="bg-white border border-[#E7E7E7] text-[#CB2129] text-base font-semibold font-body rounded-[4px] placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full p-3 md:p-4"
              /> */}
                <Text
                  validation={validation}
                  name="mobile"
                  label={labelConstants.MOBILE}
                  isRequire={true}
                  regexCompare={regex.MOBILE}
                  maxLength={10}
                  readOnly={true}
                  className="bg-white border border-[#E7E7E7] text-[#CB2129] text-[12px] md:text-base font-semibold font-body rounded-[4px] placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full p-2 md:p-4"
                />
                <Text
                  className="bg-white border border-[#E7E7E7] text-[#CB2129] text-[12px] md:text-base font-semibold font-body rounded-[4px] placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full p-2 md:p-4"
                  validation={validation}
                  name="email"
                  label="Email"
                  isRequire={true}
                />
              </div>
              <p className="text-[#9C9C9C] text-sm font-normal mt-5">
                Please note your pass will be sent to the above given email id & mobile no..
              </p>
              <div className="text-center mt-4 md:mt-7">
                <button type="submit" className="btn-liner-gradient  text-white text-base md:text-lg font-semibold py-[10px] px-7 rounded-lg ">
                  Confirm
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="bg-[#F9F9F9] rounded-xl p-3 md:p-6 mt-10">
          <h3 className="text-2xl md:text-3xl text-[#101010] font-semibold">
            Step 2
          </h3>
          <p className="text-lg md:text-xl  text-[#101010] font-normal">
            Order Details
          </p>
          {passList.length > 0 &&
            <>
              {passList.map((pass, index) => (
                <>
                  {pass.quantity > 0 && (
                    <div key={index} className="bg-white rounded-lg p-5 mt-4">
                      <div className="flex">
                        <div className="flex-none mr-5">
                          <div className="w-[80px] md:w-[120px] h-[80px] md:h-[120px]">
                            {pass.image ?
                              <img src={pass.image} alt="img" className="rounded-[4px] aspect-[1/1]" />
                              : event.banner_image ?
                                <img src={event.banner_image} alt="img" className="rounded-[4px] aspect-[1/1]" />
                                :
                                <img src={bannerImageDefault} alt="img" className="rounded-[4px] aspect-[1/1]" />
                            }
                          </div>
                        </div>
                        <div className="flex-1 mt-3 md:mt-0">
                          <div className="block md:flex h-full">
                            <div className="flex-1">
                              <h3 className="text-base md:text-2xl text-[#101010] font-semibold">
                                {event.name ? event.name : ""}{event.tagline ? ': ' + event.tagline : ''}
                              </h3>
                              <h4 className="text-base md:text-xl text-[#CB2129] font-semibold mt-1">
                                {pass.name ? pass.name : ""}
                              </h4>
                              <p className="text-base text-[#101010] font-body font-normal mt-2">
                                {pass.quantity} Pass
                              </p>
                            </div>
                            <div className="flex-none">
                              <div className="hidden md:block h-full">
                                <div className="flex flex-col justify-between h-full">
                                  <h3 className="text-lg md:text-3xl text-[#101010] font-body font-semibold">
                                    ₹ {pass.amount_after_discount}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="block md:hidden mt-3">
                        <div className="flex md:flex-col justify-between h-full">
                          <h3 className="text-xl md:text-3xl text-[#101010] font-body font-semibold">
                            ₹ {pass.amount_after_discount}
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}

            </>

          }

        </div>

        <div className="bg-[#F9F9F9] rounded-xl p-3 md:p-6 mt-10">
          <h3 className="text-2xl md:text-3xl text-[#101010] font-semibold">
            Step 3
          </h3>
          <p className="text-lg md:text-xl  text-[#101010] font-normal">
            Apply Offer
          </p>
          <div className="bg-white rounded-lg p-3 md:p-5 mt-4">
            <div className="flex gap-3">
              <div className="flex-1">
                <div>
                  <input
                    name="promoCode"
                    type="text"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    className={"bg-white border border-[#E7E7E7] text-[#CB2129] text-base font-semibold font-body  rounded-lg placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full py-4 px-5"}
                    placeholder={"Enter promo code"}
                  />
                </div>
              </div>
              <div className="flex-none md:ml-5">
                <button onClick={() =>
                  handlePromoCodeApply()
                } type="button" className="btn-liner-gradient  text-white text-lg font-semibold py-2 md:py-[10px] px-4 md:px-7 rounded-lg ">
                  Confirm
                </button>
              </div>

            </div>
            <div className="w-full md:w-[80%] xl:w-[90%] ">
              {passPaymentCalculation.discount_status === "success" && (
                <p className="text-green-500 text-sm font-body font-normal mt-2">
                  {passPaymentCalculation.discount_message}
                </p>
              )}
              {passPaymentCalculation.discount_status === "error" && (
                <p className="text-red-500 text-sm font-body font-normal mt-2">
                  {passPaymentCalculation.discount_message}
                </p>
              )}
            </div>
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              freeMode={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1.2,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 1.7,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 2.3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay, FreeMode, Pagination]}
              className="mySwiper mt-7"
            >
              {passOffers.map((offer, index) => (
                <>
                  {offer.visible_to_public == "Yes" && (
                    <SwiperSlide key={index}>
                      <div className="bg-white border border-[#D2D2D2] rounded-lg">
                        <div className="p-3">
                          <div className="flex items-center">
                            <div className="flex-1">
                              <div className="flex">
                                <img
                                  src={offerIcon}
                                  alt="icon"
                                  className="inline-block mr-2"
                                />
                                <div>
                                  <h3 className="text-[#101010] text-lg font-semibold">
                                    {offer.name}
                                  </h3>
                                  <p className="text-[#0F993E] text-[12px] font-normal">
                                    {offer.offer_code}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="flex-none">
                              <button onClick={() =>
                                setPromoCode(offer.offer_code)
                              } className="bg-[#CB2129] border border-[#CB2129] text-white text-sm py-[4px] px-4 rounded-lg font-medium">
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="border-b-2 border-dashed border-[#E7E7E7] mx-4 relative flex-none">
                          <span
                            className={`w-[24px] h-[22.43px] rounded-full absolute -bottom-[13px] -left-[29px] bg-white border border-[#D2D2D2] box-border flex items-center p-1 after:absolute after:bg-white after:w-[12px] after:h-[22px] after:-left-[1px]`}
                          ></span>
                          <span
                            className={`w-[24px] h-[22.43px] rounded-full absolute -bottom-[13px] -right-[29px] bg-white border border-[#D2D2D2] box-border flex items-center p-1 after:absolute after:bg-white after:w-[12px] after:h-[22px] after:-right-[1px]`}
                          ></span>
                        </div>
                        <div className="p-3">
                          <p className="text-[#9C9C9C] text-base font-normal">
                            {offer.description}
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  )}
                </>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="bg-[#F9F9F9] rounded-xl p-3 md:p-6 mt-10">
          <h3 className="text-2xl md:text-3xl text-[#101010] font-semibold">
            Step 4
          </h3>
          <p className="text-lg md:text-xl  text-[#101010] font-normal">
            Payment Details
          </p>
          <div className="bg-white rounded-lg p-5 mt-4">
            <div className="flex mb-3">
              <div className="flex-1">
                <h3 className="text-xl text-[#101010] font-semibold">
                  Order Amount (
                  {passList.reduce((total, pass) => total + pass.quantity, 0)})
                </h3>
              </div>
              <div className="flex-none">
                <h3 className="text-lg md:text-2xl text-[#CB2129] font-body font-semibold">
                  ₹{paymentDetails.total_amount}
                </h3>
              </div>
            </div>
            {/* <div className="flex">
              <div className="flex-1">
                <h3 className="text-xl text-[#101010] font-semibold">
                  Booking Fees
                </h3>
              </div>
              <div className="flex-none">
                <h3 className="text-lg md:text-2xl text-[#CB2129] font-body font-semibold">
                  ₹900
                </h3>
              </div>
            </div> */}
            <div className="border-b-2 border-[#E7E7E7] my-3"></div>
            <div className="flex">
              <div className="flex-1">
                <h3 className="text-base text-[#101010] font-normal">
                  Amount Before Tax
                </h3>
              </div>
              <div className="flex-none">
                <h3 className="text-base text-[#101010] font-normal">
                  ₹{paymentDetails.amount}
                </h3>
              </div>
            </div>
            <div className="flex mt-1">
              <div className="flex-1">
                <h3 className="text-base text-[#101010] font-normal">GST</h3>
              </div>
              <div className="flex-none">
                <h3 className="text-base text-[#101010] font-normal">
                  ₹{paymentDetails.tax_amount}
                </h3>
              </div>
            </div>
            {paymentDetails.convenience_charge > 0 && (
              <div className="flex mt-1">
                <div className="flex-1">
                  <h3 className="text-base text-[#101010] font-normal">
                    Convenience Charge
                  </h3>
                </div>
                <div className="flex-none">
                  <h3 className="text-base text-[#101010] font-normal">
                    ₹{paymentDetails.convenience_charge}
                  </h3>
                </div>
              </div>
            )}
            {paymentDetails.get_y && paymentDetails.get_y.length > 0 && (
              <div className="flex mt-3">
                <div className="flex-1">
                  <h3 className="text-xl text-[#101010] font-semibold">
                    {paymentDetails.get_y[0]["pass_detail"]["name"]}
                  </h3>
                </div>
                <div className="flex-none">
                  <h3 className="text-base text-[#101010] font-normal">
                    {paymentDetails.get_y[0]["quantity"]} MEPASS FREE
                  </h3>
                </div>
              </div>
            )}
            <div className="border-b border-[#E7E7E7] my-3"></div>
            <div className="flex">
              <div className="flex-1">
                <h3 className="text-xl text-[#101010] font-semibold">
                  Order Total
                </h3>
              </div>
              <div className="flex-none">
                <h3 className="text-lg md:text-2xl text-[#101010] font-bold">
                  ₹{paymentDetails.net_amount}
                </h3>
              </div>
            </div>
            <div className="border-b border-[#E7E7E7] my-3"></div>
            <button
              onClick={() => processedToPay()}
              className="btn-liner-gradient  text-white text-lg font-semibold py-[10px] px-7 rounded-lg w-full mt-2"
            >
              Proceed to pay
            </button>
          </div>
        </div>
        {/* ----------------card-start--------------- */}
        {/* <div className="hidden md:block">
          <div className="flex items-center justify-between">
            <h2 className="text-3xl md:text-5xl text-[#101010] font-body font-medium">
              {event.name ? event.name : ""}
            </h2>
            <button
              onClick={() => setIsProcessedToPay(false)}
              className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-3 px-8 rounded font-body  hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-3 md:mt-0"
            >
              <i className="fa fa-arrow-left"></i> Back
            </button>
          </div>
          <div className="bg-[#131313] p-5 rounded-lg mt-7">
            <div className="flex items-center justify-between">
              <h3 className="text-[20px] text-white font-body font-semibold">
                Ticket Quantity
              </h3>
              <span className="text-[20px] text-white font-body font-semibold">
                {passList.reduce((total, pass) => total + pass.quantity, 0)}
              </span>
            </div>
            <div className="border-y border-[#F6F6F6] py-5 my-5">
              <div className="flex items-center justify-between mb-2">
                <p className="text-base text-[#DCDCDC] font-body font-normal">
                  Amount
                </p>
                <p className="text-base text-[#DCDCDC] font-body font-medium">
                  ₹{paymentDetails.total_amount}
                </p>
              </div>
              {paymentDetails.discount > 0 && (
                <div className="flex items-center justify-between mb-2">
                  <p className="text-base text-[#DCDCDC] font-body font-normal">
                    Discount applied
                  </p>
                  <p className="text-base text-[#DCDCDC] font-body font-medium">
                    - ₹{paymentDetails.discount}
                  </p>
                </div>
              )}
              <div className="flex items-center justify-between mb-2">
                <p className="text-base text-[#DCDCDC] font-body font-normal">
                  Amount Before Tax
                </p>
                <p className="text-base text-[#DCDCDC] font-body font-medium">
                  ₹{paymentDetails.amount}
                </p>
              </div>
              <div className="flex items-center justify-between mb-2">
                <p className="text-base text-[#DCDCDC] font-body font-normal">
                  GST
                </p>
                <p className="text-base text-[#DCDCDC] font-body font-medium">
                  ₹{paymentDetails.tax_amount}
                </p>
              </div>
              <div className="flex items-center justify-between mb-2">
                <p className="text-base text-[#DCDCDC] font-body font-normal">
                  Convenience Charge
                </p>
                <p className="text-base text-[#DCDCDC] font-body font-medium">
                  ₹{paymentDetails.convenience_charge}
                </p>
              </div>
              {paymentDetails.get_y && paymentDetails.get_y.length > 0 && (
                <div className="flex items-center justify-between mb-2">
                  <p className="text-base text-[#DCDCDC] font-body font-normal">
                    {paymentDetails.get_y[0]["pass_detail"]["name"]}
                  </p>
                  <p className="text-base text-[#DCDCDC] font-body font-medium">
                    {paymentDetails.get_y[0]["quantity"]} MEPASS FREE
                  </p>
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10">
              <div className="text-center md:text-left">
                <span className="text-3xl text-secondary font-body font-semibold uppercase">
                  ₹{paymentDetails.net_amount}
                </span>
                <p className="text-xl text-white font-body font-normal mt-2">
                  Final Amount
                </p>
              </div>
              <button
                onClick={() => processedToPay()}
                className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-3 px-8 rounded font-body  hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-3 md:mt-0"
              >
                Process to pay{" "}
              </button>
            </div>
          </div>
        </div> */}
        {/* ----------------card-end---------------- */}
      </div>
    </div>
  );
};

export default PaymentOption;
