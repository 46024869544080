/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => {
            throw error;
        });
};

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchApiJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (response.status == 429) {
                window.location.href = "/page-429";
            } else if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => {
            throw error;
        });
};
export { fetchJSON, fetchApiJSON };
