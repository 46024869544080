import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import banner from "../../assets/images/sign-up.png";
import cardImg from "../../assets/light_theme_images/event-category-banner-mg.png";
import googleIcon from "../../assets/light_theme_images/google.svg";
import instagramIcon from "../../assets/light_theme_images/instagram-icon.svg";
import logo from "../../assets/light_theme_images/logo.svg";
import user from "../../assets/images/user.png";
import labelConstant from "../../constants/label";
import regex from "../../constants/regex";
import OTP from "../Common/OTP";
import Text from "../Common/Text";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
// import required modules
import { EffectFade, Autoplay } from "swiper/modules";
import eventDefaultBanner from "../../assets/images/default/event-image.jpg";
const SignUp = ({
  validation,
  isOtpSent,
  validationOtp,
  resendOtp,
  recaptchaResponse,
  isCheckEmail,
  isCheckMobile,
  props,
  cmsData,
}) => {
  const [timeLeft, setTimeLeft] = useState(120); // 2 minutes = 120 seconds
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setShowButton(true);
    }
  }, [timeLeft]);

  const handleResendOtp = (e) => {
    e.preventDefault();
    resendOtp(); // Call the resendOtp function
    setShowButton(false); // Hide the button
    setTimeLeft(120); // Reset the timer to 2 minutes
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };
  return (
    <>
      {/* <div
        className="h-[850px] md:h-[1075px] bg-neutral bg-blend-normal flex items-center bg-no-repeat"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="container">
          <div className="hidden md:block">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              {isOtpSent && !isCheckEmail ? (
                <>
                  <div className="hidden md:block">
                    <h2 className="text-4xl md:text-[50px] text-secondary font-body font-light">
                      Enter OTP
                    </h2>
                    <p className="text-sm md:text-base font-normal text-white font-body mt-5 w-[90%]">
                      Enter your otp for login.
                    </p>
                  </div>
                  <div className="md:hidden block">
                    <h2 className="text-3xl text-white font-body font-light text-center mb-3">
                      Welcome to Mepass!
                    </h2>
                    <p className="text-base text-white font-body font-normal text-center max-w-[82%] m-auto pb-7">
                      Welcome to{" "}
                      <Link
                        to=""
                        className="font-body font-semibold text-secondary"
                      >
                        MePass
                      </Link>{" "}
                      Please enter your Mobile and otp for login
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <h2 className="text-4xl md:text-5xl text-secondary font-body font-light">
                      Welcome to Mepass!
                    </h2>
                    <p className="text-sm md:text-base font-normal text-white font-body mt-5 w-[90%]">
                      Step into our vibrant community where every moment becomes
                      a cherished memory. Sign up with us and make each
                      experience truly your own. Let's create unforgettable
                      memories together!"
                    </p>
                  </div>
                </>
              )}

              <div>
                <div className="bg-white rounded-lg">
                  <div className="p-[25px] md:p-[50px] pb-0 md:pb-0">
                    <img src={logo} alt="" className="m-auto" />
                    <div className="mt-[50px]">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          isOtpSent && !isCheckEmail
                            ? validationOtp.handleSubmit()
                            : validation.handleSubmit();
                        }}
                      >
                        {isOtpSent && !isCheckEmail ? (
                          <>
                            <div>
                              <div className="flex items-start justify-between">
                                <OTP
                                  validation={validationOtp}
                                  name="otp"
                                  label={labelConstant.OTP}
                                  isRequire={true}
                                />
                              </div>
                            </div>

                            <div className="text-center mt-5">
                              {showButton ? (
                                <button
                                  onClick={handleResendOtp}
                                  className="border border-[#CB2129] rounded-full text-[#CB2129] text-base font-body font-medium px-4 py-2 inline-block"
                                >
                                  Resend OTP
                                </button>
                              ) : (
                                <p className="text-[#101010] text-base font-body font-medium">
                                  Resend OTP in {formatTime(timeLeft)}
                                </p>
                              )}
                            </div>

                            <button
                              type="submit"
                              disabled={
                                validationOtp.isSubmitting ? true : false
                              }
                              className="btn-liner-gradient  text-white text-base font-medium py-4 px-8 rounded-[10px] font-body w-full mt-5"
                            >
                              {" "}
                              Submit OTP
                            </button>
                          </>
                        ) : (
                          <>
                            <div className="mb-5">
                              <Text
                                validation={validation}
                                name="name"
                                label={labelConstant.NAME}
                                isRequire={false}
                                placeholder="Name"
                                regexCompare={regex.NAME}
                              />
                            </div>
                            <div className="mb-5">
                              <Text
                                validation={validation}
                                name="mobile"
                                label={labelConstant.MOBILE}
                                isRequire={true}
                                placeholder="Mobile Number"
                                regexCompare={regex.MOBILE}
                                maxLength={10}
                              />
                            </div>
                            <button
                              type="submit"
                              disabled={validation.isSubmitting ? true : false}
                              className="btn-liner-gradient  text-white text-base font-medium py-4 px-8 rounded-[10px] font-body w-full mt-5"
                            >
                              Sign up
                            </button>
                          </>
                        )}
                      </form>
                    </div>
                    <div className="mt-5 text-center">
                      {props.router.location.state &&
                      props.router.location.state.is_login_page == "Yes" ? (
                        <p className="text-[#101010] text-lg font-normal font-body">
                          Already have an account?{" "}
                          <Link
                            to="/login"
                            className="text-[#101010] font-bold"
                          >
                            Login
                          </Link>
                        </p>
                      ) : (
                        <p className="text-[#101010] text-lg font-normal font-body">
                          Already have an account?{" "}
                          <Link
                            to="/login"
                            state={{ previousPage: "Login" }}
                            className="text-[#101010] font-bold"
                          >
                            Login
                          </Link>
                        </p>
                      )}
                    </div>
                  </div>
                 
                  <div className="p-[25px] md:p-[50px] pt-0 md:pt-0">
                    <div className="grid grid-cols-2 gap-4 mt-7">
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:hidden block">
            <h2 className="text-3xl text-white font-body font-light text-center mb-3">
              SignUp
            </h2>
            <p className="text-base text-white font-body font-normal text-center max-w-[82%] m-auto pb-7">
              Welcome to{" "}
              <Link to="" className="font-body font-semibold text-secondary">
                MePass
              </Link>{" "}
              Please enter your Email and password for login
            </p>
            <div className="bg-white rounded-[10px] mt-7 px-[36px] py-[29px]">
              <div className="text-center">
                <img src={user} alt="img" className="inline-block" />
              </div>
              <div className="mt-3">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    isOtpSent && !isCheckEmail
                      ? validationOtp.handleSubmit()
                      : validation.handleSubmit();
                  }}
                >
                  {isOtpSent && !isCheckEmail ? (
                    <>
                      <div>
                        <div className="flex items-start justify-between">
                          <OTP
                            validation={validationOtp}
                            name="otp"
                            label={labelConstant.OTP}
                            isRequire={true}
                          />
                        </div>
                      </div>
                      <div className="text-center mt-5">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            resendOtp();
                          }}
                          className="border border-[#CB2129] rounded-full text-[#CB2129] text-base font-body font-medium px-4 py-2 inline-block"
                        >
                          Resend OTP
                        </button>
                      </div>
                      <button
                        type="submit"
                        disabled={validationOtp.isSubmitting ? true : false}
                        className="btn-liner-gradient  text-white text-base font-normal tracking-[0.3rem] md:py-4 py-2 px-8 rounded-[5px] md:rounded-[10px] font-body w-full  duration-1000 ease-in-out uppercase mt-5"
                      >
                        {" "}
                        Submit OTP
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="mb-5">
                        <Text
                          validation={validation}
                          name="name"
                          label={labelConstant.NAME}
                          isRequire={true}
                          placeholder="Name"
                          regexCompare={regex.NAME}
                        />
                      </div>
                      <div className="mb-5">
                        <Text
                          validation={validation}
                          name="mobile"
                          label={labelConstant.MOBILE}
                          isRequire={true}
                          placeholder="Mobile Number"
                          regexCompare={regex.MOBILE}
                        />
                      </div>
                      <div className="mb-5">
                        <Text
                          validation={validation}
                          name="email"
                          label={labelConstant.EMAIL}
                          isRequire={true}
                          placeholder="Email"
                          //regexCompare={regex.EMAIL}
                        />
                      </div>
                      <button
                        type="submit"
                        disabled={validation.isSubmitting ? true : false}
                        className="btn-liner-gradient  text-white text-base font-normal tracking-[0.3rem] py-4 px-8 rounded-[10px] font-body w-full  duration-1000 ease-in-out uppercase mt-5"
                      >
                        Sign up
                      </button>
                    </>
                  )}
                </form>
              </div>
              <div className="mt-3 text-center">
                {props.router.location.state &&
                props.router.location.state.is_login_page ? (
                  <p className="text-[#101010] text-lg font-normal font-body flex justify-center items-center whitespace-nowrap">
                    aldready have an account?{" "}
                    <Link to="/login" className="text-[#101010] font-bold">
                      {" "}
                      Login
                    </Link>
                  </p>
                ) : (
                  <p className="text-[#101010] text-lg font-normal font-body flex justify-center items-center whitespace-nowrap">
                    aldready have an account?{" "}
                    <Link
                      to="/login"
                      state={{ previousPage: "Login" }}
                      className="text-[#101010] font-bold"
                    >
                      {" "}
                      Login
                    </Link>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div>
        <div className="block lg:flex items-center justify-center md:h-screen pt-24 pb-28 xl:pt-0 xl:pb-0">
          <div className="relative flex flex-col-reverse lg:flex-row items-center justify-center px-5 md:px-7 lg:px-0 pb-10 lg:pb-0">
            <div className="w-full lg:w-[450px] xl:w-[500px] flex-none mt-14 lg:mt-0">
              <div>
                <div className="text-center">
                  <img
                    src={logo}
                    alt=""
                    className="m-auto mb-10 hidden lg:inline-block"
                  />
                </div>
                {isOtpSent && !isCheckEmail ? (
                  <>
                    <div className="text-center">
                      <h2 className="text-base md:text-4xl text-[#101010] font-semibold">
                        OTP Verification
                      </h2>
                      <p className="text-sm md:text-lg font-normal text-[#101010] mt-2">
                        Enter the OTP sent to your mobile to verify and continue{" "}
                        <br /> your journey with us!
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center">
                      <h2 className="text-base md:text-4xl text-[#101010] font-semibold">
                        Start your Journey
                      </h2>
                      <p className="text-sm md:text-lg font-normal text-[#101010] mt-2">
                        Create your account today and be part of a community{" "}
                        <br /> where every moment becomes a cherished memory!
                      </p>
                    </div>
                  </>
                )}

                <div className="mt-6">
                  <div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        isOtpSent && !isCheckEmail
                          ? validationOtp.handleSubmit()
                          : validation.handleSubmit();
                      }}
                    >
                      {isOtpSent && !isCheckEmail ? (
                        <>
                          <div>
                            <div className="flex items-start justify-between">
                              <OTP
                                validation={validationOtp}
                                name="otp"
                                label={labelConstant.OTP}
                                isRequire={true}
                              />
                            </div>
                          </div>
                          <button
                            type="submit"
                            disabled={validationOtp.isSubmitting ? true : false}
                            className="btn-liner-gradient  text-white text-base font-medium py-4 px-8 rounded-[10px] font-body w-full mt-5"
                          >
                            {" "}
                            Verify
                          </button>
                          <div className="text-center mt-5">
                            {showButton ? (
                              <button
                                onClick={handleResendOtp}
                                className="text-[#101010] text-base font-medium"
                              >
                                Didn’t receive OTP?{" "}
                                <span className="font-bold text-[#CB2129]">
                                  Resend OTP
                                </span>
                              </button>
                            ) : (
                              <p className="text-[#101010] text-base font-body font-medium">
                                Resend OTP in {formatTime(timeLeft)}
                              </p>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mb-5">
                            <Text
                              validation={validation}
                              name="name"
                              label={labelConstant.FULL_NAME}
                              isRequire={false}
                              placeholder="Name"
                              regexCompare={regex.NAME}
                              className="bg-[#F9F9F9] text-[#CB2129] text-base font-semibold rounded-[4px] placeholder:text-[#616161] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full py-4 px-5"
                            />
                          </div>
                          <div className="mb-5">
                            <Text
                              validation={validation}
                              name="mobile"
                              label={labelConstant.MOBILE_NUMBER}
                              isRequire={true}
                              placeholder="Mobile Number"
                              regexCompare={regex.MOBILE}
                              maxLength={10}
                              className="bg-[#F9F9F9] text-[#CB2129] text-base font-semibold rounded-[4px] placeholder:text-[#616161] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full py-4 px-5"
                            />
                          </div>
                          <button
                            type="submit"
                            disabled={validation.isSubmitting ? true : false}
                            className="btn-liner-gradient  text-white text-base font-medium py-2 md:py-4 px-4 md:px-8 rounded-[8px] font-body w-full mt-5"
                          >
                            Create Account
                          </button>
                        </>
                      )}
                    </form>
                  </div>
                  {isOtpSent && !isCheckEmail ? (
                    <></>
                  ) : (
                    <>
                      <div className="mt-5 text-center">
                        {props.router.location.state &&
                        props.router.location.state.is_login_page == "Yes" ? (
                          <p className="text-[#101010] text-lg font-normal font-body">
                            Already have an account?{" "}
                            <Link
                              to="/login"
                              className="text-[#CB2129] font-bold"
                            >
                              Login
                            </Link>
                          </p>
                        ) : (
                          <p className="text-[#101010] text-lg font-normal font-body">
                            Already have an account?{" "}
                            <Link
                              to="/login"
                              state={{ previousPage: "Login" }}
                              className="text-[#CB2129] font-bold"
                            >
                              Login
                            </Link>
                          </p>
                        )}
                      </div>
                      {/* <div className="mt-8 border-b border-[#E0E0E0] relative">
                          <div className="bg-white px-[7px] text-[#616161] text-sm font-light absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 -mt-[1px]">
                            Or use social
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-1.5 md:gap-6 mt-10">
                          <button className="select-location-border-liner-gradient text-[#101010] text-[12px] md:text-base font-normal py-1 md:py-3 px-2 xl:px-4">
                            <img
                              src={googleIcon}
                              alt="icon"
                              className="inline-block mr-1 md:mr-2"
                            />
                            Log In with Google
                          </button>
                          <button className="select-location-border-liner-gradient text-[#101010] text-[12px] md:text-base font-normal py-1 md:py-3 px-2 xl:px-4">
                            <img
                              src={instagramIcon}
                              alt="icon"
                              className="inline-block mr-1 md:mr-2"
                            />
                            Log In with Instagram
                          </button>
                        </div> */}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full lg:w-[450px] flex-none lg:ml-14">
              <div className="text-center">
                <img
                  src={logo}
                  alt=""
                  className="m-auto mb-14 inline-block lg:hidden"
                />
              </div>
              <div>
                <Swiper
                  spaceBetween={30}
                  effect={"fade"}
                  autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                  }}
                  modules={[EffectFade, Autoplay]}
                  className="mySwiper lg:h-[600px] xl:h-[700px]"
                >
                  {!isEmpty(cmsData) &&
                    cmsData.map((singleCms, index) => (
                      <>
                        {index < 4 && !isEmpty(singleCms.event) ? (
                          <SwiperSlide>
                            <div className="relative w-full h-full aspect-[9/16] rounded-3xl overflow-hidden flex items-center justify-center px-[90px] py-[70px] md:py-[160px]">
                              <div
                                className="absolute inset-0 -z-10"
                                style={{
                                  backgroundImage: `url(${
                                    singleCms.event.banner_image
                                      ? singleCms.event.banner_image
                                      : eventDefaultBanner
                                  })`,
                                  filter: "blur(30px)",
                                  opacity: "0.5px",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                }}
                              ></div>
                              <div className="absolute inset-0 bg-[#00000033] -z-10"></div>
                              <img
                                src={
                                  singleCms.event.banner_image
                                    ? singleCms.event.banner_image
                                    : eventDefaultBanner
                                }
                                alt="img"
                                className="aspect-[9/16] rounded-xl"
                              />
                            </div>
                          </SwiperSlide>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
SignUp.propTypes = {
  validation: PropTypes.object,
  recaptchaRef: PropTypes.object,
  isOtpSent: PropTypes.bool,
  validationOtp: PropTypes.object,
  resendOtp: PropTypes.func,
};
export default SignUp;
