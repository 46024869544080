import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import bannerImageDefault from "../../assets/images/default/event-image.jpg";
import hBannerImageDefault from "../../assets/images/default/h-event-image.png";
import banner1 from "../../assets/images/event-new.png";
import icon from "../../assets/images/small/Star.png";
import icon4 from "../../assets/images/small/percentage-red.png";
import icon2 from "../../assets/images/small/percentage-yellow.png";
import icon3 from "../../assets/images/small/small-star.png";
import Artist from "../../components/Event/Details/Artist";
import Sponsor from "../../components/Event/Details/Sponsor";
import calendarIcon from "../../assets/light_theme_images/calender-dark.svg";
import clockIcon from "../../assets/light_theme_images/clock-dark.svg";
import starIcon from "../../assets/light_theme_images/star-dark.svg";
import locationIcon from "../../assets/light_theme_images/location-dark.svg";
import mapImg from "../../assets/light_theme_images/map.svg";
import instagramIcon from "../../assets/light_theme_images/instagram.svg";
import facebookIcon from "../../assets/light_theme_images/facebook.svg";
import twitterIcon from "../../assets/light_theme_images/twitter.svg";
import gallaryImg from "../../assets/light_theme_images/gallary-img.png";
import gallaryImg2 from "../../assets/light_theme_images/gallary-img2.png";
import gallaryImg3 from "../../assets/light_theme_images/gallary-img3.png";
import durartionIcon from "../../assets/light_theme_images/duration-hours.svg";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  FreeMode,
  Keyboard,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Link, useParams } from "react-router-dom";
import logo from "../../assets/images/default/organiser-logo.png";
import { convertToAmPm, convertToDate } from "../../common/commonFunction";
import withRouter from "../../components/Common/withRouter";
import api from "../../constants/api";
import {
  addUpdateNewData,
  clearData,
  getData,
} from "../../store/AppMaster/actions";
import EventUpComing from "../../components/Event/Details/EventUpComing";
import SeoDetails from "../../seo/SeoDetails";
import SkeletonLoaderEventDetails from "../../components/SkeletonLoader/EventCategory/Details";
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

const EventDetails = (props) => {
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));
  // const {merchant_id}=useState(true);
  const params = useParams();
  // console.log(params);
  const [singleEvent, setSingleEvent] = useState([]);
  const [isApiCall, setIsApiCall] = useState(0);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [artists, setArtists] = useState([]);
  const [isArtistDataGet, setIsArtistDataGet] = useState(true);
  const [sponsors, setSponsors] = useState([]);
  const [isSponsorDataGet, setIsSponsorDataGet] = useState(true);
  const [eventDetail, setEventDetail] = useState([]);
  const [isUpcomingEventData, setIsUpcomingEventData] = useState(true);
  const [profileData, setProfileData] = useState([]);
  const [upcomingEventDetail, setUpcomingEventDetail] = useState([]);
  const [bannerImage, setBannerImage] = useState([]);
  const [mapCenter, setMapCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [videoUrl, setVideoUrl] = useState("");
  const [embedUrl, setEmbedUrl] = useState("");

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const dispatch = useDispatch();

  const artistDataGet = useCallback(() => {
    setIsArtistDataGet(true);
    const userDetail = JSON.parse(
      localStorage.getItem("publicMePassUserDetails")
    );
    let requestData = {
      event_slug: props.router.params.event_slug,
      user_id: userDetail && userDetail.id ? userDetail.id : "",
    };
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.EventArtistList));
  }, [dispatch, props.router.navigate, props.router.params.event_slug]);

  const sponsorDataGet = useCallback(() => {
    setIsSponsorDataGet(true);
    let requestData = {
      event_slug: props.router.params.event_slug,
    };
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.EventSponsorList));
  }, [dispatch, props.router.navigate, props.router.params.event_slug]);

  const upComingEventDataGet = (merchant_company_slug) => {
    setIsUpcomingEventData(true);
    let requestData = {
      merchant_company_slug: merchant_company_slug,
      events: "Upcoming",
    };
    dispatch(clearData());
    dispatch(getData(requestData, "", api.EventList));
  };

  useEffect(() => {
    if (isApiCall === 0) {
      setIsUpdateData(true);
      dispatch(clearData());
      const userDetail = JSON.parse(
        localStorage.getItem("publicMePassUserDetails")
      );
      const requestData = {
        event_slug: props.router.params.event_slug,
        user_id: userDetail && userDetail.id ? userDetail.id : "",
      };
      dispatch(getData(requestData, props.router.navigate, api.EventDetails));
      artistDataGet();
      sponsorDataGet();
      setIsApiCall(1);
    }
  }, [
    isApiCall,
    dispatch,
    artistDataGet,
    sponsorDataGet,
    props.router.navigate,
    props.router.params.event_slug,
  ]);

  const convertToEmbedUrl = (url) => {
    const regex = /place\/([^/]+)\/@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = url.match(regex);
    if (match) {
      const place = match[1];
      const latitude = match[2];
      const longitude = match[3];
      setMapCenter({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      });
    }
    return null;
  };

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.eventView) && isUpdateData) {
      setSingleEvent(data.eventView);
      if (data.eventView.gmap_link) {
        convertToEmbedUrl(data.eventView.gmap_link);
      }
      if (data.eventView.horizontal_banner_image) {
        //Separate using Comma
        let bannerImage = data.eventView.horizontal_banner_image.split(",");
        setBannerImage(bannerImage);
      }
      if (data.eventView.youtube_url) {
        setEmbedUrl(extractEmbedUrl(data.eventView.youtube_url));
      }
      upComingEventDataGet(data.eventView.merchant_company_slug);
      setIsUpdateData(false);
    }
    if (!isEmpty(data) && !isEmpty(data.artistList) && isArtistDataGet) {
      setArtists(data.artistList);
      setIsArtistDataGet(false);
    }
    if (!isEmpty(data) && !isEmpty(data.sponsorList) && isSponsorDataGet) {
      setSponsors(data.sponsorList);
      setIsSponsorDataGet(false);
    }
  }, [
    data,
    isUpdateData,
    isArtistDataGet,
    isSponsorDataGet,
    isUpcomingEventData,
    setSingleEvent,
    setArtists,
    setSponsors,
  ]);

  if (!isEmpty(data) && !isEmpty(data.eventList) && isUpcomingEventData) {
    setUpcomingEventDetail(data.eventList);
    setIsUpcomingEventData(false);
  }
  const [isAddData, setIsAddData] = useState(false);
  const addToFavorite = (eventId) => {
    setIsAddData(true);
    const newData = {
      favorite_id: eventId,
      type: "Event",
    };
    dispatch(
      addUpdateNewData(newData, props.router.navigate, api.AddFavorites)
    );
  };

  if (!isEmpty(data) && !isEmpty(data.favoritesAdd) && isAddData) {
    setIsAddData(false);
    setIsUpdateData(true);
    singleEvent.is_favorite = "Yes";
  }

  // Remove favorite
  const [isRemoveData, setIsRemoveData] = useState(false);
  const removeToFavorite = (eventId) => {
    setIsRemoveData(true);
    const newData = {
      favorite_id: eventId,
      type: "Event",
    };
    dispatch(
      addUpdateNewData(newData, props.router.navigate, api.RemoveFavorites)
    );
  };
  if (!isEmpty(data) && !isEmpty(data.favoritesDelete) && isRemoveData) {
    setIsRemoveData(false);
    setIsUpdateData(true);
    dispatch(clearData());
    singleEvent.is_favorite = "No";
  }

  const eventDateDisplay = () => {
    if (singleEvent.dates && singleEvent.dates[0]) {
      //First and Last Array Date not Same then Display Date
      if (singleEvent.dates.length > 1) {
        if (
          singleEvent.dates[0]["date"] !=
          singleEvent.dates[singleEvent.dates.length - 1]["date"]
        ) {
          //First and Last Array Date in Year Same then only Date and month Display Remove Year
          if (
            singleEvent.dates[0]["date"].split("-")[2] ==
            singleEvent.dates[singleEvent.dates.length - 1][
              "date"
            ].split("-")[2]
          ) {
            let firstDate = convertToDate(singleEvent.dates[0]["date"]);
            let lastDate = convertToDate(
              singleEvent.dates[singleEvent.dates.length - 1]["date"]
            );
            return (
              firstDate.split(",")[0] +
              ", " +
              firstDate.split(",")[1] +
              " - " +
              lastDate
            );
          } else {
            return (
              convertToDate(singleEvent.dates[0]["date"]) +
              " - " +
              convertToDate(
                singleEvent.dates[singleEvent.dates.length - 1]["date"]
              )
            );
          }
        } else {
          return convertToDate(singleEvent.dates[0]["date"]);
        }
      } else {
        return convertToDate(singleEvent.dates[0]["date"]);
      }
    }
  };

  const getFullAddress = (date) => {
    let address = "";
    if (date["address"]) {
      address = date["address"];
    }
    if (date["city"]) {
      address += ", " + date["city"];
    }
    if (date["state"] && date["state"] != date["city"]) {
      address += ", " + date["state"];
    }
    if (date["country"] && date["country"] != date["state"]) {
      address += ", " + date["country"];
    }
    return address;
  };

  const extractEmbedUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      const videoId = parsedUrl.searchParams.get("v"); // Extract video ID
      const playlistId = parsedUrl.searchParams.get("list"); // Extract playlist ID
      if (videoId) {
        let embedBase = `https://www.youtube.com/embed/${videoId}`;
        if (playlistId) {
          embedBase += `?list=${playlistId}`;
        }
        return embedBase; // Construct the iframe embed URL
      }
      return null;
    } catch (error) {
      return null; // Handle invalid URLs
    }
  };

  return (
    <>
      <SeoDetails
        title={singleEvent.seo_title}
        description={singleEvent.short_description}
        keywords={singleEvent.keywords}
        ogImage={singleEvent.banner_image}
        schemaData={singleEvent} // pass the event data to generate schema
        artist={artists} // pass the artist data to generate schema
      />

      <section className="pt-14">
        <div className="container">
          <div className="block md:flex gap-10">
            <div className="flex-1">
              <h2 className="text-[#101010] text-2xl md:text-4xl font-bold">
                {singleEvent.name}{singleEvent.tagline ? ': ' + singleEvent.tagline : ''}
              </h2>
              <div className="block lg:flex gap-4 mt-4 md:mt-2.5">
                <div className="flex-none">
                  <div className="flex gap-1.5 mb-2 lg:mb-0">
                    <img
                      src={calendarIcon}
                      alt="img"
                      className="inline-block"
                    />
                    <p className="text-[#101010] text-lg font-normal">
                      {eventDateDisplay()}
                    </p>
                  </div>
                </div>
                <div className="flex-none">
                  <div className="flex gap-4">
                    <div className="flex-none">
                      <div className="flex gap-1.5 mb-2 lg:mb-0">
                        <img src={clockIcon} alt="img" className="inline-block" />
                        <p className="text-[#101010] text-lg font-normal">
                          {singleEvent &&
                            singleEvent.dates &&
                            singleEvent.dates[0] &&
                            singleEvent.dates[0]["start_time"]
                            ? convertToAmPm(singleEvent.dates[0]["start_time"])
                            : ""}
                        </p>
                      </div>
                    </div>
                    {singleEvent.duration_hours &&
                      <div className="flex-none">
                        <div className="flex gap-1.5 mb-2 lg:mb-0">
                          <img src={durartionIcon} alt="img" className="inline-block" />
                          <p className="text-[#101010] text-lg font-normal">
                            {singleEvent.duration_hours} Hours
                          </p>
                        </div>
                      </div>
                    }
                    <div className="flex-none">
                      {singleEvent.rating > 0 ? (
                        <div className="flex gap-1.5 mb-2 lg:mb-0">
                          <img src={starIcon} alt="img" className="inline-block" />
                          <p className="text-[#101010] text-lg font-normal">
                            {singleEvent.rating ? singleEvent.rating : ""}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  {singleEvent.dates &&
                    singleEvent.dates[0] &&
                    singleEvent.dates[0]["address"] ? (
                    <div className="flex items-start gap-1.5">
                      <img
                        src={locationIcon}
                        alt="img"
                        className="inline-block mt-[3px]"
                      />
                      <p className="text-[#101010] text-lg font-normal">
                        {getFullAddress(singleEvent.dates[0])}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="flex-none mt-7 md:mt-0">
              {singleEvent.pass_created === true &&
                singleEvent.pass_available === true ? (
                <Link to={`/events/${singleEvent.slug}/book-tickets`}>
                  <button className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-4 md:px-8 rounded-lg font-body duration-1000 ease-in-out h-[54px] flex items-center justify-center">
                    Book Now
                  </button>
                </Link>
              ) : ''}
              {singleEvent.original_price > 0 && (
                <p className="text-[#101010] text-xl font-medium mt-2">
                  ₹{singleEvent.original_price} onwards
                </p>
              )}
            </div>
          </div>
        </div>
      </section>

      {((!isEmpty(bannerImage) && bannerImage.length > 0)
        || embedUrl
      ) && (
          <section className="pt-10">
            <Swiper
              cssMode={true}
              mousewheel={true}
              keyboard={true}
              slidesPerView={1.4}
              centeredSlides={true}
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 1.5,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
              className="mySwiper event-details-slider"
            >
              {bannerImage && (
                <>
                  {bannerImage.map((item, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={
                          item
                            ? item
                            : hBannerImageDefault
                        }
                        alt=""
                        className="rounded-xl aspect-[99/40] w-full"
                      />
                    </SwiperSlide>
                  ))}
                  {embedUrl &&
                    <SwiperSlide>
                      <iframe
                       className="rounded-xl aspect-[99/40] w-full"
                        src={embedUrl}
                      >
                      </iframe>
                    </SwiperSlide>
                  }
                </>
              )}
            </Swiper>
          </section >
        )}
      <section className="bg-white pt-10 pb-24">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="md:col-span-2">
              {!isEmpty(singleEvent.short_description) ? (
                <div>
                  <h2 className="text-2xl md:text-3xl font-semibold text-[#101010] ">
                    About the Event
                  </h2>
                  <p className="text-base text-[#101010] font-normal mt-4">
                    {singleEvent.short_description}
                  </p>
                  {!isEmpty(singleEvent.description) &&
                    singleEvent.description !== "null" ? (
                    <p className="text-base text-[#101010] font-body font-normal mt-6">
                      <pre className="text-base text-[#101010] font-body font-normal whitespace-pre-wrap overflow-auto">
                        {singleEvent.description}
                      </pre>
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {!isEmpty(singleEvent.terms_and_conditions) ? (
                <div className="pt-10 md:pt-24">
                  <h2 className="text-2xl md:text-3xl font-semibold text-[#101010] ">
                    Terms & Conditions
                  </h2>
                  <p className="text-base text-[#101010] font-normal mt-3">
                    <span
                      className={`${singleEvent.terms_and_conditions &&
                        singleEvent.terms_and_conditions.includes("<ul>")
                        ? "bullet-point"
                        : singleEvent.terms_and_conditions &&
                          singleEvent.terms_and_conditions.includes("<ol>")
                          ? "number-point"
                          : ""
                        }`}
                      dangerouslySetInnerHTML={{
                        __html: singleEvent.terms_and_conditions,
                      }}
                    ></span>
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              <div className="flex flex-col gap-10">
                {singleEvent.gmap_link &&
                  <div>
                    <h4 className="text-2xl lg:text-3xl font-semibold text-[#101010] ">
                      Event Location
                    </h4>
                    {/* <LoadScript googleMapsApiKey="AIzaSyDffCmHYWlz1NRwz2pEIybqWzbrb9kPLI4">
                      <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={mapCenter}
                        zoom={14}
                      >
                        <Marker position={mapCenter} />
                      </GoogleMap>
                    </LoadScript> */}
                    {singleEvent.dates &&
                      singleEvent.dates[0] &&
                      singleEvent.dates[0]["address"] ? (
                      <div className="flex gap-1.5 pt-2">
                        <p className="text-[#101010] text-xl font-normal">
                          {getFullAddress(singleEvent.dates[0])}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                }
                <div className="flex flex-col gap-5">
                  <h4 className="text-2xl lg:text-3xl font-semibold text-[#101010] ">
                    Share with friends
                  </h4>
                  <ul className="flex gap-5">
                    {singleEvent.instagram_page &&
                      <li>
                        <a
                          href={singleEvent.instagram_page}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={instagramIcon} alt="icon" />
                        </a>
                      </li>
                    }
                    {singleEvent.twitter_page &&
                      <li>
                        <a
                          href={singleEvent.twitter_page}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={twitterIcon} alt="icon" />
                        </a>
                      </li>
                    }
                    {singleEvent.facebook_page &&
                      <li>
                        <a
                          href={singleEvent.facebook_page}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={facebookIcon} alt="icon" />
                        </a>
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* ----Gallery---- */}
          {/* <div className="mt-8">
            <h2 className="text-2xl md:text-3xl font-semibold text-[#101010] mb-5">
              Gallery
            </h2>
            <Swiper
              cssMode={true}
              mousewheel={true}
              keyboard={true}
              slidesPerView={3}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1.3,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
              className="mySwiper gallary-slider !pb-10"
            >
              <SwiperSlide>
                <img
                  src={gallaryImg}
                  alt=""
                  className="rounded-xl aspect-[9/16] w-full"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={gallaryImg2}
                  alt=""
                  className="rounded-xl aspect-[9/16] w-full"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={gallaryImg3}
                  alt=""
                  className="rounded-xl aspect-[9/16] w-full"
                />
              </SwiperSlide>
            </Swiper>
          </div> */}

          {/* ----Artists-Performing---- */}
          <div className="mt-8">
            <Artist artists={artists} props={props} />
          </div>

          {/* ----Event-Sponsors---- */}
          <div className="mt-8">
            <Sponsor sponsors={sponsors} props={props} />
          </div>
          {/* ----You-may-love-these-too---- */}
          <div className="mt-8">
            <EventUpComing events={upcomingEventDetail} props={props} />
          </div>
        </div>
      </section>

      {/* <div className="w-full h-[360px] bg-cover pt-28 md:pt-[135px] relative">
        <div
          className="absolute inset-0 -z-10"
          style={{
            backgroundImage: `url(${
              singleEvent.banner_image
                ? singleEvent.banner_image
                : bannerImageDefault
            })`,
            filter: "blur(15px)",
            opacity: "0.5px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div
          className="absolute inset-0 bg-black -z-10"
          style={{ opacity: 0.5 }}
        ></div>
      </div> */}
      <section>
        <div className="container">
          {/* <div className="hidden md:block">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-4">
              <div>
                <img
                  src={
                    singleEvent.banner_image
                      ? singleEvent.banner_image
                      : bannerImageDefault
                  }
                  alt=""
                  className="rounded aspect-[9/16]"
                />
              </div>
              <div className="col-span-2 mt-10 md:mt-0">
                <div className="flex justify-end items-center gap-4 mb-10 md:mb-[105px] relative">
                  <h3 className="text-xl text-white font-body font-regular">
                    Organised by
                  </h3>
                  <Link to={`/organiser/${singleEvent.merchant_company_slug}`}>
                    <div className="bg-white rounded w-[150px] p-2">
                      <div className="max-h-[80px]">
                        <img
                          src={
                            singleEvent.logo_path ? singleEvent.logo_path : logo
                          }
                          alt=""
                          className="size-20 m-auto"
                        />
                      </div>
                    </div>
                  </Link>{" "}
                </div>
                <div className="flex items-center justify-between gap-5">
                  <h2 className="text-2xl md:text-4xl lg:text-5xl text-white font-body font-medium">
                    {singleEvent.name}
                  </h2>
                  {singleEvent.rating > 0 ? (
                    <div className="bg-secondary py-[8px] px-[16px] flex items-center gap-2 rounded-full">
                      <img src={icon3} alt="" className="inline-block" />
                      <p className="text-primary text-[24px] font-body font-semibold">
                        {singleEvent.rating ? singleEvent.rating : 1}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="block lg:flex items-start  justify-between mt-5">
                  <div className="flex-1">
                    <div className="flex flex-wrap items-center 2xl:gap-2 lg:gap-2 gap-1 mb-3 md:mb-0">
                      {singleEvent.keywords &&
                      !isEmpty(singleEvent.keywords) ? (
                        <>
                          {singleEvent.keywords &&
                            singleEvent.keywords
                              .split(",")
                              .map((item, index) => (
                                <button
                                  key={index}
                                  className="text-white text-[16px] font-normal tracking-[.125em] py-2 px-[18px] rounded-full font-body bg-black  hover:text-secondary border border-black hover:border hover:border-secondary duration-1000 ease-in-out"
                                >
                                  #{item}
                                </button>
                              ))}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {singleEvent.feature ? (
                    <div className="flex-none">
                      <div className="bg-secondary w-[183.25px] h-[54.25px] flex items-center justify-center rounded-[7.97px] gap-3 md:mt-3 lg:mt-0 mt-0">
                        <img src={icon} alt="" className="inline-block" />
                        <p className="text-primary text-[22.31px] font-body font-semibold">
                          Featured
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="bg-[#000000] rounded-lg p-4 mt-5">
                  <div className="flex justify-between gap-2 ">
                    {singleEvent.dates &&
                    singleEvent.dates[0] &&
                    singleEvent.dates[0]["address"] ? (
                      <p className="text-white text-base md:text-[20px] font-body font-normal flex-1">
                        <i className="fas fa-map-marker-alt text-white mr-2"></i>
                        {getFullAddress(singleEvent.dates[0])}
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="text-end flex-none">
                      {singleEvent.price_without_tax > 0 ? (
                        <h5 className="text-xl md:text-[32px] font-semibold text-secondary font-body">
                          <b>₹{singleEvent.price_without_tax}</b>
                        </h5>
                      ) : (
                        ""
                      )}

                      <div className="flex items-center mt-1">
                        {singleEvent.original_price > 0 &&
                        singleEvent.original_price !=
                          singleEvent.price_without_tax ? (
                          <h5 className="text-lg font-medium text-[#c8c8c8] ml-3">
                            <del>₹{singleEvent.original_price}</del>
                          </h5>
                        ) : (
                          ""
                        )}
                        {singleEvent.percentage > 0 && (
                          <p className="text-lg font-medium text-[#388e3c] ml-3">
                            {singleEvent.percentage}% off
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-5 mt-5">
                    <p className="text-white text-base md:text-[20px] font-body font-normal mb-2">
                      {eventDateDisplay()}
                    </p>
                    <p className="text-white text-base md:text-[20px] font-body font-normal mb-2">
                      <i className="fas fa-clock text-white mr-2"></i>
                      {singleEvent &&
                      singleEvent.dates &&
                      singleEvent.dates[0] &&
                      singleEvent.dates[0]["start_time"]
                        ? convertToAmPm(singleEvent.dates[0]["start_time"])
                        : ""}
                    </p>
                  </div>
                  <div className="block lg:flex items-center justify-between mt-5">
                    {singleEvent.no_of_ratings > 0 ? (
                      <div className="py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white group-hover:hidden">
                        <i className="fas fa-user-friends mr-3"></i>{" "}
                        {singleEvent.no_of_ratings > 0
                          ? singleEvent.no_of_ratings
                          : ""}
                        <span className="font-body font-normal ml-1">
                          Expected Crowd
                        </span>
                      </div>
                    ) : (
                      ""
                    )}

                    {singleEvent.pass_created === true &&
                    singleEvent.pass_available === true ? (
                      <Link to={`/events/${singleEvent.slug}/book-tickets`}>
                        <button className="bg-primary border border-primary text-white text-base font-normal tracking-[.25em] py-3 px-8 rounded font-body w-full lg:w-2/4 xl:w-[408px] hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-4 lg:mt-0">
                          Book
                        </button>
                      </Link>
                    ) : singleEvent.pass_created === false ? (
                      ""
                    ) : singleEvent.pass_available === false &&
                      singleEvent.pass_created === true ? (
                      <button className="bg-primary border border-primary text-white text-base font-normal tracking-[.25em] py-3 px-8 rounded font-body w-full lg:w-2/4 xl:w-[408px] hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-4 lg:mt-0">
                        Sold
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className="flex items-center gap-4 mt-5">
                  {localStorage.getItem("publicMePassUser") ? (
                    <>
                      {singleEvent.is_favorite === "Yes" ? (
                        <div
                          className="bg-white w-[48px] h-[48px] flex items-center justify-center rounded-full cursor-pointer"
                          onClick={() => {
                            removeToFavorite(singleEvent.id);
                          }}
                        >
                          <i className="fas fa-heart text-3xl text-primary"></i>
                        </div>
                      ) : (
                        <div
                          className="bg-white w-[48px] h-[48px] flex items-center justify-center rounded-full cursor-pointer"
                          onClick={() => {
                            addToFavorite(singleEvent.id);
                          }}
                        >
                          <i className="fas fa-heart text-3xl text-[#D9D9D9]"></i>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      onClick={() => {
                        props.router.navigate("/login", {
                          state: { is_login_page: "Yes" },
                        });
                      }}
                      className="bg-white w-[48px] h-[48px] flex items-center justify-center rounded-full cursor-pointer"
                    >
                      <i className="fas fa-heart text-3xl text-[#D9D9D9]"></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div> */}
          {/* ---------------------------------------------------mobile-View-start------------------------ */}
          {/* <div className="md:hidden block">
            <div className="-mt-[320px]">
              <div className="flex justify-center w-[70%] m-auto">
                <div className="text-center">
                  <h2 className="text-2xl text-white font-body font-medium">
                    {singleEvent.name}
                  </h2>
                  <div className="mt-6">
                    <div className="text-center">
                      <div className="relative">
                        <img
                          src={
                            singleEvent.banner_image
                              ? singleEvent.banner_image
                              : bannerImageDefault
                          }
                          alt=""
                          className="rounded"
                        />
                        <div className=" absolute top-0 -right-[46px]">
                          {localStorage.getItem("publicMePassUser") ? (
                            <>
                              {singleEvent.is_favorite === "Yes" ? (
                                <div
                                  className="bg-white w-[35px] h-[35px] flex items-center justify-center rounded-full mt-2 cursor-pointer"
                                  onClick={() => {
                                    removeToFavorite(singleEvent.id);
                                  }}
                                >
                                  <i className="fas fa-heart text-xl text-primary"></i>
                                </div>
                              ) : (
                                <div
                                  className="bg-white w-[35px] h-[35px] flex items-center justify-center rounded-full mt-2 cursor-pointer"
                                  onClick={() => {
                                    addToFavorite(singleEvent.id);
                                  }}
                                >
                                  <i className="fas fa-heart text-xl text-[#D9D9D9]"></i>
                                </div>
                              )}
                            </>
                          ) : (
                            <div
                              onClick={() => {
                                props.router.navigate("/login", {
                                  state: { is_login_page: "Yes" },
                                });
                              }}
                              className="bg-white w-[35px] h-[35px] flex items-center justify-center rounded-full mt-2"
                            >
                              <i className="fas fa-heart text-xl text-[#D9D9D9]"></i>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex justify-end items-center gap-4 mt-6">
                        <h3 className="text-lg text-white font-body font-regular">
                          Organised by
                        </h3>
                        <Link
                          to={`/organiser/${singleEvent.merchant_company_slug}`}
                        >
                          <div className="bg-white rounded w-[140px] p-2 text-center">
                            <img
                              src={
                                singleEvent.logo_path
                                  ? singleEvent.logo_path
                                  : logo
                              }
                              alt=""
                              className="size-12 m-auto"
                            />
                          </div>
                        </Link>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex items-center justify-center flex-nowrap gap-4 mt-6">
                  {singleEvent.feature ? (
                    <div className="bg-secondary w-[136px] h-[32px] rounded-[5px] flex items-center justify-center">
                      <img src={icon3} alt="" className="inline-block" />
                      <p className="text-primary text-base font-body font-semibold ml-2">
                        Featured
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {singleEvent.offer_available ? (
                    <div className="bg-secondary w-[155px] h-[32px] rounded-[5px] flex items-center justify-center">
                      <img
                        src={icon4}
                        alt=""
                        className="inline-block w-[20px]"
                      />
                      <p className="text-primary text-base font-body font-semibold ml-2">
                        Offer Available
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex items-center justify-between mt-3">
                  <div className="">
                    {singleEvent.price_without_tax > 0 ? (
                      <span className="text-3xl text-secondary font-body font-semibold uppercase">
                        ₹{singleEvent.price_without_tax}
                      </span>
                    ) : (
                      ""
                    )}
                    <div className="flex items-center mt-1">
                      {singleEvent.original_price > 0 &&
                      singleEvent.original_price !=
                        singleEvent.price_without_tax ? (
                        <h5 className="text-lg font-medium text-[#c8c8c8]">
                          <del>₹ {singleEvent.original_price}</del>
                        </h5>
                      ) : (
                        ""
                      )}
                      {singleEvent.percentage > 0 ? (
                        <p className="text-lg font-medium text-[#388e3c] ml-3">
                          {singleEvent.percentage}% off
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {singleEvent.pass_created === true &&
                  singleEvent.pass_available === true ? (
                    <Link to={`/events/${singleEvent.slug}/book-tickets`}>
                      <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body  hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-3 md:mt-0">
                        Book
                      </button>
                    </Link>
                  ) : singleEvent.pass_created === false ? (
                    ""
                  ) : singleEvent.pass_available === false &&
                    singleEvent.pass_created === true ? (
                    <button className="bg-primary border border-primary  text-white text-base font-normal tracking-[.25em] py-2 px-8 rounded font-body  hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-3 md:mt-0">
                      Sold
                    </button>
                  ) : null}
                </div>
                <div className="flex items-center overflow-auto gap-2 mt-6">
                  {singleEvent.keywords && !isEmpty(singleEvent.keywords) ? (
                    <>
                      {singleEvent.keywords &&
                        singleEvent.keywords.split(",").map((item, index) => (
                          <button
                            key={index}
                            className="text-white text-[16px] font-normal tracking-[.125em] py-2 px-[18px] rounded-full font-body bg-black  hover:text-secondary border border-black hover:border hover:border-secondary duration-1000 ease-in-out"
                          >
                            #{item}
                          </button>
                        ))}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="bg-[#000000] rounded-lg p-4 mt-6">
                  {singleEvent.dates &&
                  singleEvent.dates[0] &&
                  singleEvent.dates[0]["address"] ? (
                    <p className="text-white text-base md:text-[20px] font-body font-normal">
                      <i className="fas fa-map-marker-alt text-white mr-2"></i>
                      {singleEvent.dates[0]["address"]}
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="flex items-center justify-between gap-5 mt-3">
                    <div className="flex items-center gap-2">
                      <p className="text-white text-base md:text-[20px] font-body font-normal">
                        <i className="fas fa-calendar text-white mr-2"></i>
                        {singleEvent.dates && singleEvent.dates[0]
                          ? singleEvent.dates[0]["date_formatted"] &&
                            eventDateDisplay()
                          : ""}
                        {singleEvent &&
                        singleEvent.dates &&
                        singleEvent.dates[0] &&
                        singleEvent.dates[0]["start_time"]
                          ? " - "
                          : ""}
                        {singleEvent &&
                        singleEvent.dates &&
                        singleEvent.dates[0] &&
                        singleEvent.dates[0]["start_time"]
                          ? convertToAmPm(singleEvent.dates[0]["start_time"])
                          : ""}
                      </p>
                      {singleEvent.no_of_ratings > 0 ? (
                        <p className="text-white text-base md:text-[20px] font-body font-normal">
                          <i className="fas fa-user-friends text-white mr-2"></i>
                          {singleEvent.no_of_ratings}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    {singleEvent.rating > 0 ? (
                      <div className="bg-primary py-[2px] px-[5px] inline-block rounded-full">
                        <p className="text-white text-[14px] font-body">
                          <i className="fas fa-star mr-2 text-secondary"></i>
                          <b>{singleEvent.rating}</b>
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {singleEvent.no_of_ratings > 0 ? (
                    <div className="py-[7px] px-[15px] border border-[#FCB426] rounded-3xl text-base font-bold font-body text-white inline-block mt-4">
                      <i className="fas fa-user-friends mr-3"></i>
                      {singleEvent.no_of_ratings}
                      <span className="font-body font-normal ml-1">
                        Expected Crowd
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div> */}

          {/* ---------------------------------------------------mobile-View-start------------------------ */}

          {/* -------------------------------avialable-offer-start------------------------- */}
          {/* {singleEvent.event_offer && !isEmpty(singleEvent.event_offer) ? (
            <div className="pt-10 md:pt-24">
              <h2 className="text-2xl md:text-[40px] font-body font-light tracking-[.150em] text-white uppercase">
                Available Offers
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-8 mt-9">
                {singleEvent.event_offer.map((singleOffer, index) => (
                  <div className="bg-[#131313] rounded-lg p-4" index={index}>
                    <div className="flex items-center gap-3">
                      <img src={icon2} alt="" />
                      <h3 className="text-base md:text-xl text-white font-body font-semibold uppercase">
                        {singleOffer.title}
                      </h3>
                    </div>
                    {singleOffer.description ? (
                      <p className="text-base text-white font-body font-regular mt-3">
                        {singleOffer.description}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )} */}

          {/* -------------------------------avialable-offer-end------------------------- */}

          {/* -------------------------------About-event-Start------------------------- */}
          {/* {!isEmpty(singleEvent.short_description) ? (
            <div className="pt-10 md:pt-24">
              <h2 className="text-2xl md:text-[40px] font-body font-light tracking-[.150em] text-white uppercase">
                About Event
              </h2>
              <p className="text-base text-[#DCDCDC] font-body font-normal mt-9">
                {singleEvent.short_description}
              </p>
              {!isEmpty(singleEvent.description) &&
              singleEvent.description !== "null" ? (
                <p className="text-base text-[#DCDCDC] font-body font-normal mt-9">
                  <pre className="text-base text-[#DCDCDC] font-body font-normal whitespace-pre-wrap overflow-auto">
                    {singleEvent.description}
                  </pre>
                </p>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )} */}
          {/* {!isEmpty(singleEvent.terms_and_conditions) ? (
            <div className="pt-10 md:pt-24">
              <h2 className="text-2xl md:text-[40px] font-body font-light tracking-[.150em] text-white uppercase">
                Terms & Conditions
              </h2>
              <p className="text-base text-[#DCDCDC] font-body font-normal mt-9 ">
                <span
                  className={`${
                    singleEvent.terms_and_conditions &&
                    singleEvent.terms_and_conditions.includes("<ul>")
                      ? "bullet-point"
                      : singleEvent.terms_and_conditions &&
                        singleEvent.terms_and_conditions.includes("<ol>")
                      ? "number-point"
                      : ""
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: singleEvent.terms_and_conditions,
                  }}
                ></span>
              </p>
            </div>
          ) : (
            ""
          )} */}
          {/* -------------------------------About-event-end------------------------- */}

          {/* -------------------------------Artist-Start------------------------- */}
          {/* <Artist artists={artists} props={props} /> */}
          {/* -------------------------------Artist-end------------------------- */}

          {/* ------------------------------section-end------------------------- */}
          {/* <div className="pt-10 md:pt-24">
            <button className="bg-primary border border-primary text-white text-base font-normal tracking-[0.1rem]  md:tracking-[.25em] px-2 py-1 md:py-2 md:px-8 rounded font-body hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase">
              <i className="fab fa-instagram mr-1 md:mr-3"></i>Follow Instagram
            </button>
            <div className="grid grid-cols-2 gap-8 mt-5 md:mt-8">
              <div>
                <img src={img2} alt="" className="w-full" />
              </div>
              <div>
                <img src={img3} alt="" className="w-full" />
              </div>
              <div>
                <img src={img4} alt="" className="w-full" />
              </div>
              <div>
                <img src={img5} alt="" className="w-full" />
              </div>
            </div>
          </div> */}
          {/* -------------------------------section-end------------------------- */}
        </div>
      </section>
    </>
  );
};

export default withRouter(EventDetails);
