import React from "react"
import withRouter from "../../components/Common/withRouter"
import img from "../../assets/light_theme_images/500-img.png"
import { useNavigate } from 'react-router-dom';

const Page500 = props => {

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Go to the previous page
    };
    return (
        <>
            <div className="container">
                <div className="text-center py-24">
                    <img src={img} alt="500" className="inline-block" />
                    <h2 className="text-[#101010] text-lg md:text-3xl font-semibold w-[257px] md:w-[421px] m-auto mt-9">Hang tight! We're resolving the issue.</h2>
                    <button onClick={goBack} className="btn-liner-gradient text-white text-sm lg:text-base text-center font-medium py-[6px] lg:py-[10px] px-2  md:px-3 lg:px-7 rounded-lg duration-1000 ease-in-out h-[48px] flex items-center justify-center mt-8 w-[82px] m-auto">Back</button>
                </div>
            </div>
        </>
    )
}

export default withRouter(Page500)
