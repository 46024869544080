import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import eventDefaultBanner from "../../assets/images/default/event-image.jpg";
import rank from "../../assets/images/small/rank.png";
import withRouter from "../../components/Common/withRouter";
import EventView from "../../components/Event/View";
import api from "../../constants/api";
import { clearData, getData } from "../../store/AppMaster/actions";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
// import required modules
import { Autoplay, FreeMode, Pagination, Mousewheel } from "swiper/modules";
import SeoDetails from "../../seo/SeoDetails";
import banner from "../../assets/light_theme_images/event-category-banner-mg.png";
import locationIcon from "../../assets/light_theme_images/location-dark.svg";
import calendar from "../../assets/light_theme_images/calender-dark.svg";
import clock from "../../assets/light_theme_images/clock-dark.svg";
import star from "../../assets/light_theme_images/star-dark.svg";
import { convertToAmPm, convertToDate } from "../../common/commonFunction";
const EventCategory = (props) => {
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));
  const [filterData, setFilterData] = useState({
    start: 0,
    limit: 12,
    search: "",
    sort_by: "",
    sort_order: "desc",
    status: "",
    category_id: "",
  });
  const [events, setEvents] = useState([]);
  const [isApiCall, setIsApiCall] = useState(0);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [totalEventsCount, setTotalEventsCount] = useState(0); // New state for total count of events
  const [cmsData, setCmsData] = useState("");
  const [isCmsData, setIsCmsData] = useState(false);
  const [isCategoryData, setIsCategoryData] = useState(false);
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();

  const getListData = useCallback(
    (status, search, start) => {
      setIsUpdateData(true);
      dispatch(clearData());
      filterData.status = status ? status : "";
      filterData.search = search ? search : filterData.search;
      filterData.start = start ? start : 0;
      filterData.category_slug = props.router.params.category_name
        ? props.router.params.category_name
        : "";
      filterData.city = props.router.params.city_name
        ? props.router.params.city_name
        : "";
      dispatch(getData(filterData, props.router.navigate, api.EventList));
    },
    [dispatch, filterData, props.router.navigate]
  );

  const CmsPage = useCallback(() => {
    setIsCmsData(true);
    let requestCmsData = {
      section_titles: "trending",
      is_page: "Event",
    };
    if (props.router.params.city_name) {
      requestCmsData.city = props.router.params.city_name;
    }
    if (props.router.params.category_name) {
      requestCmsData.category = props.router.params.category_name;
    }
    dispatch(clearData());
    dispatch(getData(requestCmsData, props.router.navigate, api.CmsPage));
  }, [dispatch, props.router.navigate]);

  const getCategoryList = useCallback(() => {
    setIsCategoryData(true);
    const requestData = {};
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.CategoryList));
  }, [dispatch, props.router.navigate]);

  useEffect(() => {
    if (isApiCall === 0) {
      setEvents([]);
      setTotalEventsCount(0);
      getListData();
      //Cms Page Data For Rank
      CmsPage();
      //Get Category List
      getCategoryList();
      setIsApiCall(1);
    }
  }, [isApiCall, getListData, CmsPage, getCategoryList]);

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.eventList) && isUpdateData) {
      setEvents((prevEvents) => [...prevEvents, ...data.eventList]);
      setTotalEventsCount(data.filter_count); // Assuming the API returns the total count of events
      setIsUpdateData(false);
    }
  }, [data, isUpdateData]);

  useEffect(() => {
    if (
      !isEmpty(data) &&
      !isEmpty(data.cmsPage) &&
      !isEmpty(data.cmsPage.trending) &&
      isCmsData
    ) {
      setCmsData(data.cmsPage.trending);
      setIsCmsData(false);
    }
    if (!isEmpty(data) && !isEmpty(data.categoryList) && isCategoryData) {
      setCategories(data.categoryList);
      setIsCategoryData(false);
    }
  }, [data, isCmsData, isCategoryData]);

  const searchEvent = () => {
    setEvents([]);
    filterData.start = 0;
    getListData("", "", 0);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setEvents([]);
      filterData.start = 0;
      getListData("", "", 0);
    }
  };

  const loadMore = () => {
    const start = filterData.start + filterData.limit;
    filterData.start = start;
    getListData(filterData.status, filterData.search, start);
  }

  return (
    <>
      <SeoDetails
        title={
          cmsData && cmsData[0] && cmsData[0].seo_title
            ? cmsData[0].seo_title
            : ""
        }
        description={
          cmsData && cmsData[0] && cmsData[0].short_description
            ? cmsData[0].short_description
            : ""
        }
        ogImage={
          cmsData && cmsData[0] && cmsData[0].seo_image && cmsData[0].seo_image
            ? cmsData[0].seo_image
            : ""
        }
      />
      <Swiper
        slidesPerView={1}
        freeMode={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, FreeMode, Pagination]}
        className="mySwiper banner-slider event-category-slider"
      >
        {!isEmpty(cmsData) &&
          cmsData.map((singleCms, index) => (
            <>
              {index < 4 && !isEmpty(singleCms.event) ? (
                <SwiperSlide>
                  <div className="relative w-full min-h-[620px] md:min-h-full md:h-full bg-cover pt-28 md:pt-[135px] pb-10 md:pb-20">
                    <div
                      className="absolute inset-0 -z-10"
                      style={{
                        backgroundImage: `url(${singleCms.event.banner_image ? singleCms.event.banner_image : eventDefaultBanner})`,
                        filter: "blur(30px)",
                        opacity: "0.5px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                    <div className="absolute inset-0 bg-[#00000033] -z-10"></div>
                    <div className="container">
                      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 md:gap-10 lg:gap-32 mt-5 md:mt-0 items-end md:items-center">
                        <div className="col-span-1 xl:col-span-2">
                          <h1 className="text-2xl font-bold text-[#F0BA0E] pb-3">
                            #{index + 1} Trending
                          </h1>
                          <Link to={"/events/" + singleCms.event.slug}>
                            <h2 className="text-3xl lg:text-6xl font-body font-semibold text-[#FFFFFF]">
                              {singleCms &&
                                singleCms.event &&
                                index + 1}{" "}
                              {singleCms &&
                                singleCms.event &&
                                singleCms.event.name}
                            </h2>
                          </Link>
                          <p className="text-[#FFFFFF] text-base font-normal pt-4 leading-6 line-clamp-2">
                            {singleCms &&
                              singleCms.event &&
                              singleCms.event.short_description
                              ? singleCms &&
                              singleCms.event &&
                              singleCms.event.short_description
                              : ""}
                          </p>
                          <div className="block lg:flex gap-4 pt-3 md:pt-5">
                            {singleCms.event.dates && singleCms.event.dates[0] ? (
                              <h4 className="text-base md:text-[20px] text-[#FFFFFF] font-normal font-body">
                                <img
                                  src={calendar}
                                  alt="calendar"
                                  className="inline-block mr-2 -mt-[4px]"
                                />
                                {convertToDate(singleCms.event.dates[0]["date"])}
                              </h4>
                            ) : (
                              ""
                            )}
                            <div className="flex gap-4 pt-3 lg:pt-0">
                              {singleCms.event &&
                                singleCms.event.dates &&
                                singleCms.event.dates[0] &&
                                singleCms.event.dates[0]["start_time"]
                                ?
                                <h5 className="text-base md:text-[20px] text-[#FFFFFF] font-normal font-body">
                                  <img
                                    src={clock}
                                    alt="clock"
                                    className="inline-block mr-2 -mt-[4px]"
                                  />
                                  {convertToAmPm(singleCms.event.dates[0]["start_time"])}
                                </h5>
                                : ""}
                              {singleCms.event.rating > 0 ? (
                                <h5 className="text-base md:text-[20px] text-[#FFFFFF] font-normal font-body">
                                  <img
                                    src={star}
                                    alt="star"
                                    className="inline-block mr-2 size-5 -mt-[4px]"
                                  />
                                  {singleCms.event.rating}
                                </h5>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {singleCms.event.dates &&
                            singleCms.event.dates[0] &&
                            singleCms.event.dates[0]["address"] ? (
                            <h5 className="text-base md:text-[20px] text-[#FFFFFF] font-normal font-body mt-3">
                              <img
                                src={locationIcon}
                                alt="location"
                                className="inline-block mr-2"
                              />
                              {singleCms.event.dates[0]["address"]}
                            </h5>
                          ) : (
                            ""
                          )}

                          <div className="hidden md:inline-block mt-10">
                            {singleCms.event.pass_created === true &&
                              singleCms.event.pass_available === true ? (
                              <Link to={`/events/${singleCms.event.slug}/book-tickets`}>
                                <button className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-3 md:px-7 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center">
                                  Book Now
                                </button>
                              </Link>
                            ) : singleCms.event.pass_created === false ? (
                              <Link to={`/events/${singleCms.event.slug}`}>
                                <button className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-3 md:px-7 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center">
                                  View Event
                                </button>
                              </Link>
                            ) : singleCms.event.pass_available === false &&
                              singleCms.event.pass_created === true ? (
                              <button
                                className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-3 md:px-7 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center"
                                disabled
                              >
                                Sold
                              </button>
                            ) : null}
                          </div>
                        </div>
                        <div className="text-center md:text-right mt-4 md:mt-0">
                          <Link to={"/events/" + singleCms.event.slug}>
                            <img
                              src={singleCms.event.banner_image ? singleCms.event.banner_image : banner}
                              className="w-full rounded-xl aspect-[9/16] object-fill"
                              alt=""
                            />
                          </Link>
                          <div className="md:hidden inline-block text-center mt-10 mb-10">
                            {singleCms.event.pass_created === true &&
                              singleCms.event.pass_available === true ? (
                              <Link to={`/events/${singleCms.event.slug}/book-tickets`}>
                                <button className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-3 md:px-7 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center">
                                  Book Now
                                </button>
                              </Link>
                            ) : singleCms.event.pass_created === false ? (
                              <Link to={`/events/${singleCms.event.slug}`}>
                                <button className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-3 md:px-7 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center">
                                  View Event
                                </button>
                              </Link>
                            ) : singleCms.event.pass_available === false &&
                              singleCms.event.pass_created === true ? (
                              <button
                                className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-3 md:px-7 rounded-lg font-body duration-1000 ease-in-out h-[48px] flex items-center justify-center"
                                disabled
                              >
                                Sold
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ) : (
                ""
              )}
            </>
          ))}
      </Swiper >

      <section className="bg-white py-14">
        <div className="container">
          <div className="flex flex-wrap gap-3 md:gap-5 mb-10">
            {categories &&
              categories.map((singleCategory, index) => (
                <button
                  key={index}
                  onClick={() => {
                    if (!isEmpty(props.router.params.city_name)) {
                      setIsApiCall(0);
                      props.router.navigate(
                        `/city/${props.router.params.city_name}/category/${singleCategory.slug}/events`
                      );
                    } else {
                      setIsApiCall(0);
                      props.router.navigate(
                        `/category/${singleCategory.slug}/events`
                      );
                    }
                  }}
                  className={
                    props.router.params.category_name ===
                      singleCategory.slug
                      ? "bg-[#CB2129] border border-[#CB2129] rounded-full py-2 px-4 md:px-5 text-white font-semibold text-base md:text-lg"
                      : "bg-white border border-[#101010] rounded-full py-2 px-4 md:px-5 text-[#101010] font-medium text-base md:text-lg"
                  }
                >
                  {singleCategory.name}
                </button>
              ))}
          </div>
          {events.length > 0 && (
            <h2 className="text-2xl md:text-3xl font-semibold text-[#101010] mb-5">
              View More from Below
            </h2>
          )}
          <div className="grid grid-cols-2 xl:grid-cols-3 gap-3 md:gap-4 lg:gap-10">
            {events &&
              events.map((singleEvent, index) => (
                <Link to={"/events/" + singleEvent.slug}>
                  <EventView singleEvent={singleEvent} key={index} />
                </Link>
              ))}
          </div>
          {events.length === 0 && (
            <div className="text-center text-black">
              <h2 className="text-2xl font-body font-normal text-center">
                No Events Found
              </h2>
            </div>
          )}
          {events.length < totalEventsCount &&
            <div className="flex justify-center mt-9">
              <button onClick={loadMore}
                className="border border-[#CB2129] text-[#CB2129] text-lg text-center font-semibold py-2 px-4 rounded-lg duration-1000 ease-in-out h-[48px] flex items-center justify-center">
                Load more
              </button>
            </div>
          }
        </div>
      </section>
    </>
  );
};

export default withRouter(EventCategory);
