import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertToAmPm, convertToDate } from "../../common/commonFunction";
import api from "../../constants/api";
import { getData } from "../../store/AppMaster/actions";
const TicketHistory = ({ props, isApiCall, setIsApiCall }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));

  const [activeButton, setActiveButton] = useState(1);
  // Function to handle button click and change active button
  const handleButtonClick = (buttonId) => {
    if (buttonId === 2) {
      getTicketHistory();
    } else {
      getActivePass();
    }
    setActiveButton(buttonId);
  };

  const [isUpdateActivePassData, setIsUpdateActivePassData] = useState(false);
  const [isUpdateTicketHistoryData, setIsUpdateTicketHistoryData] =
    useState(false);
  const [activePass, setActivePass] = useState([]);
  const [ticketHistory, setTicketHistory] = useState([]);
  const [totalActivePass, setTotalActivePass] = useState(0);
  const [totalTicketHistory, setTotalTicketHistory] = useState(0);
  const [requestActivePassData, setRequestActivePassData] = useState({
    start: 0,
    limit: 10,
    type: "ActivePass",
  });
  const [requestTicketHistoryData, setRequestTicketHistoryData] = useState({
    start: 0,
    limit: 10,
    type: "PassHistory",
  });
  useEffect(() => {
    if (isApiCall === 0) {
      if (activeButton === 1) {
        setActivePass([]);
        setTotalActivePass(0);
        getActivePass();
      } else {
        setTicketHistory([]);
        setTotalTicketHistory(0);
        getTicketHistory();
      }
      setIsApiCall(1);
    }
  }, [isApiCall, dispatch, props.router.navigate]);

  const getActivePass = () => {
    setIsUpdateActivePassData(true);
    dispatch(
      getData(
        requestActivePassData,
        props.router.navigate,
        api.getTicketHistory
      )
    );
  };

  const getTicketHistory = () => {
    setIsUpdateTicketHistoryData(true);
    dispatch(
      getData(
        requestTicketHistoryData,
        props.router.navigate,
        api.getTicketHistory
      )
    );
  };

  if (
    !isEmpty(data) &&
    !isEmpty(data.passHistoryLis) &&
    isUpdateActivePassData
  ) {
    setActivePass((prevPass) => [...prevPass, ...data.passHistoryLis]);
    setTotalActivePass(data.filter_count);
    setRequestActivePassData({
      start: requestActivePassData.start + 10,
      limit: 10,
      type: "ActivePass",
    });
    setIsUpdateActivePassData(false);
  }
  if (
    !isEmpty(data) &&
    !isEmpty(data.passHistoryLis) &&
    isUpdateTicketHistoryData
  ) {
    setTicketHistory((prevPass) => [...prevPass, ...data.passHistoryLis]);
    setTotalTicketHistory(data.filter_count);
    setRequestTicketHistoryData({
      start: requestTicketHistoryData.start + 10,
      limit: 10,
      type: "PassHistory",
    });
    setIsUpdateTicketHistoryData(false);
  }

  const loadMoreActivePass = () => {
    getActivePass();
  };

  const loadMoreTicketHistory = () => {
    getTicketHistory();
  };
  return (
    <>
      <div className="bg-[#F9F9F9] p-4 md:p-[40px] rounded-lg md:rounded-[2px]">
        <div className="flex items-center justify-between bg-white p-2 md:p-3 rounded-lg mb-7">
          <button
            className={`button ${activeButton === 1
              ? "active-button bg-[#CB2129] rounded-[4px] !text-white "
              : ""
              } py-[10px] px-[17px] md:px-[30px] text-[#101010] font-semibold text-base md:text-xl w-1/2`}
            onClick={() => handleButtonClick(1)}
          >
            Active Pass
          </button>
          <button
            className={`button ${activeButton === 2
              ? "active-button bg-[#CB2129] rounded-[4px] !text-white "
              : ""
              } py-[10px] px-[17px] md:px-[30px] text-[#101010] font-semibold text-base md:text-xl w-1/2`}
            onClick={() => handleButtonClick(2)}
          >
            History
          </button>
        </div>
        {activeButton === 1 ? (
          <>
            {activePass.map((item, index) => (
              <div
                key={index}
                className="bg-white border border-[#D9D9D9] rounded-xl mb-4"
              >
                <div className="flex">
                  <div className="flex-none w-[99px] md:w-[150px] lg:w-[247px] text-center">
                    <div className="p-[7px] md:p-10 flex items-center h-full justify-center">
                      <h2 className="text-[#101010] text-[13px] md:text-xl lg:text-3xl font-bold">
                        {item.created_at ? convertToDate(item.created_at) : ""}
                      </h2>
                    </div>
                  </div>
                  <div className="border-l-2 border-dashed border-[#E7E7E7] my-3 mx-1 md:mx-4 relative flex-none">
                    <span
                      className={`w-[40px] h-[40px] rounded-full absolute -top-[37px] -left-[21px] bg-[#F9F9F9] border-b border-[#E7E7E7] box-border flex items-center p-1`}
                    ></span>
                    <span
                      className={`w-[40px] h-[40px] rounded-full absolute -bottom-[37px] -right-[18px] bg-[#F9F9F9] border-t border-[#E7E7E7] box-border flex items-center p-1`}
                    ></span>
                  </div>
                  <div className="flex-1">
                    <div className="px-2 py-4 md:px-4">
                      <div className="flex">
                        <div className="flex-1">
                          <h3 className="text-[#101010] text-[14px] md:text-xl font-bold">
                            {item.event_name}
                          </h3>
                          <div className="block md:flex items-center mt-1.5 md:mt-3 gap-6">
                            <p className="text-[#101010] text-[13px] md:text-base font-body font-medium">
                              {item.event_date ? (
                                <i className="fas fa-calendar text-[#CB2129] mr-2"></i>
                              ) : (
                                ""
                              )}
                              {item.event_date
                                ? convertToDate(item.event_date)
                                : ""}
                            </p>
                            <p className="text-[#101010] text-[13px] md:text-base font-medium mr-2 mt-1.5 md:mt-0">
                              {item.start_time ? (
                                <i className="fas fa-clock text-[#CB2129] mr-2"></i>
                              ) : (
                                ""
                              )}
                              {item.start_time
                                ? convertToAmPm(item.start_time)
                                : ""}
                            </p>
                          </div>
                          <p className="text-[#101010] text-[13px] md:text-base font-medium mt-1.5 md:mt-2">
                            {(item.address || item.city) && (
                              <i className="fas fa-map-marker-alt text-[#CB2129] mr-2"></i>
                            )}
                            {item.address} {item.address && item.city && ", "}{" "}
                            {item.city}
                          </p>
                        </div>
                        <div className="flex-none">
                          {/* <i
                            className="fas fa-share-alt text-[#CB2129] cursor-pointer"
                            onClick={() => {
                              props.router.navigate(
                                `/user-tickets/${item.url_string}/share-history`
                              );
                            }}
                          ></i> */}
                        </div>
                      </div>
                      <div className="flex mt-6">
                        <div className="flex-1">
                          <span className="text-[#101010] text-lg lg:text-2xl font-semibold flex-none">
                            ₹
                            {item.pricing_details &&
                              item.pricing_details.net_amount
                              ? item.pricing_details.net_amount
                              : 0}
                          </span>
                        </div>
                        <div className="flex-none">
                          <button
                            onClick={() => {
                              window.open(`/qr/${item.url_string}`, "_blank");
                            }}
                            className="text-white btn-liner-gradient text-[13px] md:text-base font-semibold rounded-lg py-1 md:py-2 px-2 md:px-4 inline-block"
                          >
                            View Ticket
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {activePass.length === 0 && (
              <div className="p-[20px] md:p-[30px] mb-4 text-center">
                <h4 className="text-[#101010] text-base xxl:text-2xl font-body font-semibold hidden md:block">
                  No Data Found
                </h4>
              </div>
            )}
          </>
        ) : (
          <>
            {ticketHistory.map((item, indexTicket) => (
              <div
                key={indexTicket}
                className="bg-white border border-[#D9D9D9] rounded-xl mb-4"
              >
                <div className="flex">
                  <div className="flex-none w-[99px] md:w-[150px] lg:w-[247px] text-center">
                    <div className="p-[7px] md:p-10 flex items-center h-full justify-center">
                      <h2 className="text-[#101010] text-[13px] md:text-xl lg:text-3xl font-bold">
                        {convertToDate(item.created_at)}
                      </h2>
                    </div>
                  </div>
                  <div className="border-l-2 border-dashed border-[#E7E7E7] my-3 mx-4 relative flex-none">
                    <span
                      className={`w-[40px] h-[40px] rounded-full absolute -top-[37px] -left-[21px] bg-[#F9F9F9] border-b border-[#E7E7E7] box-border flex items-center p-1`}
                    ></span>
                    <span
                      className={`w-[40px] h-[40px] rounded-full absolute -bottom-[37px] -right-[18px] bg-[#F9F9F9] border-t border-[#E7E7E7] box-border flex items-center p-1`}
                    ></span>
                  </div>
                  <div className="flex-1">
                    <div className="px-2 py-4 md:px-4">
                      <div className="flex">
                        <div className="flex-1">
                          <h3 className="text-[#101010] text-[14px] md:text-xl font-bold">
                            {item.event_name}
                          </h3>
                          <div className="block md:flex items-center mt-1.5 md:mt-3 gap-6">
                            <p className="text-[#101010] text-[13px] md:text-base font-medium">
                              {item.event_date ? (
                                <i className="fas fa-calendar text-[#CB2129] mr-2"></i>
                              ) : (
                                ""
                              )}
                              {item.event_date
                                ? convertToDate(item.event_date)
                                : ""}
                            </p>
                            <p className="text-[#101010] text-[13px] md:text-base font-medium mr-2 mt-1.5 md:mt-0">
                              {item.start_time ? (
                                <i className="fas fa-clock text-[#CB2129] mr-2"></i>
                              ) : (
                                ""
                              )}
                              {item.start_time
                                ? convertToAmPm(item.start_time)
                                : ""}
                            </p>
                          </div>
                          <p className="text-[#101010] text-[13px] md:text-base font-medium mt-1.5 md:mt-2">
                            {(item.address || item.city) && (
                              <i className="fas fa-map-marker-alt text-[#CB2129] mr-2"></i>
                            )}
                            {item.address}
                            {item.address && item.city && ", "}
                            {item.city}
                          </p>
                        </div>
                        <div className="flex-none">
                          {/* <i
                            className="fas fa-share-alt text-[#CB2129] cursor-pointer"
                            onClick={() => {
                              props.router.navigate(
                                `/user-tickets/${item.url_string}/share-history`
                              );
                            }}
                          ></i> */}
                        </div>
                      </div>
                      <div className="flex mt-6">
                        <div className="flex-1">
                          <span className="text-[#101010] text-lg lg:text-2xl font-semibold flex-none">
                            ₹
                            {item.pricing_details &&
                              item.pricing_details.net_amount
                              ? item.pricing_details.net_amount
                              : 0}
                          </span>
                        </div>
                        <div className="flex-none">
                          <button
                            onClick={() => {
                              window.open(
                                `/qr/${item.url_string}`,
                                "_blank"
                              );
                            }}
                            className="text-white btn-liner-gradient text-[13px] md:text-base font-semibold rounded-lg py-1 md:py-2 px-2 md:px-4 inline-block"
                          >
                            View Ticket
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {ticketHistory.length === 0 && (
              <div className="p-[20px] md:p-[30px] mb-4 text-center">
                <h4 className="text-[#101010] text-base xxl:text-2xl font-body font-semibold hidden md:block">
                  No Data Found
                </h4>
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex items-center justify-center mt-5">
        {activeButton === 1 ? (
          <>
            {totalActivePass > activePass.length ? (
              <button
                className="bg-secondary rounded-lg py-2 px-4 text-black font-body font-normal text-base 2xl:text-[18px] tracking-[0.1rem] uppercase"
                onClick={loadMoreActivePass}
              >
                Load More
              </button>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            {totalTicketHistory > ticketHistory.length ? (
              <button
                className="bg-gainsboro rounded-lg py-2 px-4 text-black font-body font-normal text-base 2xl:text-[18px] tracking-[0.1rem] uppercase"
                onClick={loadMoreTicketHistory}
              >
                Load More
              </button>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </>
  );
};
TicketHistory.propTypes = {
  props: PropTypes.object,
};
export default TicketHistory;
