// function to genererate event schema
export const generateEventSchema = (event, artistList = []) => { // artistList is an array of artists
    const dates = Array.isArray(event.dates) ? event.dates : []; // Ensure dates is an array

    // Generate schema for offers, location, organizer, images, and performers
    let offers = generateOfferSchema(event);
    let location = generateLocationSchema(event);
    let organizer = generateOrganizationSchema(event);
    let images = generateImageSchema(event);
    let performers = generatePerformerSchema(artistList);

    // Construct the schema object
    let schema = {
        "@context": "http://schema.org",
        "@type": "Event",
        "name": event.name || "",
        "description": event.description || event.short_description || "",
        "startDate": dates[0]?.date || "",
        "endDate": dates[dates.length - 1]?.date || "",
        "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
        "eventStatus": "https://schema.org/EventScheduled",

    };

    // Include offers, location, organizer, images, and performers only if they exist
    if (offers) {
        schema.offers = offers;
    }
    if (location) {
        schema.location = location;
    }
    if (organizer) {
        schema.organizer = organizer;
    }

    if (images) {
        schema.image = images;
    }
    if (performers && performers.length > 0) {
        schema.performer = performers;
    }
    return schema;
};



// function to generate organization schema
export const generateOrganizationSchema = (event) => {
    let url = window.location.href + '/organiser/' + event.merchant_company_slug;
    //remove // from url
    url = url.replace(/([^:]\/)\/+/g, "$1");
    return {
        "@type": "Organization",
        "name": event.merchant_company_name || "",
        "url": url
    };
};

//function to generate location schema
export const generateLocationSchema = (event) => {
    return {
        "@type": "Place",
        "name": event?.address || "",
        "address": {
            "@type": "PostalAddress",
            "addressCountry": event?.country || "",
            "addressLocality": event?.city || "",
            "streetAddress": event?.address || "",
            "postalCode": event?.pin_code || ""
        },

    };
};

// function to generate offer schema
export const generateOfferSchema = (event) => {
    const dates = Array.isArray(event.dates) ? event.dates : [];
    let url = window.location.href + '/book-tickets';
    //remove // from url 
    url = url.replace(/([^:]\/)\/+/g, "$1");

    return {
        "@type": "Offer",
        "URL": url,
        "availability": "http://schema.org/InStock",
        "name": event?.pass_name || "",
        "price": event?.price_without_tax || "",
        "priceCurrency": "INR",
        "validFrom": dates[0]?.date || "",
    };
};

// funcion to generate image make array of images
export const generateImageSchema = (event) => {
    // If banner_image is not available, return default image
    if (!event.banner_image) {
        return ["https://www.mepass.in/apple-touch-icon-192x192.png"];
    }
    return [event.banner_image];
};

// Function to generate performer schema (for artist)
export const generatePerformerSchema = (artistList = []) => {
    // Map each artist to a schema object
    return artistList.map(artist => {
        const url = window.location.href + '/artiest/' + artist.slug;  
        return {
            "@type": "Person",
            "name": artist.name || "",
            "image": artist.image || "",
            "sameAs": [
                url,
                artist.instagram_url || "",
                artist.facebook_url || "",
                artist.youtube_url || "",
                artist.twitter_url || ""
            ]
        };
    });
};
