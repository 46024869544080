const messageConstant = {
    EMAIL_REQUIRED: 'Email is required',
    EMAIL_INVALID: 'Email is invalid',
    OTP_REQUIRED: 'OTP is required',
    OTP_INVALID: 'OTP is invalid',
    NAME_REQUIRED: 'Name is required',
    FULL_NAME_REQUIRED: 'Full Name is required',
    NAME_INVALID: 'Name is invalid',
    FULL_NAME_INVALID: 'Full Name is invalid',
    MOBILE_REQUIRED: 'Mobile is required',
    MOBILE_INVALID: 'Mobile is invalid',
    IMAGE_REQUIRED: 'Image is required',
    IMAGE_TYPE: 'Image type should be jpg or png',
    PDF_IMAGE_TYPE: 'File type should be jpg, png or pdf',
    IMAGE_DIMENSION: 'Image dimensions should be valid.',
    DATE_REQUIRED: 'Date is required',
    SALARY_REQUIRED: 'Salary is required',
    BACKGROUND_COLOR_REQUIRED: 'Background Color is required',
    DESIGNATION_REQUIRED: 'Designation is required',
    MERCHANT_NAME_REQUIRED: 'Merchant Name is required',
    SHORT_DESCRIPTION_REQUIRED: 'Short Description is required',
    DESCRIPTION_REQUIRED: 'Description is required',
    ADDRESS_REQUIRED: 'Address is required',
    COUNTRY_REQUIRED: 'Country is required',
    STATE_REQUIRED: 'State is required',
    CITY_REQUIRED: 'City is required',
    BUSINESS_PAN_REQUIRED: 'Business pan is required',
    URL_INVALID: 'URL is invalid',
    MOBILE_MIN_LENGTH: 'Mobile number should be minimum 10 digits',
    MOBILE_MAX_LENGTH: 'Mobile number should be maximum 10 digits',
    CONFIRM_MOBILE_REQUIRED: 'Confirm Mobile is required',
    CONFIRM_MOBILE_INVALID: 'Confirm Mobile is invalid',
    MOBILE_NOT_MATCH: 'Mobile number not match',
    COUNT_REQUIRED: 'QTY is required',
    COUNT_INVALID: 'QTY is invalid',
    COUNT_MIN_LENGTH: 'QTY should be minimum 1',
    COUNT_MAX_LENGTH: 'QTY should be maximum Available Balance',
    NAME_MAX_LENGTH: 'Name should be maximum 50 characters',
    NAME_MIN_LENGTH: 'Name should be minimum 5 characters',
    FULL_NAME_MIN_LENGTH: 'Full Name should be minimum 5 characters',
    FULL_NAME_MAX_LENGTH: 'Full Name should be maximum 50 characters',
    GENDER_REQUIRED: 'Gender is required',
}
export default messageConstant
