import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/light_theme_images/logo.svg";
import { isEmpty } from "lodash";
import searchIcon from "../../assets/light_theme_images/search.svg";

const SidebarMenu = ({
  sidebarArray,
  toggleSubmenu,
  handleActiveItem,
  activeItem,
  openSubmenu,
  isResponsive,
  handleSidebarToggle,
  sidebarToggle,
  handleNavLinkClick,
  toggleSubmenuIndex,
  openSubmenuIndex,
}) => {

  return (
    <>
      {isResponsive ? (
        <>
          {sidebarToggle ? (

            <aside
              className={`fixed left-0 top-0 bottom-0 z-30 overflow-y-auto h-full xl:hidden bg-white block flex-col 
      transition-transform duration-700 ease-linear
      ${sidebarToggle ? " h-full w-full" : "h-0 w-0"}`}
            >
              <div className="flex flex-col overflow-y-auto">
                <div className="flex items-center justify-between shadow-lg px-4 py-7">
                  <Link to="/">
                    <img src={logo} alt="Your Company" />
                  </Link>
                  <div
                    className="text-[#000000] text-xl text-center cursor-pointer"
                    onClick={handleNavLinkClick}
                  >
                    <i className="fas fa-times mt-1"></i>
                  </div>
                </div>
                <nav className="pt-10 px-[20px]">
                  <div>
                    <ul className="flex flex-col items-center gap-3">
                      {sidebarArray.map((item, index) => (
                        <li className="mb-[10px]" key={item.name + index}>
                          <div>
                            {item.submenu && item.submenu.length > 0 ? (
                              <Link
                                to={item.link}
                                className={`flex items-center justify-center rounded-md ${activeItem == item.link
                                  ? "text-primary"
                                  : "text-[#101010]"
                                  } w-[212px] h-[34px] p-[10px] justify-start`}
                                onClick={() => {
                                  // handleNavLinkClick();
                                  handleActiveItem(item.link);
                                  toggleSubmenu(index);
                                }}
                              >
                                <div className="text-[16px] font-medium ml-2">
                                  {item.name}
                                  {item.submenu &&
                                    item.submenu &&
                                    item.submenu.length > 0 && (
                                      <button
                                        onClick={() => {
                                          toggleSubmenuIndex(index);
                                        }}
                                        className="text-sm ml-4 text-gray-500"
                                      >
                                        <i className="fas fa-caret-down"></i>
                                      </button>
                                    )}
                                </div>
                              </Link>
                            ) : (
                              <Link
                                to={item.link}
                                className={`flex items-center justify-center rounded-md ${activeItem == item.link
                                  ? "text-primary"
                                  : "text-[#101010]"
                                  } w-[212px] h-[34px] p-[10px] justify-start`}
                                onClick={() => {
                                  handleNavLinkClick();
                                }}
                              >
                                <div className="text-[16px] font-medium ml-2">
                                  {item.name}
                                </div>
                              </Link>
                            )}

                            {/* Toggle Submenu */}
                          </div>
                          {/* Render Submenu */}
                          {item.submenu &&
                            item.submenu &&
                            openSubmenu === index && (
                              <ul className="ml-4 mt-2">
                                {item.submenu &&
                                  item.submenu.map((subItem, subIndex) => (
                                    <li
                                      key={subItem.name + subIndex}
                                      className="p-[8px]"
                                    >
                                      <Link
                                        to={subItem.link}
                                        onClick={() => {
                                          handleNavLinkClick();
                                        }}
                                        className={`flex items-center ml-[63px] rounded-md`}
                                      >
                                        <div className="text-[#101010] text-[14px] font-normal font-body ml-2">
                                          {subItem.name}
                                        </div>
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            )}
                        </li>
                      ))}
                    </ul>
                    {!localStorage.getItem("publicMePassUser") ? (
                      <div className="text-center mt-3">
                        <Link
                          to="/login"
                          onClick={() => {
                            handleNavLinkClick();
                          }}
                          className="btn-liner-gradient text-sm text-white font-medium py-2 px-4 rounded-3xl inline-block"
                        >
                          Login / Register
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </nav>
              </div>
            </aside>
          ) : ('')}
          <div className="block xl:hidden">
            <div className="flex items-center gap-4">
              {/* <div className="flex-none">
                <div className="size-9 flex items-center justify-center cursor-pointer search-border-liner-gradient">
                  <i className="fa-solid fa-magnifying-glass text-[#101010]"></i>
                </div>
              </div> */}
              {/* <div className="flex-none">
                <div className="size-9 flex items-center justify-center cursor-pointer search-border-liner-gradient">
                  <i className="fa-solid fa-location-dot text-[#101010]"></i>
                </div>
              </div> */}
              <i
                className="fas fa-bars text-[#101010] text-xl cursor-pointer"
                onClick={handleSidebarToggle}
              ></i>
            </div>
          </div>
        </>
      ) : (
        <ul className="flex items-center group gap-2">
          {sidebarArray.map((item, index) => (
            <li
              key={item.name + index}
              onClick={() => toggleSubmenu(index)}
              className={
                index > sidebarArray.length
                  ? `text-xl px-3 py-2  hover:menu-text-liner-gradient relative ${activeItem === item.link
                    ? "menu-text-liner-gradient font-bold"
                    : "text-[#101010] font-medium"
                  }`
                  : `text-xl px-3 py-2  relative ${activeItem === item.link
                    ? "text-[#CB2129] font-bold"
                    : "text-[#101010] font-medium"
                  }`
              }
            >
              {item.submenu && item.submenu.length > 0 ? (
                <Link
                  onClick={() => {
                    toggleSubmenu(index);
                    handleActiveItem(item.link);
                  }}
                >
                  {item.name}
                  {item.submenu && item.submenu.length > 0 && (
                    <i className="fas fa-caret-down ml-1"></i>
                  )}
                </Link>
              ) : (
                <Link
                  to={item.link}
                  onClick={() => {
                    handleActiveItem(item.link);
                  }}
                >
                  {item.name}
                </Link>
              )}
              {item.submenu &&
                item.submenu.length > 0 &&
                openSubmenu === index && (
                  <ul className="absolute top-full right-0 mt-2 w-[224px] bg-white text-[#101010] rounded-lg shadow-lg max-h-[300px] overflow-auto p-4">
                    {item.submenu &&
                      item.submenu.map((subItem, subIndex) => (
                        <>
                          <li
                            key={subItem.name + subIndex}
                            className="cursor-pointer text-base font-normal text-[#101010]"
                          >
                            <Link to={subItem.link}>{subItem.name}</Link>
                          </li>
                          {subIndex !== item.submenu.length - 1 && (
                            <div className="border-b border-[#CACED8] mb-3 pb-3"></div>
                          )}
                        </>
                      ))}
                  </ul>
                )}
            </li>
          ))}
        </ul>
      )
      }
    </>
  );
};

export default SidebarMenu;
