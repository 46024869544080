import PropTypes from "prop-types"
import React from "react"
const Text = ({
  validation,
  name,
  label,
  isRequire,
  readOnly,
  showIcon,
  onChange,
  regexCompare,
  formErrors,
  maxLength,
  className
}) => {
  const handleChange = e => {
    const value = e.target.value;
    // Enforce maxLength
    if (maxLength && value.length > maxLength) {
      return;
    }
    if (onChange) {
      onChange(e)
    }
    if (validation.handleChange) {
      validation.handleChange(e)
      const sanitizedPhoneNumber = e.target.value.replace(regexCompare, "")
      validation.setFieldValue &&
        validation.setFieldValue(e.target.name, sanitizedPhoneNumber)
    }
  }
  return (
    <div>
      <label className="mb-[10px] block text-base font-medium text-[#101010]">
        {label} {isRequire ? <span className="text-[#CB2027]">*</span> : ""}
      </label>
      <input
        name={name}
        type="text"
        onChange={handleChange}
        onBlur={validation.handleBlur}
        value={validation.values[name] || ""}
        readOnly={readOnly}
        className={className ? className : "bg-white border border-[#E7E7E7] text-[#CB2129] text-base font-semibold font-body  rounded-lg placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full py-4 px-5"}
        // className="bg-white border border-[#E7E7E7] text-black text-base font-normal font-body  rounded-lg placeholder:text-[#8B8B8B] focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 px-3"
        placeholder={"Enter " + label}
      />
      {validation.touched[name] && validation.errors[name] ? (
        <div className="text-red-500 mt-1">{validation.errors[name]} </div>
      ) : null}
      {formErrors && formErrors[name] && (
        <div className="text-red-500 mt-1">{formErrors[name]} </div>
      )}
    </div>
  )
}

Text.propTypes = {
  validation: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  isRequire: PropTypes.bool,
  readOnly: PropTypes.bool,
  showIcon: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Text


// import PropTypes from "prop-types";
// import React from "react";

// const Text = ({
//   validation,
//   name,
//   label,
//   isRequire,
//   readOnly,
//   showIcon,
//   onChange,
//   regexCompare,
//   formErrors,
// }) => {
//   const handleChange = e => {
//     let value = e.target.value;

//     // Sanitize the input by removing non-digit characters
//     const sanitizedPhoneNumber = value.replace(regexCompare, "");

//     // Check if the sanitized input is purely numeric
//     if (!isNaN(sanitizedPhoneNumber)) {
//       // Limit the input to 10 digits
//       if (sanitizedPhoneNumber.length > 10) {
//         value = sanitizedPhoneNumber.slice(0, 10);
//       } else {
//         value = sanitizedPhoneNumber;
//       }
//     }

//     e.target.value = value;

//     if (onChange) {
//       onChange(e);
//     }

//     if (validation.handleChange) {
//       validation.handleChange(e);
//       validation.setFieldValue && validation.setFieldValue(e.target.name, value);
//     }
//   };

//   return (
//     <div>
//       <input
//         name={name}
//         type="text"
//         onChange={handleChange}
//         onBlur={validation.handleBlur}
//         value={validation.values[name] || ""}
//         readOnly={readOnly}
//         className="bg-white border border-[#E7E7E7] text-black text-base font-normal font-body rounded-lg placeholder:text-[#8B8B8B] focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 px-3"
//         placeholder={"Enter " + label}
//       />
//       {validation.touched[name] && validation.errors[name] ? (
//         <div className="text-red-500">{validation.errors[name]}</div>
//       ) : null}
//       {formErrors && formErrors[name] && (
//         <div className="text-red-500">{formErrors[name]}</div>
//       )}
//     </div>
//   );
// };

// Text.propTypes = {
//   validation: PropTypes.object,
//   name: PropTypes.string.isRequired,
//   label: PropTypes.string.isRequired,
//   isRequire: PropTypes.bool,
//   readOnly: PropTypes.bool,
//   showIcon: PropTypes.bool,
//   onChange: PropTypes.func,
//   regexCompare: PropTypes.instanceOf(RegExp),
//   formErrors: PropTypes.object,
// };

// export default Text;
