import PropTypes from "prop-types";
import React from "react";
import withRouter from "../../components/Common/withRouter";
import { useEffect } from "react";
// Layout Related Components
import Header from "./Header";
import Footer from "./Footer";
import BusinessHeader from "../Layout/BusinessHeader";
import HomeFooter from "../Layout/HomeFooter"
import { useLocation } from "react-router-dom";

const Layout = props => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on load }, []); // Empty dependency array to run only once on mount
  });
  const location = useLocation();
  const currentRoute = location.pathname;
  return (
    <React.Fragment>
      {currentRoute === '/business'
        ? <BusinessHeader />
        : <Header />}
      {props.children}
      {currentRoute === '/home' || currentRoute === '/'
        ? <HomeFooter />
        :  <Footer />}
     
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default withRouter(Layout);
