const labelConstant = {
    EMAIL: 'Email',
    OTP: 'OTP',
    MOBILE: 'Mobile',
    MOBILE_NUMBER: 'Mobile Number',
    NAME: 'Name',
    FULL_NAME: 'Full Name',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    SHORTNAME: 'Short Name',
    DURATION: 'Duration',
    LOGO: 'Logo',
    DASHBOARD: 'Dashboard',
    EMPLOYEE: 'Employee',
    PROJECT: 'Project',
    SPRINT: 'Sprint',
    CREATE: 'Create',
    DOB: 'Date of Birth',
    SALARY: 'Salary',
    PROFILE_IMAGE: 'Profile Pic',
    DESIGNATION: 'Designation',
    MERCHANT_NAME: 'Merchant Name',
    SHORT_DESCRIPTION: 'Short Description',
    DESCRIPTION: 'Description',
    MERCHANT_MOBILE: 'Merchant Mobile',
    MERCHANT_EMAIL: 'Merchant Email',
    MERCHANT_ADDRESS: 'Merchant Address',
    MERCHANT_WEBSITE: 'Merchant Website',
    MERCHANT_LOGO: 'Merchant Logo',
    MERCANT_BANNER: 'Merchant Banner',
    COUNTRY: 'Country',
    STATE: 'State',
    CITY: 'City',
    ADDRESS: 'Address',
    GST_NUMBER: 'GST Number',
    BUSINESS_PAN: 'Business PAN',
    TAN: 'TAN',
    BANK_DETAILS: 'Bank Details',
    CANCEL_CHEQUE_PHOTO: 'Cancel Cheque Photo',
    E_SIGN: 'E-Sign',
    STATUS: 'Status',
    NOTIFICATION: 'Notification',
    DISCOUNT_CODE: 'Discount Code',

}
export default labelConstant
